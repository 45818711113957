import React from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
// import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as API from '../../../api/account';
import Logo from './logo_login.svg';
import showNotification from '../../../components/extras/showNotification';

const ResetPassword = () => {
	const { t } = useTranslation(['auth']);
	const navigate = useNavigate();
	const { darkModeStatus } = useDarkMode();
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');

	const formik = useFormik({
		initialValues: {
			password: '',
			rePassword: '',
		},
		validate: (values) => {
			const errors: { password?: string; rePassword?: string } = {};

			if (!values.password) {
				errors.password = '請輸入密碼';
			}

			if (!values.rePassword) {
				errors.rePassword = '請輸入確認密碼';
			}

			if (values.password !== values.rePassword) {
				errors.rePassword = '密碼不一致';
			}

			return errors;
		},
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: async (values) => {
			formik.setSubmitting(true);
			const account = {
				password: values.password,
				token: token || '',
			};
			API.resetPassword(account)
				.then((res) => {
					showNotification('success', '密碼修改成功');
					setTimeout(() => {
						navigate('auth-pages/login');
					}, 1000);
				})
				.catch((err) => {
					showNotification('error', err.error.message);
				})
				.finally(() => {
					formik.setSubmitting(false);
				});
		},
	});
	const LoginHeader = () => {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>重設密碼</div>
				<div className='text-center h4 text-muted mb-5'>輸入新密碼</div>
			</>
		);
	};

	return (
		<PageWrapper isProtected={false} title={t('Login')} className={classNames('bg-warning')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames('text-decoration-none  fw-bold display-2', {
											'text-dark': !darkModeStatus,
											'text-light': darkModeStatus,
										})}>
										<img src={Logo} alt='logo' width={150} />
									</Link>
								</div>

								<LoginHeader />
								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										<FormGroup id='password' isFloating label='新密碼'>
											<Input
												type='password'
												autoComplete='password'
												value={formik.values.password}
												isTouched={formik.touched.password}
												invalidFeedback={formik.errors.password}
												validFeedback='看起來很不錯!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
										<FormGroup className='mt-3' id='rePassword' isFloating label='確認密碼'>
											<Input
												type='password'
												autoComplete='re-password'
												value={formik.values.rePassword}
												isTouched={formik.touched.rePassword}
												invalidFeedback={formik.errors.rePassword}
												validFeedback='符合!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='warning'
											type='submit'
											className='w-100 py-3'
											isDisable={formik.isSubmitting}
											isLoading={formik.isSubmitting}
											onClick={formik.handleSubmit}>
											確認
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default ResetPassword;
