/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * Message that represents an arbitrary HTTP body. It should only be used for
 * payload formats that can't be represented as JSON, such as raw binary or
 * an HTML page.
 *
 *
 * This message can be used both in streaming and non-streaming API methods in
 * the request as well as the response.
 *
 * It can be used as a top-level request field, which is convenient if one
 * wants to extract parameters from either the URL or HTTP template into the
 * request fields and also want access to the raw HTTP body.
 *
 * Example:
 *
 *     message GetResourceRequest {
 *       // A unique request id.
 *       string request_id = 1;
 *
 *       // The raw HTTP body is bound to this field.
 *       google.api.HttpBody http_body = 2;
 *
 *     }
 *
 *     service ResourceService {
 *       rpc GetResource(GetResourceRequest)
 *         returns (google.api.HttpBody);
 *       rpc UpdateResource(google.api.HttpBody)
 *         returns (google.protobuf.Empty);
 *
 *     }
 *
 * Example with streaming methods:
 *
 *     service CaldavService {
 *       rpc GetCalendar(stream google.api.HttpBody)
 *         returns (stream google.api.HttpBody);
 *       rpc UpdateCalendar(stream google.api.HttpBody)
 *         returns (stream google.api.HttpBody);
 *
 *     }
 *
 * Use of this type only changes how the request and response bodies are
 * handled, all other features will continue to work unchanged.
 */
export interface ApiHttpBody {
	/** The HTTP Content-Type header value specifying the content type of the body. */
	contentType?: string;
	/**
	 * The HTTP request/response body as raw binary.
	 * @format byte
	 */
	data?: string;
	/**
	 * Application specific response metadata. Must be set in the first response
	 * for streaming APIs.
	 */
	extensions?: ProtobufAny[];
}

/**
 * `Any` contains an arbitrary serialized protocol buffer message along with a
 * URL that describes the type of the serialized message.
 *
 * Protobuf library provides support to pack/unpack Any values in the form
 * of utility functions or additional generated methods of the Any type.
 *
 * Example 1: Pack and unpack a message in C++.
 *
 *     Foo foo = ...;
 *     Any any;
 *     any.PackFrom(foo);
 *     ...
 *     if (any.UnpackTo(&foo)) {
 *       ...
 *     }
 *
 * Example 2: Pack and unpack a message in Java.
 *
 *     Foo foo = ...;
 *     Any any = Any.pack(foo);
 *     ...
 *     if (any.is(Foo.class)) {
 *       foo = any.unpack(Foo.class);
 *     }
 *     // or ...
 *     if (any.isSameTypeAs(Foo.getDefaultInstance())) {
 *       foo = any.unpack(Foo.getDefaultInstance());
 *     }
 *
 *  Example 3: Pack and unpack a message in Python.
 *
 *     foo = Foo(...)
 *     any = Any()
 *     any.Pack(foo)
 *     ...
 *     if any.Is(Foo.DESCRIPTOR):
 *       any.Unpack(foo)
 *       ...
 *
 *  Example 4: Pack and unpack a message in Go
 *
 *      foo := &pb.Foo{...}
 *      any, err := anypb.New(foo)
 *      if err != nil {
 *        ...
 *      }
 *      ...
 *      foo := &pb.Foo{}
 *      if err := any.UnmarshalTo(foo); err != nil {
 *        ...
 *      }
 *
 * The pack methods provided by protobuf library will by default use
 * 'type.googleapis.com/full.type.name' as the type URL and the unpack
 * methods only use the fully qualified type name after the last '/'
 * in the type URL, for example "foo.bar.com/x/y.z" will yield type
 * name "y.z".
 *
 * JSON
 * ====
 * The JSON representation of an `Any` value uses the regular
 * representation of the deserialized, embedded message, with an
 * additional field `@type` which contains the type URL. Example:
 *
 *     package google.profile;
 *     message Person {
 *       string first_name = 1;
 *       string last_name = 2;
 *     }
 *
 *     {
 *       "@type": "type.googleapis.com/google.profile.Person",
 *       "firstName": <string>,
 *       "lastName": <string>
 *     }
 *
 * If the embedded message type is well-known and has a custom JSON
 * representation, that representation will be embedded adding a field
 * `value` which holds the custom JSON in addition to the `@type`
 * field. Example (for message [google.protobuf.Duration][]):
 *
 *     {
 *       "@type": "type.googleapis.com/google.protobuf.Duration",
 *       "value": "1.212s"
 *     }
 */
export interface ProtobufAny {
	/**
	 * A URL/resource name that uniquely identifies the type of the serialized
	 * protocol buffer message. This string must contain at least
	 * one "/" character. The last segment of the URL's path must represent
	 * the fully qualified name of the type (as in
	 * `path/google.protobuf.Duration`). The name should be in a canonical form
	 * (e.g., leading "." is not accepted).
	 *
	 * In practice, teams usually precompile into the binary all types that they
	 * expect it to use in the context of Any. However, for URLs which use the
	 * scheme `http`, `https`, or no scheme, one can optionally set up a type
	 * server that maps type URLs to message definitions as follows:
	 *
	 * * If no scheme is provided, `https` is assumed.
	 * * An HTTP GET on the URL must yield a [google.protobuf.Type][]
	 *   value in binary format, or produce an error.
	 * * Applications are allowed to cache lookup results based on the
	 *   URL, or have them precompiled into a binary to avoid any
	 *   lookup. Therefore, binary compatibility needs to be preserved
	 *   on changes to types. (Use versioned type names to manage
	 *   breaking changes.)
	 *
	 * Note: this functionality is not currently available in the official
	 * protobuf release, and it is not used for type URLs beginning with
	 * type.googleapis.com. As of May 2023, there are no widely used type server
	 * implementations and no plans to implement one.
	 *
	 * Schemes other than `http`, `https` (or the empty scheme) might be
	 * used with implementation specific semantics.
	 */
	'@type'?: string;
	[key: string]: any;
}

export interface PurchaseATMPaidNotifyReq {
	orderId?: string;
	paymentDate?: string;
	accountLastFiveCode?: string;
	firmId?: string;
}

export interface PurchaseATMPaidNotifyRes {
	result?: string;
}

export interface PurchaseCancelSubscriptionRequest {
	firmId?: string;
	ramateFirmId?: string;
	userId?: string;
	platformType?: string;
}

export interface PurchaseCancelSubscriptionResponse {
	result?: string;
}

export interface PurchaseCheckNoticeReq {
	orderId?: string;
}

export interface PurchaseCheckNoticeRes {
	result?: string;
}

export interface PurchaseCost {
	costName?: string;
	/** @format int32 */
	costPrice?: number;
}

export interface PurchaseCreateOrderLogCustomerReq {
	firmId?: string;
	orderId?: string;
	customerMessage?: string;
	updateUser?: string;
}

export interface PurchaseCreateOrderLogCustomerRes {
	result?: string;
	isSendNoticeLetter?: boolean;
}

export interface PurchaseCreateOrderReq {
	/** @gotags: db:"live_id" */
	liveId?: string;
	/** @gotags: db:"platform_type" */
	platformType?: string;
	product?: PurchaseProduct[];
	/** @gotags: db:"firm_id" */
	firmId?: string;
	/** @gotags: db:"invoice_type" */
	invoiceType?: string;
	/** @gotags: db:"payment_method" */
	paymentMethod?: string;
	/** @gotags: db:"association_id" */
	associationId?: string;
	/** @gotags: db:"delivery" */
	delivery?: PurchaseDelivery;
	/** @gotags: db:"invoice_carrier_type" */
	invoiceCarrierType?: string;
	/** @gotags: db:"invoice_carrier_info" */
	invoiceCarrierInfo?: string;
	/** @gotags: db:"invoice_identifier" */
	invoiceIdentifier?: string;
	/** @gotags: db:"love_code" */
	loveCode?: string;
	/** @gotags: db:"update_user" */
	updateUser?: string;
	invoiceIssueType?: string;
	invoiceTitle?: string;
	cost?: PurchaseCost[];
	orderMemoCustomer?: string;
	customFields?: PurchaseCustomFields[];
	ramateAccount?: PurchaseRamateAccount;
	bombmyUserId?: string;
	orderMemo?: string;
	salesPageId?: string;
	eventId?: string;
	fbp?: string;
	fbc?: string;
	ttclid?: string;
	couponList?: string[];
}

export interface PurchaseCreateOrderRes {
	result?: string;
	isSendNoticeLetter?: boolean;
}

export interface PurchaseCreatePaymentReq {
	/** @gotags: db:"order_id" */
	orderId?: string;
	userId?: string;
	firmId?: string;
	platformType?: string;
	updateUser?: string;
}

export interface PurchaseCreatePaymentRes {
	/** @gotags: db:"live_id" */
	result?: string;
}

export interface PurchaseCreateSignupOrderReq {
	firmId?: string;
	platformType?: string;
	signupId?: string;
	name?: string;
	email?: string;
	phone?: string;
	gender?: string;
	county?: string;
	district?: string;
	address?: string;
	zipcode?: string;
	invoiceType?: string;
	paymentMethod?: string;
	associationId?: string;
	userId?: string;
	updateUser?: string;
	orderUserId?: string;
	signupOrderSession?: PurchaseSignupOrderSession[];
	signupOrderId?: string;
	/** @format int32 */
	orderAmt?: number;
}

export interface PurchaseCreateSignupOrderRes {
	result?: string;
}

export interface PurchaseCustomFields {
	name?: string;
	value?: string;
	type?: string;
	description?: string;
}

export interface PurchaseDeleteInvoiceSchedulerRes {
	result?: string;
}

export interface PurchaseDelivery {
	/** @gotags: db:"name" */
	name?: string;
	/** @gotags: db:"phone" */
	phone?: string;
	/** @gotags: db:"county" */
	county?: string;
	/** @gotags: db:"district" */
	district?: string;
	/** @gotags: db:"zipcode" */
	zipcode?: string;
	/** @gotags: db:"address" */
	address?: string;
	/**
	 * @gotags: db:"prefer_period"
	 * @format int32
	 */
	preferPeriod?: number;
	/** @gotags: db:"gender" */
	gender?: string;
	/** @gotags: db:"email" */
	email?: string;
	/** @format int32 */
	deliveryType?: number;
	countryCode?: string;
}

export interface PurchaseDeliveryInfo {
	name?: string;
	phone?: string;
	email?: string;
	address?: string;
	countryCode?: string;
	sevenStoreId?: string;
	sevenStoreName?: string;
	sevenStoreAddress?: string;
}

export interface PurchaseEntityLogisticsInfo {
	logisticsId?: string;
	logisticsProductId?: string;
	logisticsProductName?: string;
	/** @format int32 */
	logisticsProductType?: number;
	serialNo?: string;
	provideTime?: string;
	activateTime?: string;
	SN?: string;
	logisticsName?: string;
	logisticsUrl?: string;
	trackNo?: string;
	shipDate?: string;
	deliveryDate?: string;
}

export interface PurchaseExecSubscriptionResponse {
	merchantTradeNo?: string;
	paymentId?: string;
}

export interface PurchaseFirmDeliveryMethod {
	deliveryMethod?: string;
	deliveryMethodName?: string;
	/** @format double */
	chargeFee?: number;
}

export interface PurchaseFirmPayMethod {
	paymentMethod?: string;
	paymentMethodName?: string;
	chargeFeeType?: string;
	/** @format double */
	chargeFee?: number;
	chargeFeeText?: string;
	cashFlowType?: string;
}

export interface PurchaseGetFirmDeliveryMethodRes {
	deliveryMethod?: PurchaseFirmDeliveryMethod[];
}

export interface PurchaseGetFirmInvoiceMethodRes {
	typeDonation?: boolean;
	typeMember?: boolean;
	typeCertificate?: boolean;
	typeBarcode?: boolean;
	typeCompany?: boolean;
	invoicePaper?: boolean;
	invoiceThirdParty?: boolean;
}

export interface PurchaseGetFirmPayMethodRes {
	payMethod?: PurchaseFirmPayMethod[];
	deliveryMethod?: PurchaseFirmDeliveryMethod[];
}

export interface PurchaseGetLoveCodeListRes {
	loveCode?: PurchaseLoveCode[];
}

export interface PurchaseGetOrderInfoRes {
	orderId?: string;
	orderNo?: string;
	paymentInfo?: PurchasePaymentInfo;
	orderDetail?: PurchaseOrderDetailInfo[];
	invoiceInfo?: PurchaseInvoiceInfo;
	deliveryInfo?: PurchaseDeliveryInfo;
	/** @format int32 */
	orderSubtotal?: number;
	/** @format int32 */
	cashFee?: number;
	/** @format int32 */
	deliveryFee?: number;
	/** @format int32 */
	orderTotal?: number;
	orderStatus?: string;
	deliveryType?: string;
	orderMemoCustomer?: string;
	virtualLogisticsInfo?: PurchaseVirtualLogisticsInfo[];
	entityTrackLogisticsInfo?: PurchaseEntityLogisticsInfo[];
	platformType?: string;
	orderUserId?: string;
	orderLogInfo?: PurchaseOrderLogInfo[];
	firmId?: string;
	signupId?: string;
	orderDescription?: string;
	noticeDescription?: string;
	noticeName?: string;
	noticeValue?: string;
	/** @format int32 */
	gtmTrigger?: number;
	gtmList?: PurchaseGtmList[];
	utmCampaign?: string;
	utmContent?: string;
	utmSource?: string;
	utmMedium?: string;
	bombmyOrdered?: boolean;
	orderFilledStatus?: string;
	bombcouseDomain?: string;
}

export interface PurchaseGetSignupOrderInfoRes {
	orderId?: string;
	orderNo?: string;
	orderMemo?: string;
	paymentInfo?: PurchasePaymentInfo;
	signupOrderInfo?: PurchaseSignupOrderSession[];
	invoiceInfo?: PurchaseInvoiceInfo;
	/** @format int32 */
	orderSubtotal?: number;
	/** @format int32 */
	cashFee?: number;
	/** @format int32 */
	orderTotal?: number;
	orderStatus?: string;
	orderMemoCustomer?: string;
	orderMemoFirm?: string;
	platformType?: string;
	orderUserId?: string;
	firmId?: string;
	orderLogInfo?: PurchaseOrderLogInfo[];
}

export interface PurchaseGtmList {
	gtmId?: string;
	gtag?: string;
}

export interface PurchaseInvoiceInfo {
	invoiceNo?: string;
	invoiceType?: string;
	invoiceCarrierType?: string;
	invoiceCarrierInfo?: string;
	invoiceIdentifier?: string;
	invoiceTitle?: string;
	loveCode?: string;
	invoiceCustomerName?: string;
	invoiceIssueType?: string;
	invoiceStatus?: string;
	lockInvoiceStatus?: boolean;
}

export interface PurchaseInvoiceNotifyReq {
	email?: string;
	invoiceNo?: string;
	firmId?: string;
	orderId?: string;
}

export interface PurchaseInvoiceNotifyRes {
	result?: string;
}

export interface PurchaseIssueInvoiceBackendReq {
	invoiceIssueType?: string;
	firmId?: string;
	orderId?: string;
	invoiceType?: string;
	invoiceTitle?: string;
	invoiceIdentifier?: string;
	invoiceCarrierType?: string;
	invoiceCarrierInfo?: string;
	loveCode?: string;
	updateUser?: string;
	invoiceCustomerName?: string;
}

export interface PurchaseIssueInvoiceBackendRes {
	result?: string;
}

export interface PurchaseIssueInvoiceOrderList {
	orderId?: string;
	orderNo?: string;
	reason?: string;
}

export interface PurchaseIssueInvoiceReq {
	orderId?: string[];
	firmId?: string;
	updateUser?: string;
}

export interface PurchaseIssueInvoiceRes {
	result?: string;
	successOrder?: PurchaseIssueInvoiceOrderList[];
	failOrder?: PurchaseIssueInvoiceOrderList[];
}

export type PurchaseIssueInvoiceSchedulerReq = object;

export type PurchaseIssueInvoiceSchedulerRes = object;

export interface PurchaseLoveCode {
	orgName?: string;
	loveCode?: string;
}

export interface PurchaseOrderDetailInfo {
	productName?: string;
	/** @format int32 */
	productPrice?: number;
	/** @format int32 */
	productQuantity?: number;
	productImgUrl?: string;
}

export interface PurchaseOrderLogInfo {
	orderLogId?: string;
	orderLogType?: string;
	orderLogText?: string;
	orderLogTime?: string;
}

export interface PurchasePayNowCheckoutRequest {
	paymentNo?: string;
	firstName?: string;
	lastName?: string;
	expirationMonth?: string;
	expirationYear?: string;
}

export type PurchasePayNowCheckoutResponse = object;

export interface PurchasePaymentInfo {
	paymentMethod?: string;
	paymentDate?: string;
	/** @format int32 */
	paymentAmount?: number;
	paymentStatus?: string;
	bankCode?: string;
	bankName?: string;
	vAccount?: string;
	expireDate?: string;
	realAccount?: string;
	accountLastFiveCode?: string;
	bankBranchName?: string;
	bankAccountName?: string;
	/** @format int32 */
	refundAmt?: number;
	refundText?: string;
	getAccountDate?: string;
	description?: string;
}

export interface PurchaseProduct {
	/** @gotags: db:"product_mod_id" */
	productId?: string;
	/**
	 * @gotags: db:"amount"
	 * @format int32
	 */
	quantity?: number;
	/**
	 * @gotags: db:"product_price"
	 * @format int32
	 */
	productPrice?: number;
}

export interface PurchaseRamateAccount {
	account?: string;
	password?: string;
}

export interface PurchaseRamateSubscriptionRequest {
	platformType?: string;
	product?: PurchaseProduct[];
	/** @gotags: db:"firm_id" */
	firmId?: string;
	/** @gotags: db:"invoice_type" */
	invoiceType?: string;
	/** @gotags: db:"payment_method" */
	paymentMethod?: string;
	/** @gotags: db:"association_id" */
	associationId?: string;
	/** @gotags: db:"delivery" */
	delivery?: PurchaseDelivery;
	/** @gotags: db:"invoice_carrier_type" */
	invoiceCarrierType?: string;
	/** @gotags: db:"invoice_carrier_info" */
	invoiceCarrierInfo?: string;
	/** @gotags: db:"invoice_identifier" */
	invoiceIdentifier?: string;
	/** @gotags: db:"love_code" */
	loveCode?: string;
	/** @gotags: db:"update_user" */
	updateUser?: string;
	invoiceIssueType?: string;
	invoiceTitle?: string;
	cost?: PurchaseCost[];
	orderMemoCustomer?: string;
	customFields?: PurchaseCustomFields[];
	ramateAccount?: PurchaseRamateAccount;
	bombmyUserId?: string;
	orderMemo?: string;
	salesPageId?: string;
	eventId?: string;
	fbp?: string;
	fbc?: string;
	ttclid?: string;
	couponList?: string[];
	isYearCost?: boolean;
}

export interface PurchaseRamateSubscriptionResponse {
	paymentId?: string;
	subscriptionCashflow?: string;
}

export interface PurchaseReIssueInvoiceReq {
	orderId?: string[];
	firmId?: string;
	reason?: string;
	updateUser?: string;
}

export interface PurchaseReIssueInvoiceRes {
	result?: string;
}

export interface PurchaseRefundPaymentReq {
	orderId?: string;
	/** @format int32 */
	amount?: number;
	reason?: string;
	firmId?: string;
	platformType?: string;
	updateUser?: string;
}

export interface PurchaseRefundPaymentRes {
	result?: string;
}

export interface PurchaseSetInvoiceSchedulerReq {
	orderId?: string;
	eventDate?: string;
}

export interface PurchaseSetInvoiceSchedulerRes {
	result?: string;
}

export interface PurchaseSignupOrderSession {
	liveId?: string;
	sessionName?: string;
	sessionContent?: string;
	/** @format int32 */
	sessionQuantity?: number;
	/** @format int32 */
	sessionPrice?: number;
}

export interface PurchaseUpdateCasbinPolicyResponse {
	/** 結果 */
	result?: string;
}

export interface PurchaseUpdateDeliveryMethodReq {
	orderId?: string;
	deliveryMethod?: string;
	updateUser?: string;
	platformType?: string;
}

export interface PurchaseUpdateDeliveryMethodRes {
	result?: string;
}

export interface PurchaseUpdateGtmTriggerRequest {
	orderId?: string;
}

export interface PurchaseUpdateGtmTriggerResponse {
	result?: string;
}

export interface PurchaseUpdateInvoiceInfoReq {
	orderId?: string;
	invoiceType?: string;
	invoiceCarrierType?: string;
	invoiceCarrierInfo?: string;
	loveCode?: string;
	invoiceIssueType?: string;
	invoiceTitle?: string;
	invoiceIdentifier?: string;
	invoiceCustomerName?: string;
	updateUser?: string;
	firmId?: string;
	createLogUser?: string;
}

export interface PurchaseUpdateInvoiceInfoRes {
	result?: string;
}

export interface PurchaseUpdatePaymentMethReq {
	orderId?: string;
	paymentMethod?: string;
	updateUser?: string;
	platformType?: string;
}

export interface PurchaseUpdatePaymentMethRes {
	result?: string;
}

export interface PurchaseValidInvoiceReq {
	orderId?: string[];
	firmId?: string;
	reason?: string;
	updateUser?: string;
}

export interface PurchaseValidInvoiceRes {
	result?: string;
}

export interface PurchaseVirtualLogisticsInfo {
	logisticsId?: string;
	logisticsProductId?: string;
	logisticsProductName?: string;
	/** @format int32 */
	logisticsProductType?: number;
	serialNo?: string;
	provideTime?: string;
	activateTime?: string;
	SN?: string;
	logisticsName?: string;
	logisticsUrl?: string;
	trackNo?: string;
	shipDate?: string;
	deliveryDate?: string;
}

export interface RpcStatus {
	/** @format int32 */
	code?: number;
	message?: string;
	details?: ProtobufAny[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = '';
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: 'same-origin',
		headers: {},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
		return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&');
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : '';
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
		[ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
			},
			signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
			body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
		}).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title index_purchase.proto
 * @version version not set
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	addPointValue = {
		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseAddPointValue
		 * @summary 儲值簡訊點數
		 * @request POST:/add_point_value
		 */
		purchaseAddPointValue: (body: PurchaseCreateOrderReq, params: RequestParams = {}) =>
			this.request<PurchaseCreateOrderRes, RpcStatus>({
				path: `/add_point_value`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),
	};
	addValue = {
		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseAddValue
		 * @summary 儲值幫賣
		 * @request POST:/add_value
		 */
		purchaseAddValue: (body: PurchaseCreateOrderReq, params: RequestParams = {}) =>
			this.request<PurchaseCreateOrderRes, RpcStatus>({
				path: `/add_value`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),
	};
	checkout = {
		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowPayNowSdkCheckout
		 * @summary checkout
		 * @request POST:/checkout
		 */
		cashFlowPayNowSdkCheckout: (body: PurchasePayNowCheckoutRequest, params: RequestParams = {}) =>
			this.request<PurchasePayNowCheckoutResponse, RpcStatus>({
				path: `/checkout`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	invoiceInfo = {
		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderUpdateInvoiceInfo
		 * @summary 訂單更改發票資訊
		 * @request PUT:/invoice_info
		 */
		signupOrderSignupOrderUpdateInvoiceInfo: (body: PurchaseUpdateInvoiceInfoReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdateInvoiceInfoRes, RpcStatus>({
				path: `/invoice_info`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),
	};
	paynow = {
		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowPayNowSdkPayment
		 * @summary paynow sdk付款頁面
		 * @request GET:/paynow/{paymentId}/payment
		 */
		cashFlowPayNowSdkPayment: (paymentId: string, params: RequestParams = {}) =>
			this.request<ApiHttpBody, RpcStatus>({
				path: `/paynow/${paymentId}/payment`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	purchase = {
		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseAtmPaidNotify
		 * @summary ATM通知付款
		 * @request POST:/purchase/atm_paid_notify
		 */
		purchaseAtmPaidNotify: (body: PurchaseATMPaidNotifyReq, params: RequestParams = {}) =>
			this.request<PurchaseATMPaidNotifyRes, RpcStatus>({
				path: `/purchase/atm_paid_notify`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseCheckNotice
		 * @summary 確認須知
		 * @request POST:/purchase/check_notice
		 */
		purchaseCheckNotice: (body: PurchaseCheckNoticeReq, params: RequestParams = {}) =>
			this.request<PurchaseCheckNoticeRes, RpcStatus>({
				path: `/purchase/check_notice`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseCreateOrder
		 * @summary 建立訂單
		 * @request POST:/purchase/create_order
		 */
		purchaseCreateOrder: (body: PurchaseCreateOrderReq, params: RequestParams = {}) =>
			this.request<PurchaseCreateOrderRes, RpcStatus>({
				path: `/purchase/create_order`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseCreatePayment
		 * @summary 建立付款
		 * @request POST:/purchase/create_payment
		 */
		purchaseCreatePayment: (body: PurchaseCreatePaymentReq, params: RequestParams = {}) =>
			this.request<PurchaseCreatePaymentRes, RpcStatus>({
				path: `/purchase/create_payment`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseCreateOrderLogCustomer
		 * @summary 客戶新增訂單記錄
		 * @request POST:/purchase/customer_message
		 */
		purchaseCreateOrderLogCustomer: (body: PurchaseCreateOrderLogCustomerReq, params: RequestParams = {}) =>
			this.request<PurchaseCreateOrderLogCustomerRes, RpcStatus>({
				path: `/purchase/customer_message`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseGetFirmDeliveryMethod
		 * @summary 取得運送方式
		 * @request GET:/purchase/delivery_method
		 */
		purchaseGetFirmDeliveryMethod: (
			query?: {
				firmId?: string;
				bombMySignupId?: string;
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetFirmDeliveryMethodRes, RpcStatus>({
				path: `/purchase/delivery_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowGetFirmInvoiceMethod
		 * @summary 取得開立發票方式
		 * @request GET:/purchase/firm_invoice_method
		 */
		cashFlowGetFirmInvoiceMethod: (
			query?: {
				firmId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetFirmInvoiceMethodRes, RpcStatus>({
				path: `/purchase/firm_invoice_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseGetFirmPayMethod
		 * @summary 取得付款方式
		 * @request GET:/purchase/firm_pay_method
		 */
		purchaseGetFirmPayMethod: (
			query?: {
				firmId?: string;
				bombMySignupId?: string;
				platformType?: string;
				bombmyOrdered?: boolean;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetFirmPayMethodRes, RpcStatus>({
				path: `/purchase/firm_pay_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdateGtmTrigger
		 * @summary 更新GTM觸發狀態
		 * @request POST:/purchase/gtm_trigger
		 */
		purchaseUpdateGtmTrigger: (body: PurchaseUpdateGtmTriggerRequest, params: RequestParams = {}) =>
			this.request<PurchaseUpdateGtmTriggerResponse, RpcStatus>({
				path: `/purchase/gtm_trigger`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdateInvoiceInfo
		 * @summary 更改發票資訊
		 * @request PUT:/purchase/invoice_info
		 */
		purchaseUpdateInvoiceInfo: (body: PurchaseUpdateInvoiceInfoReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdateInvoiceInfoRes, RpcStatus>({
				path: `/purchase/invoice_info`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmUpdateInvoiceInfoBackend
		 * @summary 更改發票資訊
		 * @request PUT:/purchase/invoice_info_backend
		 */
		crmUpdateInvoiceInfoBackend: (body: PurchaseUpdateInvoiceInfoReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdateInvoiceInfoRes, RpcStatus>({
				path: `/purchase/invoice_info_backend`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowInvoiceNotify
		 * @summary 發送發票通知
		 * @request POST:/purchase/invoice_notify
		 */
		cashFlowInvoiceNotify: (body: PurchaseInvoiceNotifyReq, params: RequestParams = {}) =>
			this.request<PurchaseInvoiceNotifyRes, RpcStatus>({
				path: `/purchase/invoice_notify`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseDeleteOrderInvoiceScheduler
		 * @summary 刪除訂單發票排程（後端）
		 * @request DELETE:/purchase/invoice_scheduler
		 */
		purchaseDeleteOrderInvoiceScheduler: (
			query?: {
				orderId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseDeleteInvoiceSchedulerRes, RpcStatus>({
				path: `/purchase/invoice_scheduler`,
				method: 'DELETE',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseSetOrderInvoiceScheduler
		 * @summary 設定訂單發票排程（後端）
		 * @request POST:/purchase/invoice_scheduler
		 */
		purchaseSetOrderInvoiceScheduler: (body: PurchaseSetInvoiceSchedulerReq, params: RequestParams = {}) =>
			this.request<PurchaseSetInvoiceSchedulerRes, RpcStatus>({
				path: `/purchase/invoice_scheduler`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdateOrderInvoiceScheduler
		 * @summary 更新訂單發票排程（後端）
		 * @request PUT:/purchase/invoice_scheduler
		 */
		purchaseUpdateOrderInvoiceScheduler: (body: PurchaseSetInvoiceSchedulerReq, params: RequestParams = {}) =>
			this.request<PurchaseSetInvoiceSchedulerRes, RpcStatus>({
				path: `/purchase/invoice_scheduler`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmIssueInvoice
		 * @summary 開立發票
		 * @request POST:/purchase/issue_invoice
		 */
		crmIssueInvoice: (body: PurchaseIssueInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseIssueInvoiceRes, RpcStatus>({
				path: `/purchase/issue_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmIssueInvoiceBackend
		 * @summary 後台開立發票
		 * @request POST:/purchase/issue_invoice_backend
		 */
		crmIssueInvoiceBackend: (body: PurchaseIssueInvoiceBackendReq, params: RequestParams = {}) =>
			this.request<PurchaseIssueInvoiceBackendRes, RpcStatus>({
				path: `/purchase/issue_invoice_backend`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowGetLoveCodeList
		 * @summary 取得捐贈碼列表
		 * @request GET:/purchase/lovecode_list
		 */
		cashFlowGetLoveCodeList: (
			query?: {
				/** @format int32 */
				start?: number;
				/** @format int32 */
				end?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetLoveCodeListRes, RpcStatus>({
				path: `/purchase/lovecode_list`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseGetOrderInfo
		 * @summary 取得訂單明細
		 * @request GET:/purchase/order_info
		 */
		purchaseGetOrderInfo: (
			query?: {
				orderId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetOrderInfoRes, RpcStatus>({
				path: `/purchase/order_info`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmRefundPayment
		 * @summary 退款
		 * @request POST:/purchase/refund_payment
		 */
		crmRefundPayment: (body: PurchaseRefundPaymentReq, params: RequestParams = {}) =>
			this.request<PurchaseRefundPaymentRes, RpcStatus>({
				path: `/purchase/refund_payment`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmReIssueInvoice
		 * @summary 重開發票
		 * @request POST:/purchase/reissue_invoice
		 */
		crmReIssueInvoice: (body: PurchaseReIssueInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseReIssueInvoiceRes, RpcStatus>({
				path: `/purchase/reissue_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseExecSubscription
		 * @summary 執行訂閱扣款
		 * @request GET:/purchase/subscription
		 */
		purchaseExecSubscription: (params: RequestParams = {}) =>
			this.request<PurchaseExecSubscriptionResponse, RpcStatus>({
				path: `/purchase/subscription`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseCancelSubscription
		 * @summary 取消訂閱
		 * @request DELETE:/purchase/subscription
		 */
		purchaseCancelSubscription: (body: PurchaseCancelSubscriptionRequest, params: RequestParams = {}) =>
			this.request<PurchaseCancelSubscriptionResponse, RpcStatus>({
				path: `/purchase/subscription`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseRamateSubscription
		 * @summary 訂閱根垣服務
		 * @request POST:/purchase/subscription
		 */
		purchaseRamateSubscription: (body: PurchaseRamateSubscriptionRequest, params: RequestParams = {}) =>
			this.request<PurchaseRamateSubscriptionResponse, RpcStatus>({
				path: `/purchase/subscription`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdateDeliveryMethod
		 * @summary 更改運送方式
		 * @request PUT:/purchase/update_delivery_method
		 */
		purchaseUpdateDeliveryMethod: (body: PurchaseUpdateDeliveryMethodReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdateDeliveryMethodRes, RpcStatus>({
				path: `/purchase/update_delivery_method`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdatePaymentMethod
		 * @summary 更改付款方式
		 * @request PUT:/purchase/update_payment_method
		 */
		purchaseUpdatePaymentMethod: (body: PurchaseUpdatePaymentMethReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdatePaymentMethRes, RpcStatus>({
				path: `/purchase/update_payment_method`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmValidInvoice
		 * @summary 作廢發票
		 * @request POST:/purchase/valid_invoice
		 */
		crmValidInvoice: (body: PurchaseValidInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseValidInvoiceRes, RpcStatus>({
				path: `/purchase/valid_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),
	};
	scheduler = {
		/**
		 * No description
		 *
		 * @tags CashFlow
		 * @name CashFlowIssueInvoiceScheduler
		 * @summary 排程開立發票用(後端)
		 * @request POST:/scheduler/issue_invoice
		 */
		cashFlowIssueInvoiceScheduler: (body: PurchaseIssueInvoiceSchedulerReq, params: RequestParams = {}) =>
			this.request<PurchaseIssueInvoiceSchedulerRes, RpcStatus>({
				path: `/scheduler/issue_invoice`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	signupOrder = {
		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderGetSignupOrderInfo
		 * @summary 取得報名訂單
		 * @request GET:/signup_order
		 */
		signupOrderGetSignupOrderInfo: (
			query?: {
				orderId?: string;
				firmId?: string;
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<PurchaseGetSignupOrderInfoRes, RpcStatus>({
				path: `/signup_order`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderCreateSignupOrder
		 * @summary 用戶報名
		 * @request POST:/signup_order
		 */
		signupOrderCreateSignupOrder: (body: PurchaseCreateSignupOrderReq, params: RequestParams = {}) =>
			this.request<PurchaseCreateSignupOrderRes, RpcStatus>({
				path: `/signup_order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderCreatePayment
		 * @summary 報名建立付款
		 * @request POST:/signup_order/create_payment
		 */
		signupOrderSignupOrderCreatePayment: (body: PurchaseCreatePaymentReq, params: RequestParams = {}) =>
			this.request<PurchaseCreatePaymentRes, RpcStatus>({
				path: `/signup_order/create_payment`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderIssueInvoice
		 * @summary 報名開立發票
		 * @request POST:/signup_order/issue_invoice
		 */
		signupOrderSignupOrderIssueInvoice: (body: PurchaseIssueInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseIssueInvoiceRes, RpcStatus>({
				path: `/signup_order/issue_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderIssueInvoiceBackend
		 * @summary 報名後台開立發票
		 * @request POST:/signup_order/issue_invoice_backend
		 */
		signupOrderSignupOrderIssueInvoiceBackend: (body: PurchaseIssueInvoiceBackendReq, params: RequestParams = {}) =>
			this.request<PurchaseIssueInvoiceBackendRes, RpcStatus>({
				path: `/signup_order/issue_invoice_backend`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderUpdateSignupOrderPaymentMethod
		 * @summary 更改報名訂單付款方式
		 * @request POST:/signup_order/payment_method
		 */
		signupOrderUpdateSignupOrderPaymentMethod: (body: PurchaseUpdatePaymentMethReq, params: RequestParams = {}) =>
			this.request<PurchaseUpdatePaymentMethRes, RpcStatus>({
				path: `/signup_order/payment_method`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderRefundPayment
		 * @summary 報名報名退款
		 * @request POST:/signup_order/refund_payment
		 */
		signupOrderSignupOrderRefundPayment: (body: PurchaseRefundPaymentReq, params: RequestParams = {}) =>
			this.request<PurchaseRefundPaymentRes, RpcStatus>({
				path: `/signup_order/refund_payment`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderReIssueInvoice
		 * @summary 報名重開發票
		 * @request POST:/signup_order/reissue_invoice
		 */
		signupOrderSignupOrderReIssueInvoice: (body: PurchaseReIssueInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseReIssueInvoiceRes, RpcStatus>({
				path: `/signup_order/reissue_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags SignupOrder
		 * @name SignupOrderSignupOrderValidInvoice
		 * @summary 報名作廢發票
		 * @request POST:/signup_order/valid_invoice
		 */
		signupOrderSignupOrderValidInvoice: (body: PurchaseValidInvoiceReq, params: RequestParams = {}) =>
			this.request<PurchaseValidInvoiceRes, RpcStatus>({
				path: `/signup_order/valid_invoice`,
				method: 'POST',
				body: body,
				format: 'json',
				...params,
			}),
	};
	updateCasbinPolicy = {
		/**
		 * No description
		 *
		 * @tags Purchase
		 * @name PurchaseUpdateCasbinPolicy
		 * @summary 更新casbin策略
		 * @request GET:/update_casbin_policy
		 */
		purchaseUpdateCasbinPolicy: (params: RequestParams = {}) =>
			this.request<PurchaseUpdateCasbinPolicyResponse, RpcStatus>({
				path: `/update_casbin_policy`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
}
