/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CrmAffiliateAccountListData {
	/** 附屬帳號ID */
	affiliateAccountId?: string;
	/** 帳號名稱 */
	accountName?: string;
	/** 帳號 */
	account?: string;
	/** bombmy平台權限 */
	bombmy?: boolean;
	/**
	 * bombmy_直播相關(分析、影片、商品、直播、留言組、虛擬使用者、黑名單)
	 * @format int32
	 */
	bombmyLive?: number;
	/**
	 * bombmy_報名相關(報名、信件模板、寄送信件)
	 * @format int32
	 */
	bombmySignup?: number;
	/**
	 * bombmy_設定相關(廠商設定)
	 * @format int32
	 */
	bombmySetting?: number;
	/**
	 * 訂單權限
	 * @format int32
	 */
	order?: number;
	/**
	 * 顧客權限
	 * @format int32
	 */
	customer?: number;
	/**
	 * 廠商資訊
	 * @format int32
	 */
	firmInfo?: number;
	/**
	 * 商品權限
	 * @format int32
	 */
	product?: number;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
	/**
	 * bombmy聯盟行銷權限
	 * @format int32
	 */
	bombmyAssociation?: number;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

export interface CrmAffiliateAccountListResponse {
	/** 附屬帳號列表內容 */
	affiliateAccountList?: CrmAffiliateAccountListData[];
}

export interface CrmAnnouncementListData {
	/** 公告ID */
	announcementId?: string;
	/** 公告名稱 */
	name?: string;
	/** 公告類型 */
	type?: string[];
	/** 公告截止日期 */
	expireDate?: string;
	/**
	 * 公告平台(0:all 1:bombmy 2:crm)
	 * @format int32
	 */
	service?: number;
	/** 建立日期 */
	createDate?: string;
}

export interface CrmAnnouncementListDataPublic {
	/** 公告ID */
	announcementId?: string;
	/** 公告名稱 */
	name?: string;
	/** 公告內文 */
	content?: string;
	/** 公告類型 */
	type?: string[];
	/** 公告截止日期 */
	expireDate?: string;
	/** 建立日期 */
	createDate?: string;
}

/** 批次顧客寄發簡訊 */
export interface CrmBatchCustomerSendSMSRequest {
	/** 公司ID */
	firmId?: string;
	/** 訂單ID */
	orderUserIdList?: string[];
	/** 簡訊內容 */
	smsContent?: string;
	/** 寄發簡訊者(login response user_info.user_name) */
	logUser?: string;
	/** 編輯者(login response user_info.user_id) */
	updateUser?: string;
}

export interface CrmBatchCustomerSendSMSResponse {
	/** 成功回傳訊息 */
	result?: string;
	/**
	 * 訂單簡訊成功資訊
	 * @format int32
	 */
	successResult?: number;
	/** 訂單簡訊失敗資訊 */
	failResult?: CrmCustomerSendSMSFailData[];
	/** 錯誤資訊 */
	errorResult?: CrmSendSMSErrorData[];
}

export interface CrmBatchDeleteOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
	/** 訂單ID */
	orderId?: string[];
}

/** 批次訂單寄發郵件 */
export interface CrmBatchOrderSendEmailRequest {
	/** 公司ID */
	firmId?: string;
	/** 訂單ID */
	orderIdList?: string[];
	/** 信件主旨 */
	emailSubject?: string;
	/** 信件內容 */
	emailContent?: string;
	/** 寄發簡訊者(login response user_info.user_name) */
	logUser?: string;
	/** 編輯者(login response user_info.user_id) */
	updateUser?: string;
}

export interface CrmBatchOrderSendEmailResponse {
	/** 成功回傳訊息 */
	result?: string;
	/**
	 * 訂單簡訊成功資訊
	 * @format int32
	 */
	successResult?: number;
	/** 訂單簡訊失敗資訊 */
	failResult?: CrmOrderSendEmailFailData[];
	/** 錯誤資訊 */
	errorResult?: CrmSendEmailErrorData[];
}

/** 批次訂單寄發簡訊 */
export interface CrmBatchOrderSendSMSRequest {
	/** 公司ID */
	firmId?: string;
	/** 訂單ID */
	orderIdList?: string[];
	/** 簡訊內容 */
	smsContent?: string;
	/** 寄發簡訊者(login response user_info.user_name) */
	logUser?: string;
	/** 編輯者(login response user_info.user_id) */
	updateUser?: string;
}

export interface CrmBatchOrderSendSMSResponse {
	/** 成功回傳訊息 */
	result?: string;
	/**
	 * 訂單簡訊成功資訊
	 * @format int32
	 */
	successResult?: number;
	/** 訂單簡訊失敗資訊 */
	failResult?: CrmOrderSendSMSFailData[];
	/** 錯誤資訊 */
	errorResult?: CrmSendSMSErrorData[];
}

export interface CrmBatchPasteCustomerTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmBatchUpdateOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmBombcoursePlanListData {
	/** 幫開課方案ID */
	bombcoursePlanId?: string;
	/** 幫開課方案名稱 */
	bombcoursePlanName?: string;
	/** 幫開課a權限 */
	aPermission?: boolean;
	/** 幫開課b權限 */
	bPermission?: boolean;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
}

/** Bombmy權限 */
export interface CrmBombmyAuthorityData {
	/**
	 * bombmy_直播相關(分析、影片、商品、直播、留言組、虛擬使用者、黑名單)
	 * @format int32
	 */
	bombmyLive?: number;
	/**
	 * bombmy_報名相關(報名、信件模板、寄送信件)
	 * @format int32
	 */
	bombmySignup?: number;
	/**
	 * bombmy_設定相關(廠商設定)
	 * @format int32
	 */
	bombmySetting?: number;
	/**
	 * bombmy聯盟行銷權限
	 * @format int32
	 */
	bombmyAssociation?: number;
}

export interface CrmBombmyLivePeriodData {
	/**
	 * ID流水號
	 * @format int32
	 */
	id?: number;
	/** 直播開始時間 */
	start?: string;
	/** 直播結束時間 */
	end?: string;
	/** 廠商資訊 */
	firmInfo?: CrmBombmyLivePeriodFirmInfo;
	/** 直播資訊 */
	liveInfo?: CrmBombmyLivePeriodLiveInfo;
	/** 報名連結 */
	signupUrl?: string;
}

export interface CrmBombmyLivePeriodFirmInfo {
	/** 廠商ID */
	firmId?: string;
	/** 廠商名稱 */
	firmName?: string;
}

export interface CrmBombmyLivePeriodLiveInfo {
	/** 直播ID */
	liveId?: string;
	/** 直播名稱 */
	liveName?: string;
	/** 直播連結 */
	liveUrl?: string;
}

export interface CrmBorder {
	top?: CrmBorderStyle;
	bottom?: CrmBorderStyle;
	left?: CrmBorderStyle;
	right?: CrmBorderStyle;
}

export interface CrmBorderStyle {
	style?: string;
}

/** 幫賣帳號開通通知信 */
export interface CrmCRMBombmyPermissionNoticeLetterRequest {
	/** 廠商ID */
	firmId?: string;
}

export interface CrmCRMBombmyPermissionNoticeLetterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 自動登入 */
export interface CrmCRMFirmAutoLoginRequest {
	/** 公司ID */
	firmId?: string;
	/** 使用者ID */
	userId?: string;
	/** token */
	token?: string;
	/** 裝置ID */
	deviceId?: string;
}

export interface CrmCRMFirmAutoLoginResponse {
	/** 廠商資訊 */
	firmInfo?: CrmFirmInfoData;
	/** 平台權限 */
	platformAuthority?: CrmPlatformAuthorityData;
	/** 角色權限 */
	roleAuthority?: CrmRoleAuthorityData;
	/** 使用者資訊 */
	userInfo?: CrmUserInfoData;
	/** Bombmy權限 */
	bombmyAuthority?: CrmBombmyAuthorityData;
}

/**
 * 登入相關
 * 登入
 */
export interface CrmCRMFirmLoginRequest {
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
	/** 平台類型 */
	platformType?: string;
	/** 裝置ID */
	deviceId?: string;
}

export interface CrmCRMFirmLoginResponse {
	/** 廠商資訊 */
	firmInfo?: CrmFirmInfoData;
	/** 平台權限 */
	platformAuthority?: CrmPlatformAuthorityData;
	/** 角色權限 */
	roleAuthority?: CrmRoleAuthorityData;
	/** 使用者資訊 */
	userInfo?: CrmUserInfoData;
	/** Bombmy權限 */
	bombmyAuthority?: CrmBombmyAuthorityData;
}

/** 註冊帳號(幫賣銷售使用) */
export interface CrmCRMFirmRegisterForSaleBombmyRequest {
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
	/** 信箱 */
	email?: string;
	/** 手機號碼 */
	phone?: string;
	/** 廠商ID */
	firmId?: string;
}

export interface CrmCRMFirmRegisterForSaleBombmyResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 註冊 */
export interface CrmCRMFirmRegisterRequest {
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
	/** 信箱 */
	email?: string;
	/** 平台類型(1:bombcourse) */
	platformType?: string;
}

export interface CrmCRMFirmRegisterResponse {
	/** 註冊結果 */
	result?: string;
	/** 寄發通知信結果 */
	sendLetterResult?: string;
}

/** 訂單成立通知信(商家、顧客) */
export interface CrmCRMOrderBuildNoticeLetterRequest {
	/** 訂單編號 */
	orderId?: string;
}

export interface CrmCRMOrderBuildNoticeLetterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 顧客留言通知信(給商家) */
export interface CrmCRMOrderCustomerMessageNoticeLetterRequest {
	/** 訂單編號 */
	orderId?: string;
	/** 留言內容 */
	message?: string;
}

export interface CrmCRMOrderCustomerMessageNoticeLetterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 商家留言通知信(給顧客) */
export interface CrmCRMOrderFirmMessageNoticeLetterRequest {
	/** 訂單編號 */
	orderId?: string;
	/** 留言內容 */
	message?: string;
}

export interface CrmCRMOrderFirmMessageNoticeLetterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 訂單付款成功通知信(商家、顧客) */
export interface CrmCRMOrderPaymentCompleteNoticeLetterRequest {
	/** 訂單編號 */
	orderId?: string;
}

export interface CrmCRMOrderPaymentCompleteNoticeLetterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** CRM開通幫開課權限 */
export interface CrmCRMPermissionBombcourseRequest {
	/** 訂單ID */
	orderId?: string;
}

export interface CrmCRMPermissionBombcourseResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 登出 */
export interface CrmCRMSignOutRequest {
	/** 公司ID */
	firmId?: string;
	/** 使用者ID */
	userId?: string;
	/** 裝置ID */
	deviceId?: string;
}

export interface CrmCRMSignOutResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmChangeSmallImgResponse {
	result?: string;
}

export interface CrmCheckAccountDoubleResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 消費資訊內容 */
export interface CrmConsumeInfoData {
	/** 顧客名稱 */
	customerName?: string;
	/** 上次購買訂單ID */
	lastOrderId?: string;
	/** 上次購買訂單流水號 */
	lastOrderNo?: string;
	/**
	 * 總訂單數
	 * @format int32
	 */
	orderCount?: number;
	/**
	 * 總花費
	 * @format int32
	 */
	totalCost?: number;
	/**
	 * 平均購物天數
	 * @format double
	 */
	averageOrderDay?: number;
	/**
	 * 距離上次購買
	 * @format int32
	 */
	sinceLastOrderDay?: number;
	/**
	 * 平均花費
	 * @format int32
	 */
	averageCost?: number;
	/**
	 * 成為顧客距今
	 * @format int32
	 */
	sinceBecomeCustomerDay?: number;
}

/** 聯絡資訊內容 */
export interface CrmContactInfoData {
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客性別 */
	customerGender?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddress?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/** 公司名稱 */
	companyName?: string;
	/** 公司統一編號 */
	companyIdentifier?: string;
	/** 負責人 */
	companyChargePerson?: string;
	/** 備註 */
	memo?: string;
	/** 顧客標籤內容 */
	customerTagInfo?: CrmTagInfoData;
	/** 顧客縣市 */
	customerCounty?: string;
	/** 顧客行政區 */
	customerDistrict?: string;
	/**
	 * 顧客平均評級
	 * @format double
	 */
	ratingAvg?: number;
	/**
	 * 顧客評級數量
	 * @format int32
	 */
	ratingCount?: number;
	/** 國碼 */
	countryCode?: string;
}

/** 聯絡紀錄內容 */
export interface CrmContactLogInfoData {
	/** 聯絡紀錄ID */
	contactLogId?: string;
	/**
	 * 聯絡紀錄類型
	 * @format int32
	 */
	contactLogType?: number;
	/** 聯絡紀錄標題 */
	contactLogTitle?: string;
	/** 聯絡紀錄內容 */
	contactLogText?: string;
	/**
	 * 聯絡紀錄評級
	 * @format double
	 */
	rating?: number;
	/** 聯絡紀錄時間 */
	contactLogTime?: string;
	/** 聯絡紀錄者 */
	contactLogUser?: string;
}

export interface CrmCouponListData {
	/** 優惠券ID */
	couponId?: string;
	/** 優惠券名稱 */
	couponName?: string;
	/** 優惠券代碼 */
	couponCode?: string;
	/** 狀態(1:公開 0:關閉) */
	couponStatus?: boolean;
	/**
	 * 優惠券數量上限
	 * @format int32
	 */
	couponSaleLimit?: number;
	/**
	 * 優惠券剩餘數量
	 * @format int32
	 */
	couponRemain?: number;
	/** 使用範圍 */
	couponRange?: string[];
	/** 優惠類型(固定/百分比) */
	couponType?: string;
	/**
	 * 折扣(百分比0.0001~1 固定:整數)
	 * @format double
	 */
	couponDiscount?: number;
	/**
	 * Bombcourse優惠券數量上限
	 * @format int32
	 */
	couponSaleLimitBombcourse?: number;
	/**
	 * Bombcourse優惠券剩餘數量
	 * @format int32
	 */
	couponRemainBombcourse?: number;
}

export interface CrmCouponListResponse {
	/** 優惠券列表 */
	couponList?: CrmCouponListData[];
}

export interface CrmCouponUseRangeData {
	/** 範圍ID */
	rangeId?: string;
	/** 範圍名稱 */
	rangeName?: string;
}

export interface CrmCreateAffiliateAccountResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 建立公告 */
export interface CrmCreateAnnouncementRequest {
	/** 公告名稱 */
	name?: string;
	/** 公告內文 */
	content?: string;
	/** 公告類型 */
	type?: string[];
	/** 公告截止日期 */
	expireDate?: string;
	/**
	 * 公告平台(0:all 1:bombmy 2:crm)
	 * @format int32
	 */
	service?: number;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

export interface CrmCreateAnnouncementResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 新增幫開課方案 */
export interface CrmCreateBombcoursePlanRequest {
	/** 幫開課方案名稱 */
	bombcoursePlanName?: string;
	/** 幫開課a權限 */
	aPermission?: boolean;
	/** 幫開課b權限 */
	bPermission?: boolean;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

export interface CrmCreateBombcoursePlanResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmCreateContactLogResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 新增費用 */
export interface CrmCreateCostData {
	/** 建立者 */
	createUser?: string;
	/** 費用名稱 */
	costName?: string;
	/**
	 * 費用金額
	 * @format int32
	 */
	costAmount?: number;
	/**
	 * 舊總金額
	 * @format int32
	 */
	oldAmount?: number;
	/**
	 * 新總金額
	 * @format int32
	 */
	newAmount?: number;
}

/**
 * 優惠券
 * 新建優惠券
 */
export interface CrmCreateCouponRequest {
	/** 公司ID */
	firmId?: string;
	/** 優惠券名稱 */
	couponName?: string;
	/** 優惠券代碼 */
	couponCode?: string;
	/** 起迄時間(1:有時間限制 0:無時間限制) */
	couponExpireType?: boolean;
	/** 優惠券開始時間 */
	couponExpireBegin?: string;
	/** 優惠券結束時間 */
	couponExpireEnd?: string;
	/** 狀態(1:公開 0:關閉) */
	couponStatus?: boolean;
	/** 可與其他優惠券並用(1:可 0:不可) */
	couponCombination?: boolean;
	/** 優惠類型(固定/百分比) */
	couponType?: string;
	/**
	 * 折扣(百分比0.0001~1 固定:整數)
	 * @format double
	 */
	couponDiscount?: number;
	/**
	 * 最低訂單金額
	 * @format int32
	 */
	couponLimitPrice?: number;
	/**
	 * 優惠券數量上限
	 * @format int32
	 */
	couponSaleLimit?: number;
	/** 使用範圍 */
	couponRange?: string[];
	/** 編輯者 */
	updateUser?: string;
	/**
	 * Bombcourse優惠券數量上限
	 * @format int32
	 */
	couponSaleLimitBombcourse?: number;
}

export interface CrmCreateCouponResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 新增顧客自訂欄位 */
export interface CrmCreateCustomerCustomFieldsRequest {
	/** 顧客ID */
	orderUserId?: string;
	/** 自訂欄位名稱 */
	customFieldsName?: string;
	/** 自訂欄位內容 */
	customFieldsValue?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface CrmCreateCustomerCustomFieldsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 新增物流追蹤 */
export interface CrmCreateDeliveryLogisticsLogData {
	/** 物流商品ID */
	logisticsProductId?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
	/** 是否更新物流狀態 */
	isUpdateDeliveryStatus?: boolean;
	/** 是否更新訂單狀態 */
	isUpdateOrderStatus?: boolean;
}

export interface CrmCreateDeliveryLogisticsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 新建發票紀錄資料 */
export interface CrmCreateInvoiceLogData {
	/** 建立者 */
	createUser?: string;
	/** 類型 */
	class?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票開立時間 */
	invoiceDate?: string;
	/** 發票類別 (個人、公司、捐贈) */
	invoiceType?: string;
	/** 抬頭 (公司用) */
	invoiceTitle?: string;
	/** 統一編號 */
	invoiceIdentifier?: string;
	/** 載具類型(電子發票載具、手機條碼載具) */
	carrierType?: string;
	/** 載具資訊 */
	carrierInfo?: string;
	/** 愛心碼 (捐贈用) */
	loveCode?: string;
}

/** 新增物流商品 */
export interface CrmCreateLogisticsProductRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 編輯者 */
	updateUser?: string;
}

export interface CrmCreateLogisticsProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmCreateOrderCostResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 訂單成立紀錄資料 */
export interface CrmCreateOrderLogData {
	/** 付款方式 */
	paymentMethod?: string;
	/** 物流方式 */
	deliveryType?: string;
}

export interface CrmCreateOrderLogResponse {
	/** 成功回傳訊息 */
	result?: string;
	/** 是否寄發通知信 */
	isSendNoticeLetter?: boolean;
}

/** 新增產品 */
export interface CrmCreateOrderProductLogData {
	/** 建立者 */
	createUser?: string;
	/** 商品ID */
	productId?: string;
	/**
	 * 產品數量
	 * @format int32
	 */
	productQuantity?: number;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
}

export interface CrmCreateOrderProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmCreateOrderRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 報名ID */
	signupId?: string;
	/** 使用者名稱 */
	name?: string;
	/** 郵件 */
	email?: string;
	/** 電話 */
	phone?: string;
	/** 性別 */
	gender?: string;
	/** 縣市 */
	county?: string;
	/** 行政區 */
	district?: string;
	/** 地址 */
	address?: string;
	/** 方便收件時段 0:不指定 */
	preferPeriod?: string;
	/** 訂單資訊 */
	orderProduct?: CrmOrderProductData[];
	/** 訂單費用 */
	orderCost?: CrmOrderCostData[];
	/** 發票類別 0:個人1:公司 */
	invoiceType?: string;
	/** 載具類型 (空字串:無載具 1:以Email發送發票 2:自然人憑證 3:手機條碼) */
	invoiceCarrierType?: string;
	/** 載具資訊 */
	invoiceCarrierInfo?: string;
	/** 愛心碼 */
	loveCode?: string;
	/** 發票開立類型 0:紙本 1:電子發票 */
	invoiceIssueType?: string;
	/** 發票抬頭 */
	invoiceTitle?: string;
	/** 公司統一編號 */
	invoiceIdentifier?: string;
	/** 買受人姓名 */
	invoiceCustomerName?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/** 金流方式 */
	cashFlowType?: string;
	/** 聯盟行銷ID */
	associationId?: string;
	/** 使用者ID */
	orderUserId?: string;
	/** 編輯者 */
	updateUser?: string;
	/** 物流方式 */
	deliveryType?: string;
	/**
	 * 金流手續費
	 * @format int32
	 */
	paymentFee?: number;
	/**
	 * 物流手續費
	 * @format int32
	 */
	deliveryFee?: number;
	/** 訂單須知名稱 */
	noticeName?: string;
	/** 訂單須知說明 */
	noticeDescription?: string;
	/** 優惠券代碼 */
	couponList?: string[];
	/** 國碼 */
	countryCode?: string;
}

export interface CrmCreateOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmCreateProductRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 商品名稱 */
	productName?: string;
	/**
	 * 商品圖片 []byte
	 * @format byte
	 */
	productImg?: string;
	/** 商品副檔名 */
	productExtension?: string;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

export interface CrmCreateProductResponse {
	/** 成功回傳訊息 (可不傳 看之後與前端溝通結果) */
	result?: string;
}

export interface CrmCreateSignupOrderRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 報名ID */
	signupId?: string;
	/** 使用者名稱 */
	name?: string;
	/** 郵件 */
	email?: string;
	/** 電話 */
	phone?: string;
	/** 性別 */
	gender?: string;
	/** 縣市 */
	county?: string;
	/** 行政區 */
	district?: string;
	/** 地址 */
	address?: string;
	/** 報名訂單資訊 */
	signupOrderSession?: CrmSignupOrderSessionData[];
	/** 發票類別 0:個人1:公司 */
	invoiceType?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/** 聯盟行銷ID */
	associationId?: string;
	/** 使用者ID */
	userId?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface CrmCreateSignupOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmCreateTagRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 標籤名稱 */
	tagName?: string;
	/**
	 * 標籤類型
	 * @format int32
	 */
	tagType?: number;
	/** 快速選擇 */
	hotKey?: boolean;
	/** 編輯者 */
	updateUser?: string;
}

export interface CrmCreateTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 自訂欄位內容 */
export interface CrmCustomFieldsData {
	/** 自訂欄位ID */
	customFieldsId?: string;
	/** 自訂欄位名稱 */
	customFieldsName?: string;
	/** 自訂欄位內容 */
	customFieldsValue?: string;
}

export interface CrmCustomerDetailListData {
	/** 顧客ID */
	orderUserId?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客性別 */
	customerGender?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddress?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/** 顧客縣市 */
	customerCounty?: string;
	/** 顧客行政區 */
	customerDistrict?: string;
	/**
	 * 總訂單數
	 * @format int32
	 */
	orderCount?: number;
	/**
	 * 總花費
	 * @format int32
	 */
	totalCost?: number;
	/**
	 * 距離上次購買
	 * @format int32
	 */
	sinceLastOrderDay?: number;
	/** 國碼 */
	countryCode?: string;
}

export interface CrmCustomerDetailListResponse {
	/** 載入顧客詳細資料內容 */
	customerDetailList?: CrmCustomerDetailListData[];
}

export interface CrmCustomerListData {
	/** 顧客ID */
	orderUserId?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客標籤內容 */
	customerTagInfo?: CrmCustomerTagData[];
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddress?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/**
	 * 顧客平均評級
	 * @format double
	 */
	ratingAvg?: number;
	/**
	 * 顧客評級數量
	 * @format int32
	 */
	ratingCount?: number;
	/** 顧客性別 */
	customerGender?: string;
	/**
	 * 總花費(已付款)
	 * @format int32
	 */
	totalCost?: number;
}

export interface CrmCustomerListResponse {
	/** 顧客列表內容 */
	customerList?: CrmCustomerListData[];
	/** 本頁小記內容 */
	customerPageSubtotal?: CrmCustomerSubtotalData[];
	/** 區間總計內容 */
	customerIntervalTotal?: CrmCustomerSubtotalData;
	/** 標籤 */
	tagSelect?: CrmTagSelect[];
}

/** 顧客留言資料 */
export interface CrmCustomerMessageData {
	/** 留言內容 */
	customerMessage?: string;
}

export interface CrmCustomerSendSMSFailData {
	/** 顧客ID */
	orderUserId?: string;
	/** 電話 */
	phone?: string;
	/** 錯誤原因 */
	reason?: string;
}

export interface CrmCustomerSubtotalData {
	/**
	 * 顧客人數
	 * @format int32
	 */
	customerCount?: number;
}

export interface CrmCustomerTagData {
	/** 標籤ID */
	tagId?: string;
	/** 標籤名稱 */
	tagName?: string;
	/**
	 * 標籤類型
	 * @format int32
	 */
	tagType?: number;
}

export interface CrmDeleteAffiliateAccountResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteAnnouncementResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteBombcoursePlanResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteContactLogResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteCouponResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteCustomerCustomFieldsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteCustomerDataResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteCustomerTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteDeliveryLogisticsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 刪除帳號 */
export interface CrmDeleteFirmAccountRequest {
	/** 公司ID */
	firmId?: string;
}

export interface CrmDeleteFirmAccountResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteLogisticsProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteOrderCostResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 刪除產品 */
export interface CrmDeleteOrderProductLogData {
	/** 建立者 */
	createUser?: string;
	/** 商品ID */
	productId?: string;
	/**
	 * 產品數量
	 * @format int32
	 */
	productQuantity?: number;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 商品名稱 */
	productName?: string;
}

export interface CrmDeleteOrderProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeleteProductResponse {
	/** 成功回傳訊息 (可不傳 看之後與前端溝通結果) */
	result?: string;
}

export interface CrmDeleteTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmDeliveryData {
	/**
	 * 物流方式
	 * 0:宅配(台灣本島)
	 * 1:宅配(離島)
	 * 2:宅配(海外)
	 * 3:其他(海外)
	 * 4:郵寄
	 * 5:門市自取
	 * 6:7-11超商取貨
	 * 7:全家超商取貨
	 * 8:萊爾富超商取貨
	 * 9:不需運送(虛擬產品)
	 * @format int32
	 */
	deliveryType?: number;
	/**
	 * 物流手續費
	 * @format int32
	 */
	chargeFee?: number;
	/** 物流手續費 (預設) */
	defaultChargeFee?: string;
}

/** EZPAY補寄發票 */
export interface CrmEZPayResendInvoiceRequest {
	/** 電子郵件 */
	email?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票日期 */
	invoiceDate?: string;
	/** 隨機碼 */
	randomNumber?: string;
	/**
	 * 發票金額
	 * @format int32
	 */
	invoiceSalesAmt?: number;
}

export interface CrmEZPayResendInvoiceResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 實體商品列表 */
export interface CrmEntityLogisticsInfoData {
	/** 訂單編號 */
	orderId?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
	/** 平台類型 */
	platformType?: string;
}

export interface CrmExcelColumnData {
	value?: string;
	style?: CrmStyle;
}

export interface CrmExcelRowData {
	excelColumnData?: CrmExcelColumnData[];
}

export interface CrmExportCustomerResponse {
	/** excel資料 */
	excelRowData?: CrmExcelRowData[];
}

export interface CrmExportOrderData {
	/** 訂單流水號 */
	orderNo?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddr?: string;
	/** 發票抬頭 */
	invoiceTitle?: string;
	/** 公司統一編號 */
	invoiceIdentifier?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/** 訂購日期 */
	orderDate?: string;
	/** 產品名稱 */
	productName?: string;
	/** 商品價格 */
	productPrice?: string;
	/** 最終金額(商品+費用+退款) */
	totalPrice?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 物流方式 */
	deliveryType?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
}

export interface CrmExportOrderResponse {
	/** excel資料 */
	exportOrderData?: CrmExportOrderData[];
}

/** 付款失敗 */
export interface CrmFailPaymentLogData {
	/** 付款方式 */
	paymentMethod?: string;
	/** 第三方服務 */
	thirdParty?: string;
	/** 交易號碼 */
	tradeNo?: string;
	/** 狀態代碼 */
	statusCode?: string;
}

export interface CrmFirmBasicInfoData {
	/** 商店名稱 */
	shopName?: string;
	/** 縣市 */
	county?: string;
	/** 區域 */
	district?: string;
	/** 地址 */
	address?: string;
	/** 網站或粉絲專業網址 */
	website?: string;
	/** 聯絡人 */
	userName?: string;
	/** Email */
	userEmail?: string;
	/** 連絡電話 */
	userPhone?: string;
	/** 發票類型 (0:個人 1:公司) */
	invoiceType?: string;
	/** 抬頭 (公司用) */
	invoiceTitle?: string;
	/** 統一編號 (公司用) */
	identifier?: string;
	/** 載具類型 (空字串:無載具 1:以Email發送發票 2:自然人憑證 3:手機條碼) */
	carrierType?: string;
	/** 載具編號 */
	carrierNo?: string;
	/** 廠商圖片 */
	imgUrl?: string;
}

export interface CrmFirmBasicInfoSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmECPaySettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmEZPaySettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmFunPointSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmInfoData {
	/** 公司ID */
	firmId?: string;
	/** 廠商名稱 */
	name?: string;
	/** 廠商圖片 */
	imgUrl?: string;
	/** 幫賣剩餘直播時數 */
	liveHours?: string;
	/** Bombmy自有網域 */
	bombmyDomainName?: string;
	/** Bombmy時數到期時間 */
	liveHoursExpireDate?: string;
	/** 簡訊點數 */
	smsPoint?: string;
	/** 低於點數安全庫存量通知 */
	pointSafetyStockNotice?: boolean;
	/** 是否開啟電子豹服務(0:儲值 1:電子豹) */
	isSurenotify?: boolean;
	/** bombcourse網域(子網域或獨有網域) */
	bombcourseSubdomainName?: string;
}

export interface CrmFirmInvoiceSettingData {
	/** 是否啟用傳統紙本發票 */
	isInvoicePaper?: boolean;
	/** 是否啟用電子發票 */
	isInvoiceThirdParty?: boolean;
	/**
	 * 發票串接廠商
	 * @format int32
	 */
	channel?: number;
	/** 捐贈 */
	typeDonation?: boolean;
	/** 會員載具 */
	typeMember?: boolean;
	/** 自然人憑證 */
	typeCertificate?: boolean;
	/** 手機號碼 */
	typeBarcode?: boolean;
	/** 公司 */
	typeCompany?: boolean;
	/** 預設郵件 */
	defaultEmail?: string;
	/** 預設地址 */
	defaultAddr?: string;
	/**
	 * 台灣課稅類別 1:應稅 3:免稅
	 * @format int32
	 */
	taxType?: number;
	/**
	 * 海外課稅類別 0:經海關 1:未經海關
	 * @format int32
	 */
	customsType?: number;
	/**
	 * 開立發票顯示品項  0:顯示原先品項 1:彙整成一項名稱
	 * @format int32
	 */
	detailType?: number;
	/** 品項名稱 */
	detailName?: string;
	/** 是否自動開立發票 */
	isInvoiceAuto?: boolean;
	/**
	 * 自動開立發票時間點
	 * 0:付款後開立
	 * 1:出貨後開立
	 * 2:出貨後7天開立
	 * 3:出貨後14天開立
	 * 4:取貨時開立
	 * @format int32
	 */
	invoiceAutoAction?: number;
	/**
	 * 如果訂單內沒有電子發票資料 0:開立個人發票(會員載具)1:不開立
	 * @format int32
	 */
	invoiceDefaultAction?: number;
	/** 不開立發票(1:不開立 0:開立) */
	invoiceOff?: boolean;
}

export interface CrmFirmInvoiceSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmLinePaySettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmListData {
	/** 廠商ID */
	firmId?: string;
	/** 帳號 */
	account?: string;
	/** 商店名稱 */
	shopName?: string;
	/** 聯絡人 */
	userName?: string;
	/** Email */
	userEmail?: string;
	/** 聯絡電話 */
	userPhone?: string;
	/** bombmy平台權限 */
	bombmy?: boolean;
	/**
	 * 剩餘簡訊點數
	 * @format double
	 */
	smsPoint?: number;
	/** 上次點數異動原因 */
	lastSmsLog?: string;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
	/** 幫賣剩餘直播時數 */
	liveHours?: string;
	/** 上次直播時數異動原因 */
	lastLiveHoursLog?: string;
	/** bombmy聯盟行銷權限 */
	bombmyAssociation?: boolean;
	/** bombcourse平台 */
	bombcourse?: boolean;
	/** 最後儲值日期 */
	lastLiveHoursDate?: string;
	/** 幫開課到期日期 */
	bombcourseExpireDate?: string;
	/** 幫開課方案ID */
	bombcoursePlanId?: string;
	/** 幫開課方案名稱 */
	bombcoursePlanName?: string;
}

export interface CrmFirmListResponse {
	firmList?: CrmFirmListData[];
}

export interface CrmFirmNewebSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmOpaySettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmPCHomeSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmPIFCSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmPayUniSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmPaynowSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmPaypalSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmSMSPointLogData {
	/** 廠商ID */
	firmId?: string;
	/** 帳號 */
	account?: string;
	/** 商店名稱 */
	shopName?: string;
	/** 聯絡人 */
	userName?: string;
	/**
	 * 點數
	 * @format double
	 */
	smsPoint?: number;
	/** 異動記錄標題 */
	logTitle?: string;
	/** 異動紀錄內容 */
	logText?: string;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
	/** 紀錄者 */
	logUser?: string;
}

export interface CrmFirmSMSPointLogResponse {
	firmSmsPointLog?: CrmFirmSMSPointLogData[];
}

export interface CrmFirmStripeSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmSunPaySettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFirmZingalaSettingResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmFonts {
	sz?: string;
	name?: string;
}

export interface CrmImportOrderData {
	/** 使用者名稱 */
	name?: string;
	/** 電話 */
	phone?: string;
	/** 縣市 */
	county?: string;
	/** 行政區 */
	district?: string;
	/** 地址 */
	address?: string;
	/** 公司名稱 */
	companyName?: string;
	/** 公司統一編號 */
	companyIdentifier?: string;
	/** 負責人 */
	companyChargePerson?: string;
	/** 備註 */
	memo?: string;
	/** 郵件 */
	email?: string;
	/** 性別 */
	gender?: string;
	/** 購買日期 */
	orderDate?: string;
	/** 產品數量 */
	productQuantity?: string;
	/** 商品價格 */
	productPrice?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
}

/** 匯入訂單 */
export interface CrmImportOrderRequest {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 匯入資料 */
	importOrderData?: CrmImportOrderData[];
	/** 商品ID */
	productId?: string;
	/** 物流商品ID */
	logisticsProductId?: string;
	/** 編輯者 */
	updateUser?: string;
	/** 直播ID */
	liveId?: string;
}

export interface CrmImportOrderResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 開立電子發票紀錄資料 */
export interface CrmIssueInvoiceLogData {
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票開立時間 */
	invoiceDate?: string;
	/** 發票類別 (個人、公司、捐贈) */
	invoiceType?: string;
	/** 抬頭 (公司用) */
	invoiceTitle?: string;
	/** 統一編號 */
	invoiceIdentifier?: string;
	/** 載具類型(電子發票載具、手機條碼載具) */
	carrierType?: string;
	/** 載具資訊 */
	carrierInfo?: string;
	/** 愛心碼 (捐贈用) */
	loveCode?: string;
}

/** 開立紙本發票 */
export interface CrmIssuePaperInvoiceLogData {
	/** 發票開立類型 */
	invoiceIssueType?: string;
	/** 發票類型 (個人、公司、捐贈) */
	invoiceType?: string;
}

export interface CrmLiveHoursLogData {
	/** 直播時數異動 */
	reduceLiveHours?: string;
	/** 剩餘直播時數 */
	remainLiveHours?: string;
	/** 異動記錄標題 */
	logTitle?: string;
	/** 異動紀錄內容 */
	logText?: string;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
	/** 紀錄者 */
	logUser?: string;
}

export interface CrmLiveNameListData {
	/** 直播ID */
	liveId?: string;
	/** 直播名稱 */
	liveName?: string;
	/** 直播開始日期 */
	liveBeginDate?: string;
	/**
	 * 直播總觀看人數
	 * @format int32
	 */
	liveWatchTimes?: number;
}

export interface CrmLoadAnnouncementListPublicResponse {
	/** 公告列表資訊 */
	announcementList?: CrmAnnouncementListDataPublic[];
}

export interface CrmLoadAnnouncementListResponse {
	/** 公告列表資訊 */
	announcementList?: CrmAnnouncementListData[];
}

export interface CrmLoadBombcoursePlanListResponse {
	bombcoursePlanList?: CrmBombcoursePlanListData[];
}

export interface CrmLoadCouponUseRangeResponse {
	/** 使用範圍列表 */
	couponUseRange?: CrmCouponUseRangeData[];
}

export interface CrmLoadFirmECPaySettingResponse {
	/** 是否啟用綠界 */
	ecpayStatus?: boolean;
	/** 金流商店代碼 */
	ecpayStoreCode?: string;
	/** 綠界hashKey */
	ecpayHashKey?: string;
	/** 綠界hashIV */
	ecpayHashIv?: string;
	/** 綠界發票商店代碼 */
	ecpayInvoiceStoreCode?: string;
	/** 綠界發票hashKey */
	ecpayInvoiceHashKey?: string;
	/** 綠界發票hashIV */
	ecpayInvoiceHashIv?: string;
	/** 綠界商家檢查碼 */
	ecpayCreditCheckCode?: string;
}

export interface CrmLoadFirmEZPaySettingResponse {
	/** 是否啟用EZPay */
	ezpayStatus?: boolean;
	/** EZPay發票商店代碼 */
	ezpayInvoiceStoreCode?: string;
	/** EZPay發票hashKey */
	ezpayInvoiceHashKey?: string;
	/** EZPay發票hashIV */
	ezpayInvoiceHashIv?: string;
}

export interface CrmLoadFirmFunPointSettingResponse {
	/** 是否啟用FunPoint */
	funpointStatus?: boolean;
	/** FunPoint商店代號 */
	funpointStoreCode?: string;
	/** FunPoint Hash Key */
	funpointHashKey?: string;
	/** FunPoint Hash IV */
	funpointHashIv?: string;
	/** FunPoint 商家檢查碼 */
	funpointCreditCheckCode?: string;
	/** 是否啟用 FunPoint SDK */
	funpointSdkStatus?: boolean;
}

export interface CrmLoadFirmLinePaySettingResponse {
	/** 是否啟用LinePay */
	linePayStatus?: boolean;
	/** LinePay ChannelID */
	linePayChannelId?: string;
	/** LinePay Channel密鑰 */
	linePayChannelSecret?: string;
}

export interface CrmLoadFirmNewebSettingResponse {
	/** 是否啟用藍新 */
	newebStatus?: boolean;
	/** 金流商店代碼 */
	newebStoreCode?: string;
	/** 藍新hashKey */
	newebHashKey?: string;
	/** 藍新hashIV */
	newebHashIv?: string;
}

export interface CrmLoadFirmOpaySettingResponse {
	/** 是否啟用歐付寶 */
	opayStatus?: boolean;
	/** 金流商店代碼 */
	opayStoreCode?: string;
	/** 歐付寶hashKey */
	opayHashKey?: string;
	/** 歐付寶hashIV */
	opayHashIv?: string;
	/** 歐付寶商家檢查碼 */
	opayCreditCheckCode?: string;
}

export interface CrmLoadFirmPCHomeSettingResponse {
	/** 是否啟用支付連 */
	pchomeStatus?: boolean;
	/** pchome app id */
	pchomeAppId?: string;
	/** pchome secret */
	pchomeSecret?: string;
}

export interface CrmLoadFirmPIFCSettingResponse {
	/** 是否啟用第一融資 */
	pifcStatus?: boolean;
}

export interface CrmLoadFirmPayUniSettingResponse {
	/** 是否啟用統一 */
	payuniStatus?: boolean;
	/** 統一商店代碼 */
	payuniStoreCode?: string;
	/** 統一hashKey */
	payuniHashKey?: string;
	/** 統一hashIV */
	payuniHashIv?: string;
}

export interface CrmLoadFirmPaynowSettingResponse {
	/** 是否啟用Paynow */
	paynowStatus?: boolean;
	/** 商店代號 */
	paynowAccount?: string;
	/** 交易密碼 */
	paynowPassword?: string;
	/** 定期定額 訂閱期數 */
	subscriptionInstallment?: string;
	/** 定期定額 付款日期 */
	subscriptionPayday?: string;
	/** 私鑰 */
	paynowPrivateKey?: string;
	/** 公鑰 */
	paynowPublicKey?: string;
	/** 是否啟用 Paynow Component SDK */
	paynowSdkStatus?: boolean;
}

export interface CrmLoadFirmPaypalSettingResponse {
	/** 是否啟用Paypal */
	paypalStatus?: boolean;
	/** paypal client_id */
	paypalClientId?: string;
	/** paypal client_secret */
	paypalClientSecret?: string;
}

export interface CrmLoadFirmStripeSettingResponse {
	/** 是否啟用Stripe */
	stripeStatus?: boolean;
	/** Stripe私鑰 */
	stripePrivateKey?: string;
}

export interface CrmLoadFirmSunPaySettingResponse {
	/** 是否啟用紅陽 */
	sunpayStatus?: boolean;
	/** 紅陽 交易密碼 */
	sunpayTransPassword?: string;
	/** 商店代號(虛擬帳戶) */
	sunpayMerchantIdVAtm?: string;
	/** 商店代號 */
	sunpayMerchantIdCard?: string;
}

export interface CrmLoadFirmZingalaSettingResponse {
	/** 是否啟用銀角零卡 */
	zingalaStatus?: boolean;
	/** 統一編號/身分證字號 */
	zingalaIdentifier?: string;
}

export interface CrmLoadLiveDetailInfoResponse {
	/** 影片連結 */
	videoUrl?: string;
	/**
	 * 線上觀看次數
	 * @format int32
	 */
	liveViews?: number;
	/**
	 * 報名人數
	 * @format int32
	 */
	liveSignup?: number;
	/**
	 * 參與人數
	 * @format int32
	 */
	liveJoin?: number;
	/**
	 * 已下單
	 * @format int32
	 */
	liveOrder?: number;
	/**
	 * 已付款
	 * @format int32
	 */
	livePayment?: number;
	/**
	 * 成交金額
	 * @format int32
	 */
	liveOrderAmt?: number;
}

export interface CrmLoadLiveNameListResponse {
	/** 直播名稱列表資料 */
	liveNameList?: CrmLiveNameListData[];
}

export interface CrmLoadLivePaymentDeliveryMethodResponse {
	/** 金流 */
	payment?: CrmPaymentData[];
	/** 物流 */
	delivery?: CrmDeliveryData[];
	/** 訊息 */
	message?: string;
}

export interface CrmLoadLivePaymentMethodResponse {
	/** 金流 */
	payment?: CrmPaymentData[];
	/** 訊息 */
	Message?: string;
}

export interface CrmLoadOneAffiliateAccountResponse {
	/** 附屬帳號ID */
	affiliateAccountId?: string;
	/** 帳號名稱 */
	accountName?: string;
	/** 帳號 */
	account?: string;
	/** bombmy平台權限 */
	bombmy?: boolean;
	/**
	 * bombmy_直播相關(分析、影片、商品、直播、留言組、虛擬使用者、黑名單)
	 * @format int32
	 */
	bombmyLive?: number;
	/**
	 * bombmy_報名相關(報名、信件模板、寄送信件)
	 * @format int32
	 */
	bombmySignup?: number;
	/**
	 * bombmy_設定相關(廠商設定)
	 * @format int32
	 */
	bombmySetting?: number;
	/**
	 * 訂單權限
	 * @format int32
	 */
	order?: number;
	/**
	 * 顧客權限
	 * @format int32
	 */
	customer?: number;
	/**
	 * 廠商資訊
	 * @format int32
	 */
	firmInfo?: number;
	/**
	 * 商品權限
	 * @format int32
	 */
	product?: number;
	/** 電話 */
	phone?: string;
	/** 信箱 */
	email?: string;
	/**
	 * bombmy聯盟行銷權限
	 * @format int32
	 */
	bombmyAssociation?: number;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

export interface CrmLoadOneAnnouncementResponse {
	/** 公告ID */
	announcementId?: string;
	/** 公告名稱 */
	name?: string;
	/** 公告內文 */
	content?: string;
	/** 公告類型 */
	type?: string[];
	/** 公告截止日期 */
	expireDate?: string;
	/**
	 * 公告平台(0:all 1:bombmy 2:crm)
	 * @format int32
	 */
	service?: number;
	/** 編輯者名稱(ramate_account_name) */
	updateName?: string;
}

export interface CrmLoadOneBombcoursePlanResponse {
	/** 幫開課方案ID */
	bombcoursePlanId?: string;
	/** 幫開課方案名稱 */
	bombcoursePlanName?: string;
	/** 幫開課a權限 */
	aPermission?: boolean;
	/** 幫開課b權限 */
	bPermission?: boolean;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
	/** 編輯日期 */
	updateDate?: string;
}

export interface CrmLoadOneCouponResponse {
	/** 優惠券ID */
	couponId?: string;
	/** 優惠券名稱 */
	couponName?: string;
	/** 優惠券代碼 */
	couponCode?: string;
	/** 起迄時間(1:有時間限制 0:無時間限制) */
	couponExpireType?: boolean;
	/** 優惠券開始時間 */
	couponExpireBegin?: string;
	/** 優惠券結束時間 */
	couponExpireEnd?: string;
	/** 狀態(1:公開 0:關閉) */
	couponStatus?: boolean;
	/** 可與其他優惠券並用(1:可 0:不可) */
	couponCombination?: boolean;
	/** 優惠類型(固定/百分比) */
	couponType?: string;
	/**
	 * 折扣(百分比0.0001~1 固定:整數)
	 * @format double
	 */
	couponDiscount?: number;
	/**
	 * 最低訂單金額
	 * @format int32
	 */
	couponLimitPrice?: number;
	/**
	 * 優惠券數量上限
	 * @format int32
	 */
	couponSaleLimit?: number;
	/**
	 * 優惠券剩餘數量
	 * @format int32
	 */
	couponRemain?: number;
	/** 使用範圍 */
	couponRange?: string[];
	/**
	 * Bombcourse優惠券數量上限
	 * @format int32
	 */
	couponSaleLimitBombcourse?: number;
	/**
	 * Bombcourse優惠券剩餘數量
	 * @format int32
	 */
	couponRemainBombcourse?: number;
}

export interface CrmLoadOneCustomerResponse {
	/** 消費資訊 */
	consumeInfo?: CrmConsumeInfoData;
	/** 聯絡資訊 */
	contactInfo?: CrmContactInfoData;
	/** 標籤列表 */
	tagList?: CrmTagInfoData;
	/** 虛擬商品列表 */
	virtualLogisticsList?: CrmVirtualLogisticsInfoData[];
	/** 實體商品列表 */
	entityLogisticsList?: CrmEntityLogisticsInfoData[];
	/** 購買資訊 */
	purchaseInfo?: CrmPurchaseInfoData[];
	/** 聯絡紀錄 */
	contactLogInfo?: CrmContactLogInfoData[];
	/** 過往購買物流商品列表 */
	purchaseLogisticsList?: string[];
	/** 自訂欄位 */
	customFields?: CrmCustomFieldsData[];
}

export interface CrmLoadOneFirmInfoResponse {
	/** 廠商基本資訊 */
	firmBasicInfo?: CrmFirmBasicInfoData;
	/** 廠商發票資訊 */
	firmInvoiceSetting?: CrmFirmInvoiceSettingData;
}

export interface CrmLoadOneFirmPointSafetyStockResponse {
	/** 公司ID */
	firmId?: string;
	/**
	 * 點數安全庫存量
	 * @format int32
	 */
	pointSafetyStock?: number;
}

export interface CrmLoadOneOrderResponse {
	/** 訂單資訊 */
	orderInfo?: CrmOrderInfoData;
	/** 顧客資訊 */
	orderCustomerInfo?: CrmOrderCustomerInfoData;
	/** 運送資訊 */
	orderDeliveryInfo?: CrmOrderDeliveryInfoData;
	/** 發票資訊 */
	orderInvoiceInfo?: CrmOrderInvoiceInfoData[];
	/** 虛擬商品物流追蹤 */
	orderVirtualLogisticsInfo?: CrmOrderVirtualLogisticsInfoData[];
	/** 實體商品物流追蹤 */
	orderEntityLogisticsInfo?: CrmOrderEntityLogisticsInfoData[];
	/** 訂單紀錄 */
	orderLogInfo?: CrmOrderLogInfoData[];
	/** 顧客發票資訊 */
	orderCustomerInvoiceInfo?: CrmOrderCustomerInvoiceInfoData;
}

export interface CrmLoadOneSignupOrderResponse {
	/** 報名訂單資訊 */
	signupOrderInfo?: CrmSignupOrderInfoData;
	/** 顧客資訊 */
	signupOrderCustomerInfo?: CrmSignupOrderCustomerInfoData;
	/** 訂單紀錄 */
	signupOrderLogInfo?: CrmSignupOrderLogInfoData[];
}

export interface CrmLoadOrderCustomerCommentResponse {
	/** 訂單紀錄 */
	orderLogInfo?: CrmOrderLogInfoData[];
}

export interface CrmLoadSignupNameListResponse {
	/** 直播名稱列表資料 */
	signupNameList?: CrmSignupNameListData[];
}

export interface CrmLoadSignupPaymentDeliveryMethodResponse {
	/** 金流 */
	payment?: CrmPaymentData[];
	/** 物流 */
	delivery?: CrmDeliveryData[];
	/** 訊息 */
	message?: string;
}

export interface CrmLoanOneFirmLiveHoursLogResponse {
	liveHoursLog?: CrmLiveHoursLogData[];
}

export interface CrmLoanOneFirmSMSPointLogResponse {
	smsPointLog?: CrmSMSPointLogData[];
}

export interface CrmLogisticsProductListData {
	/** 物流商品ID */
	logisticsProductId?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
}

export interface CrmLogisticsProductListResponse {
	/** 訂單列表內容 */
	logisticsProductList?: CrmLogisticsProductListData[];
}

export interface CrmMoveImgResponse {
	/** 結果 */
	result?: string;
}

export interface CrmOrderBindAssociationResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmOrderCancelAssociationResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmOrderCostData {
	/** 費用ID */
	costId?: string;
	/** 費用名稱 */
	costName?: string;
	/**
	 * 費用金額
	 * @format int32
	 */
	costPrice?: number;
}

export interface CrmOrderCustomerInfoData {
	/** 顧客ID */
	orderUserId?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客標籤內容 */
	customerTagInfo?: CrmCustomerTagData[];
	/**
	 * 總訂單數
	 * @format int32
	 */
	orderCount?: number;
	/**
	 * 總花費
	 * @format int32
	 */
	totalCost?: number;
	/** 上次購買訂單ID */
	lastOrderId?: string;
	/** 上次購買訂單流水號 */
	lastOrderNo?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddress?: string;
	/** 報名地區 */
	signupCounty?: string;
	/** 顧客性別 */
	customerGender?: string;
	/** 國碼 */
	countryCode?: string;
}

export interface CrmOrderCustomerInvoiceInfoData {
	/** 發票類別 0:個人1:公司 */
	invoiceType?: string;
	/** 載具類型 (空字串:無載具 1:以Email發送發票 2:自然人憑證 3:手機條碼) */
	invoiceCarrierType?: string;
	/** 載具資訊 */
	invoiceCarrierInfo?: string;
	/** 愛心碼 */
	loveCode?: string;
	/** 發票開立類型 */
	invoiceIssueType?: string;
	/** 發票抬頭 */
	invoiceTitle?: string;
	/** 公司統一編號 */
	invoiceIdentifier?: string;
	/** 買受人姓名 */
	invoiceCustomerName?: string;
	/** 發票鎖定狀態 */
	lockInvoiceStatus?: boolean;
	/** 發票建立者 0:商家 1:消費者 */
	invoiceCreateUser?: string;
}

export interface CrmOrderDeliveryInfoData {
	/** 顧客ID */
	orderUserId?: string;
	/** 物流收件者名稱 */
	deliveryName?: string;
	/** 物流信箱 */
	deliveryEmail?: string;
	/** 物流電話 */
	deliveryPhone?: string;
	/** 物流方式 */
	deliveryType?: string;
	/** 物流性別 */
	deliveryGender?: string;
	/** 物流地址 */
	deliveryAddress?: string;
	/** 方便收件時段 */
	deliveryPreferPeriod?: string;
	/** 物流縣市 */
	deliveryCounty?: string;
	/** 物流行政區 */
	deliveryDistrict?: string;
	/** 國碼 */
	countryCode?: string;
}

export interface CrmOrderEntityLogisticsInfoData {
	/** 物流ID */
	logisticsId?: string;
	/** 物流商品ID */
	logisticsProductId?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
}

export interface CrmOrderInfoData {
	/** 訂單編號 */
	orderId?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 直播名稱 */
	liveName?: string;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/** 訂購日期 */
	orderDate?: string;
	/** 訂購產品 */
	orderProduct?: CrmOrderProductData[];
	/** 訂單費用 */
	orderCost?: CrmOrderCostData[];
	/** 付款方式 */
	paymentMethod?: string;
	/**
	 * 總金額(商品+費用)
	 * @format int32
	 */
	amount?: number;
	/** 顧客訂單備註 */
	orderMemoCustomer?: string;
	/** 廠商訂單備註 */
	orderMemoFirm?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 發票日期 */
	invoiceStatus?: string;
	/** 發票日期 */
	invoiceDate?: string;
	/** 物流方式 */
	deliveryType?: string;
	/** 顧客訂單網址 */
	customerOrderPageUrl?: string;
	/** 幫賣報名ID(一頁購物ID) */
	signupId?: string;
	/** 退款文字 */
	refundText?: string;
	/**
	 * 退款金額
	 * @format int32
	 */
	refundAmt?: number;
	/**
	 * 最終金額(商品+費用+退款+手續費)
	 * @format int32
	 */
	totalPrice?: number;
	/**
	 * 金流手續費
	 * @format int32
	 */
	paymentFee?: number;
	/**
	 * 物流手續費
	 * @format int32
	 */
	deliveryFee?: number;
	/** 直播ID */
	liveId?: string;
	/** 聯盟夥伴歸屬 */
	partnerName?: string;
	/** 自動建立訂單狀態(true:自動建立 false:手動建立) */
	autoGenerated?: boolean;
}

export interface CrmOrderIntervalTotalData {
	/** 區間總計內容 */
	intervalTotalData?: Record<string, number>;
	/**
	 * 區間總計總金額
	 * @format int32
	 */
	intervalTotalAmount?: number;
	/**
	 * 區間總計銷售數量
	 * @format int32
	 */
	intervalSaleCount?: number;
}

export interface CrmOrderInvoiceData {
	/** 發票狀態 0:未開立1:已開立2:註銷 3:訂單遭退款 */
	invoiceStatus?: string;
	/** 發票開立類型 0:紙本 1:電子發票 */
	invoiceIssueType?: string;
	/** 發票類型 0:個人1:公司2:捐贈 */
	invoiceType?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票日期 */
	invoiceDate?: string;
	/** 發票抬頭 (紙本顯示用) */
	invoiceTitle?: string;
	/** 公司統一編號 (紙本顯示用) */
	invoiceIdentifier?: string;
	/** 發票受買人 (紙本顯示用) */
	invoiceCustomerName?: string;
}

export interface CrmOrderInvoiceInfoData {
	/** 發票狀態 */
	invoiceStatus?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 受買人 */
	invoiceCarrierInfo?: string;
	/** 類型 */
	invoiceType?: string;
	/**
	 * 發票金額
	 * @format int32
	 */
	invoiceSalesAmount?: number;
	/** 開立時間 */
	invoiceDate?: string;
	/** 受買人編號 */
	invoiceCarrierNo?: string;
	/** 隨機碼 */
	randomNumber?: string;
	/** 發票抬頭 */
	invoiceTitle?: string;
	/** 買受人姓名 */
	invoiceCustomerName?: string;
}

export interface CrmOrderListData {
	/** 訂單編號 */
	orderId?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 直播名稱 */
	liveName?: string;
	/** 訂購產品 */
	orderProduct?: CrmOrderProductData[];
	/** 訂購日期 */
	orderDate?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/**
	 * 總金額(商品+費用)
	 * @format int32
	 */
	amount?: number;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/** 發票狀態 */
	invoiceStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票日期 */
	invoiceDate?: string;
	/** 物流方式 */
	deliveryType?: string;
	/** 顧客訂單備註 */
	orderMemoCustomer?: string;
	/** 廠商訂單備註 */
	orderMemoFirm?: string;
	/** 訂單標籤 */
	orderTag?: CrmOrderTagData;
	/** 顧客ID */
	orderUserId?: string;
	/**
	 * 最終金額(商品+費用+退款+手續費)
	 * @format int32
	 */
	totalPrice?: number;
	/** 顧客發票資訊 */
	orderCustomerInvoiceInfo?: CrmOrderCustomerInvoiceInfoData;
	/**
	 * 顧客留言數
	 * @format int32
	 */
	customerMessageCount?: number;
	/** 出貨物流商名稱 */
	shipLogisticsName?: string;
	/** 出貨追蹤號碼 */
	shipTrackNo?: string;
	/** 訂單發票資訊 */
	orderInvoiceInfo?: CrmOrderInvoiceData;
	/** 運送電話 */
	deliveryPhone?: string;
	/** 運送資料email */
	deliveryEmail?: string;
	/** 自動建立訂單狀態(true:自動建立 false:手動建立) */
	autoGenerated?: boolean;
	/** 優惠券名稱 */
	coupon?: string[];
	/** 是否從幫賣下單 0:否 1:是 */
	bombmyOrdered?: boolean;
}

export interface CrmOrderListResponse {
	/** 訂單列表內容 */
	orderList?: CrmOrderListData[];
	/** 本頁小計內容 */
	orderPageSubtotal?: CrmOrderPageSubtotalData[];
	/** 區間總計內容 */
	orderIntervalTotal?: CrmOrderIntervalTotalData;
}

export interface CrmOrderLogInfoData {
	/** 訂單紀錄ID */
	orderLogId?: string;
	/** 訂單紀錄類型 */
	orderLogType?: string;
	/** 訂單紀錄內容 */
	orderLogText?: string;
	/** 訂單紀錄時間 */
	orderLogTime?: string;
}

/** 給顧客的備註資料 */
export interface CrmOrderMemoData {
	/** 備註內容 */
	orderMemo?: string;
}

export interface CrmOrderPageSubtotalData {
	/** 本頁小計內容 */
	pageSubtotalData?: Record<string, number>;
	/**
	 * 本頁小計總金額
	 * @format int32
	 */
	pageSubtotalAmount?: number;
	/**
	 * 本頁小計銷售數量
	 * @format int32
	 */
	pageSaleCount?: number;
}

export interface CrmOrderProductData {
	/** 商品ID */
	productId?: string;
	/** 產品名稱 */
	productName?: string;
	/** 產品圖片 */
	productImgUrl?: string;
	/**
	 * 產品數量
	 * @format int32
	 */
	productQuantity?: number;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
}

export interface CrmOrderSendEmailFailData {
	/** 訂單ID */
	orderId?: string;
	/** 郵件 */
	email?: string;
	/** 錯誤原因 */
	reason?: string;
}

export interface CrmOrderSendSMSFailData {
	/** 訂單ID */
	orderId?: string;
	/** 電話 */
	phone?: string;
	/** 錯誤原因 */
	reason?: string;
}

export interface CrmOrderTagData {
	/**
	 * 訂單數量
	 * @format int32
	 */
	order?: number;
	/**
	 * 重複數量
	 * @format int32
	 */
	repeat?: number;
	/** 聯盟夥伴歸屬 */
	partnerName?: string;
}

export interface CrmOrderVirtualLogisticsInfoData {
	/** 物流ID */
	logisticsId?: string;
	/** 物流商品ID */
	logisticsProductId?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
}

export interface CrmPartnerListData {
	/** 夥伴ID */
	partnerId?: string;
	/** 夥伴名稱 */
	partnerName?: string;
	/** 聯盟ID */
	associationId?: string;
}

export interface CrmPartnerListResponse {
	/** 夥伴列表 */
	partnerList?: CrmPartnerListData[];
}

export interface CrmPasteCustomerTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmPaymentData {
	/**
	 * 付款方式 0:銀行轉帳 1:虛擬匯款帳戶 2:信用卡(一次付清)  3:信用卡(分3期) 4:信用卡(分6期) 5:信用卡(分12期) 6:信用卡(分24期) 7:零卡分期(分3期) 8:零卡分期(分6期) 9:零卡分期(分12期) 10:零卡分期(分24期)
	 * @format int32
	 */
	paymentMethod?: number;
	/** 金流介接廠商(0:綠界 1:藍新 2:歐付寶 3:銀角零卡 99:無金流) */
	cashFlowType?: string;
	/**
	 * 金流手續費
	 * @format double
	 */
	chargeFee?: number;
	/** 金流手續費類型(0:不收取 1:百分比 2:固定) */
	chargeFeeType?: string;
	/** 金流手續費 (預設) */
	defaultChargeFee?: string;
}

/** 平台權限 */
export interface CrmPlatformAuthorityData {
	/** bombmy平台 */
	bombmy?: boolean;
	/** bombmy聯盟行銷權限 */
	bombmyAssociation?: boolean;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

export interface CrmProductListData {
	/** 商品ID */
	productId?: string;
	/** 商品名稱 */
	productName?: string;
	/** 商品圖片連結 */
	productImgUrl?: string;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
}

export interface CrmProductListResponse {
	/** 商品列表 */
	productList?: CrmProductListData[];
}

/** 購買資訊內容 */
export interface CrmPurchaseInfoData {
	/** 訂單編號 */
	orderId?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 訂購日期 */
	orderDate?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/**
	 * 總金額
	 * @format int32
	 */
	amount?: number;
	/** 訂購產品 */
	orderProduct?: CrmOrderProductData[];
	/** 平台類型 */
	platformType?: string;
}

/** 根垣管理者自動登入 */
export interface CrmRamateAccountAutoLoginRequest {
	/** 根垣管理者ID */
	ramateAccountId?: string;
	/** 根垣管理者token */
	ramateAccountToken?: string;
}

export interface CrmRamateAccountAutoLoginResponse {
	/** 根垣管理者ID */
	ramateAccountId?: string;
	/** 根垣管理者暱稱 */
	ramateAccountName?: string;
	/** 根垣管理者token */
	ramateAccountToken?: string;
}

/** 根垣管理者登入 */
export interface CrmRamateAccountLoginRequest {
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
}

export interface CrmRamateAccountLoginResponse {
	/** 根垣管理者ID */
	ramateAccountId?: string;
	/** 根垣管理者暱稱 */
	ramateAccountName?: string;
	/** 根垣管理者token */
	ramateAccountToken?: string;
}

/** 根垣管理者註冊 */
export interface CrmRamateAccountRegisterRequest {
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
	/** 暱稱 */
	name?: string;
}

export interface CrmRamateAccountRegisterResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmRamateLoadAccountInfoResponse {
	/** 根垣管理者ID */
	ramateAccountId?: string;
	/** 根垣管理者暱稱 */
	ramateAccountName?: string;
	/** 根垣管理者電話 */
	ramateAccountPhone?: string;
	/** 根垣管理者信箱 */
	ramateAccountEmail?: string;
}

/** 根垣開通幫賣帳號 */
export interface CrmRamatePermissionBombmyRequest {
	/** 公司ID */
	firmId?: string;
	/** 訂單ID */
	orderId?: string;
	/**
	 * 開通期限
	 * @format int32
	 */
	permissionMonth?: number;
}

export interface CrmRamatePermissionBombmyResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmRamateUpdateAccountInfoResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 匯款回覆 */
export interface CrmRemitReplyData {
	/** 匯款時間 */
	paymentDate?: string;
	/** 匯款末五碼 */
	accountLastFiveCode?: string;
	/**
	 * 匯款金額
	 * @format int32
	 */
	amount?: number;
}

/** 角色權限 */
export interface CrmRoleAuthorityData {
	/**
	 * 訂單權限
	 * @format int32
	 */
	order?: number;
	/**
	 * 顧客權限
	 * @format int32
	 */
	customer?: number;
	/**
	 * 廠商權限
	 * @format int32
	 */
	firmInfo?: number;
	/**
	 * 商品權限
	 * @format int32
	 */
	product?: number;
}

export interface CrmSMSPointLogData {
	/**
	 * 點數
	 * @format double
	 */
	smsPoint?: number;
	/** 異動記錄標題 */
	logTitle?: string;
	/** 異動紀錄內容 */
	logText?: string;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
	/** 紀錄者 */
	logUser?: string;
}

export interface CrmSearchBombmyLivePeriodResponse {
	/** 幫賣直播時段資料 */
	bombmyLivePeriod?: CrmBombmyLivePeriodData[];
}

export interface CrmSendEmailErrorData {
	/** 錯誤原因 */
	reason?: string;
}

/** Email發送紀錄資料 */
export interface CrmSendEmailLogData {
	/** 郵件類別 (0:訂單成立 1:已付款通知) */
	emailType?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 信件主旨 */
	emailSubject?: string;
}

export interface CrmSendForgotPassLetterRequest {
	/** 帳號 */
	account?: string;
	/** 信箱 */
	email?: string;
}

export interface CrmSendForgotPassLetterResponse {
	result?: string;
}

export interface CrmSendSMSErrorData {
	/** 錯誤原因 */
	reason?: string;
}

export interface CrmSendSMSResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmSetTodayOrderData {
	/** 廠商ID */
	firmId?: string;
	/** 商品ID */
	productId?: string;
	/** 商品價格 */
	productPrice?: string;
	/**
	 * 下訂時間(開始)
	 * @format int32
	 */
	orderHourBegin?: number;
	/**
	 * 下訂分鐘(開始)
	 * @format int32
	 */
	orderMinuteBegin?: number;
	/**
	 * 下訂時間(結束)
	 * @format int32
	 */
	orderHourEnd?: number;
	/**
	 * 下訂分鐘(結束)
	 * @format int32
	 */
	orderMinuteEnd?: number;
	/**
	 * 下訂最低筆數
	 * @format int32
	 */
	orderMin?: number;
	/**
	 * 下訂浮動筆數
	 * @format int32
	 */
	orderFloat?: number;
}

/** 每日添加隨機訂單 */
export interface CrmSetTodayOrderRequest {
	/** 廠商資訊 */
	firmInfo?: CrmSetTodayOrderData[];
}

export interface CrmSetTodayOrderResponse {
	/** 結果 */
	result?: string;
}

/** 登出所有裝置 */
export interface CrmSignOutAllDeviceRequest {
	/** 公司ID */
	firmId?: string;
	/** 使用者ID */
	userId?: string;
}

export interface CrmSignOutAllDeviceResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmSignupNameListData {
	/** 報名ID */
	signupId?: string;
	/** 報名腳本名稱 */
	signupName?: string;
	/**
	 * 報名人數
	 * @format int32
	 */
	signupCount?: number;
	/** 最後編輯日期 */
	updateDate?: string;
}

export interface CrmSignupOrderCustomerInfoData {
	/** 顧客ID */
	orderUserId?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客標籤內容 */
	customerTagInfo?: CrmCustomerTagData[];
	/**
	 * 總報名訂單數
	 * @format int32
	 */
	signupOrderCount?: number;
	/**
	 * 總花費
	 * @format int32
	 */
	totalCost?: number;
	/** 上次報名訂單ID */
	lastSignupOrderId?: string;
	/** 上次報名訂單流水號 */
	lastSignupOrderNo?: string;
	/** 顧客信箱 */
	customerEmail?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 顧客地址 */
	customerAddress?: string;
	/** 報名地區 */
	signupCounty?: string;
}

export interface CrmSignupOrderInfoData {
	/** 訂單編號 */
	signupOrderId?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 報名腳本名稱 */
	signupName?: string;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 訂購日期 */
	orderDate?: string;
	/** 報名訂單資訊 */
	signupOrderSession?: CrmSignupOrderSessionData[];
	/** 訂單費用 */
	orderCost?: CrmOrderCostData[];
	/** 付款方式 */
	paymentMethod?: string;
	/**
	 * 總金額
	 * @format int32
	 */
	amount?: number;
	/** 顧客訂單備註 */
	orderMemoCustomer?: string;
	/** 廠商訂單備註 */
	orderMemoFirm?: string;
	/** 發票日期 */
	invoiceStatus?: string;
	/** 統一編號 */
	invoiceIdentifier?: string;
	/** 發票類別 0:個人1:公司 */
	invoiceType?: string;
	/** 發票日期 */
	invoiceDate?: string;
}

export interface CrmSignupOrderIntervalTotalData {
	/** 區間總計內容 */
	intervalTotalData?: Record<string, number>;
	/**
	 * 區間總計總金額
	 * @format int32
	 */
	intervalTotalAmount?: number;
}

export interface CrmSignupOrderListData {
	/** 報名訂單編號 */
	signupOrderId?: string;
	/** 訂單流水號 */
	orderNo?: string;
	/** 顧客名稱 */
	customerName?: string;
	/** 顧客電話 */
	customerPhone?: string;
	/** 報名腳本名稱 */
	signupName?: string;
	/** 報名訂單資訊 */
	signupOrderSession?: CrmSignupOrderSessionData[];
	/** 訂購日期 */
	orderDate?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/**
	 * 總金額
	 * @format int32
	 */
	amount?: number;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 發票狀態 */
	invoiceStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 發票日期 */
	invoiceDate?: string;
}

export interface CrmSignupOrderListResponse {
	/** 訂單列表內容 */
	signupOrderList?: CrmSignupOrderListData[];
	/** 本頁小計內容 */
	signupOrderPageSubtotal?: CrmSignupOrderPageSubtotalData[];
	/** 區間總計內容 */
	signupOrderIntervalTotal?: CrmSignupOrderIntervalTotalData;
}

export interface CrmSignupOrderLogInfoData {
	/** 訂單紀錄ID */
	orderLogId?: string;
	/** 訂單紀錄類型 */
	orderLogType?: string;
	/** 訂單紀錄內容 */
	orderLogText?: string;
	/** 訂單紀錄時間 */
	orderLogTime?: string;
}

export interface CrmSignupOrderPageSubtotalData {
	/** 本頁小計內容 */
	pageSubtotalData?: Record<string, number>;
	/**
	 * 本頁小計總金額
	 * @format int32
	 */
	pageSubtotalAmount?: number;
}

export interface CrmSignupOrderSessionData {
	/** 直播ID */
	liveId?: string;
	/** 場次名稱 */
	sessionName?: string;
	/** 場次內文 */
	sessionContent?: string;
	/**
	 * 場次數量
	 * @format int32
	 */
	sessionQuantity?: number;
	/**
	 * 場次價格
	 * @format int32
	 */
	sessionPrice?: number;
}

export interface CrmStyle {
	/** border */
	border?: CrmBorder;
	/** font */
	fonts?: CrmFonts;
}

/** 付款成功 */
export interface CrmSuccessPaymentLogData {
	/** 付款方式 */
	paymentMethod?: string;
	/** 第三方服務 */
	thirdParty?: string;
	/** 付款時間 */
	paymentDate?: string;
	/**
	 * 付款金額
	 * @format int32
	 */
	paymentAmount?: number;
	/** 交易號碼 */
	tradeNo?: string;
	/** 第三方服務編號 */
	thirdPartyNo?: string;
	/** PCHomePay APP ID */
	pchomePayAppId?: string;
	/** 信用卡末4碼 */
	cardFourNo?: string;
}

/** 退款成功 */
export interface CrmSuccessRefundData {
	/**
	 * 退款金額
	 * @format int32
	 */
	refundAmount?: number;
	/** 退款時間 */
	refundDate?: string;
	/** 退款理由 */
	refundText?: string;
}

/** 取號成功 */
export interface CrmSuccessTakeNumberData {
	/** 銀行代號 */
	bankCode?: string;
	/** 銀行名稱 */
	bankName?: string;
	/** 繳費帳號 */
	paymentAccount?: string;
	/**
	 * 金額
	 * @format int32
	 */
	amount?: number;
	/** 繳費到期日 */
	paymentDeadline?: string;
}

export interface CrmTagInfoData {
	/** 主要標籤 */
	mainTagList?: CrmCustomerTagData[];
	/** 職業標籤 */
	jobTagList?: CrmCustomerTagData[];
	/** 人格標籤 */
	personalityTagList?: CrmCustomerTagData[];
	/** 群組標籤 */
	groupTagList?: CrmCustomerTagData[];
}

export interface CrmTagListData {
	/** 標籤ID */
	tagId?: string;
	/** 標籤名稱 */
	tagName?: string;
	/**
	 * 標籤類型
	 * @format int32
	 */
	tagType?: number;
	/** 快速選擇 */
	hotKey?: boolean;
	/**
	 * 顧客人數
	 * @format int32
	 */
	customerCount?: number;
	/** 建立日期 */
	createDate?: string;
	/** 編輯日期 */
	updateDate?: string;
}

export interface CrmTagListResponse {
	/** 主要標籤 */
	mainTagList?: CrmTagListData[];
	/** 職業標籤 */
	jobTagList?: CrmTagListData[];
	/** 人格標籤 */
	personalityTagList?: CrmTagListData[];
	/** 群組標籤 */
	groupTagList?: CrmTagListData[];
}

export interface CrmTagSelect {
	/** 標籤ID */
	tagId?: string;
	/** 標籤名稱 */
	tagName?: string;
	/**
	 * 標籤類型
	 * @format int32
	 */
	tagType?: number;
}

export interface CrmTestHeaderResponse {
	/** header內容 */
	header?: string;
	/** 結果 */
	result?: string;
}

/** 編輯帳號資訊 */
export interface CrmUpdateAccountInfoRequest {
	/** 公司ID */
	firmId?: string;
	/** 使用者ID */
	userId?: string;
	/** 使用者名稱 */
	userName?: string;
	/** 使用者電話 */
	userPhone?: string;
	/** 使用者信箱 */
	userEmail?: string;
	/** 舊密碼 */
	oldPassword?: string;
	/** 新密碼 */
	newPassword?: string;
	/** 登入裝置狀態(1:只能登入一個設備 0:可登入複數設備) */
	oneDeviceStatus?: boolean;
	/** 自動開啟分頁(1:點擊開啟分頁 0:點擊原視窗顯示) */
	openPaginationStatus?: boolean;
}

export interface CrmUpdateAccountInfoResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateAffiliateAccountResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateAnnouncementResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateBombcourseExpireDateResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 編輯幫開課方案 */
export interface CrmUpdateBombcoursePlanRequest {
	/** 幫開課方案ID */
	bombcoursePlanId?: string;
	/** 幫開課方案名稱 */
	bombcoursePlanName?: string;
	/** 幫開課a權限 */
	aPermission?: boolean;
	/** 幫開課b權限 */
	bPermission?: boolean;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

export interface CrmUpdateBombcoursePlanResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateContactLogResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateCouponResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateCustomerCustomFieldsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateCustomerDataResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 更新物流追蹤 */
export interface CrmUpdateDeliveryLogisticsLogData {
	/** 物流商品ID */
	logisticsProductId?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
	/** 是否更新物流狀態 */
	isUpdateDeliveryStatus?: boolean;
	/** 是否更新訂單狀態 */
	isUpdateOrderStatus?: boolean;
}

export interface CrmUpdateDeliveryLogisticsResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 更改訂單物流方式 */
export interface CrmUpdateDeliveryTypeLogData {
	/** 建立者 */
	createUser?: string;
	/** 舊物流方式 */
	oldDeliveryType?: string;
	/** 新物流方式 */
	newDeliveryType?: string;
	/**
	 * 舊物流手續費
	 * @format int32
	 */
	oldDeliveryFee?: number;
	/**
	 * 新物流手續費
	 * @format int32
	 */
	newDeliveryFee?: number;
	/**
	 * 舊最終金額(order_amt+退款+手續費)
	 * @format int32
	 */
	oldTotalPrice?: number;
	/**
	 * 新最終金額(order_amt+退款+手續費)
	 * @format int32
	 */
	newTotalPrice?: number;
}

export interface CrmUpdateDeliveryTypeResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateFirmAuthorityResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateFirmBombcoursePlanResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateFirmLiveHoursResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateFirmSMSPointResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 忘記密碼_編輯新密碼 */
export interface CrmUpdateForgotPassRequest {
	/** Token */
	token?: string;
	/** 新密碼 */
	password?: string;
}

export interface CrmUpdateForgotPassResponse {
	result?: string;
}

/** 修改發票紀錄資料 */
export interface CrmUpdateInvoiceLogData {
	/** 建立者 */
	createUser?: string;
	/** 類型 */
	class?: string;
	/** 發票號碼 */
	invoiceNo?: string;
	/** 發票開立時間 */
	invoiceDate?: string;
	/** 發票類別 (個人、公司、捐贈) */
	invoiceType?: string;
	/** 抬頭 (公司用) */
	invoiceTitle?: string;
	/** 統一編號 */
	invoiceIdentifier?: string;
	/** 載具類型(電子發票載具、手機條碼載具) */
	carrierType?: string;
	/** 載具資訊 */
	carrierInfo?: string;
	/** 愛心碼 (捐贈用) */
	loveCode?: string;
}

export interface CrmUpdateLastLiveHoursDateResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateLogisticsProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderCostResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderDeliveryDataResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderLockInvoiceStatusResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderMemoCustomerResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderMemoFirmResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateOrderMemoResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 編輯產品 */
export interface CrmUpdateOrderProductLogData {
	/** 建立者 */
	createUser?: string;
	/** 商品ID */
	productId?: string;
	/**
	 * 舊產品數量
	 * @format int32
	 */
	oldProductQuantity?: number;
	/**
	 * 舊商品價格
	 * @format int32
	 */
	oldProductPrice?: number;
	/**
	 * 新產品數量
	 * @format int32
	 */
	newProductQuantity?: number;
	/**
	 * 新商品價格
	 * @format int32
	 */
	newProductPrice?: number;
	/** 商品名稱 */
	productName?: string;
}

export interface CrmUpdateOrderProductResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 更新訂單狀態 */
export interface CrmUpdateOrderStatusLogData {
	/** 舊物流狀態 */
	oldDeliveryStatus?: string;
	/** 新物流狀態 */
	newDeliveryStatus?: string;
	/** 舊金流狀態 */
	oldPaymentStatus?: string;
	/** 新金流狀態 */
	newPaymentStatus?: string;
	/** 舊訂單狀態 */
	oldOrderStatus?: string;
	/** 新訂單狀態 */
	newOrderStatus?: string;
	/** 物流追蹤狀態 (為true 則為物流追蹤而來) */
	deliveryLogisticsStatus?: boolean;
}

export interface CrmUpdateOrderStatusResponse {
	/** 成功回傳訊息 */
	result?: string;
	/** 是否寄發通知信 */
	isSendNoticeLetter?: boolean;
}

export interface CrmUpdatePaymentDeliveryStatusResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 更改訂單付款方式 */
export interface CrmUpdatePaymentMethodLogData {
	/** 建立者 */
	createUser?: string;
	/** 舊付款方式 */
	oldPaymentMethod?: string;
	/** 新付款方式 */
	newPaymentMethod?: string;
	/**
	 * 舊金流手續費
	 * @format int32
	 */
	oldPaymentFee?: number;
	/**
	 * 新金流手續費
	 * @format int32
	 */
	newPaymentFee?: number;
	/**
	 * //舊金流狀態
	 *    string old_payment_status = 6;
	 *    //新金流狀態
	 *    string new_payment_status = 7;
	 * 舊最終金額(order_amt+退款+手續費)
	 * @format int32
	 */
	oldTotalPrice?: number;
	/**
	 * 新最終金額(order_amt+退款+手續費)
	 * @format int32
	 */
	newTotalPrice?: number;
}

export interface CrmUpdatePaymentMethodResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdatePointSafetyStockResponse {
	/** 成功回傳訊息 */
	result?: string;
}

export interface CrmUpdateProductResponse {
	/** 成功回傳訊息 (可不傳 看之後與前端溝通結果) */
	result?: string;
}

export interface CrmUpdateTagResponse {
	/** 成功回傳訊息 */
	result?: string;
}

/** 使用者資訊 */
export interface CrmUserInfoData {
	/** 使用者ID */
	userId?: string;
	/** 使用者名稱 */
	userName?: string;
	/** 使用者電話 */
	userPhone?: string;
	/** 使用者信箱 */
	userEmail?: string;
	/** 登入裝置狀態(1:只能登入一個設備 0:可登入複數設備) */
	oneDeviceStatus?: boolean;
	/** token */
	token?: string;
	/** 授權權杖 */
	authorizationToken?: string;
	/** 自動開啟分頁(1:點擊開啟分頁 0:點擊原視窗顯示) */
	openPaginationStatus?: boolean;
}

/** 作廢電子發票紀錄資料 */
export interface CrmValidInvoiceLogData {
	/** 發票號碼 */
	invoiceNo?: string;
	/** 作廢原因 */
	reason?: string;
}

/** 虛擬商品列表 */
export interface CrmVirtualLogisticsInfoData {
	/** 訂單編號 */
	orderId?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** 平台類型 */
	platformType?: string;
}

export interface ProtobufAny {
	'@type'?: string;
	[key: string]: any;
}

export interface RpcStatus {
	/** @format int32 */
	code?: number;
	message?: string;
	details?: ProtobufAny[];
}

/** 刪除優惠券 */
export interface ProductDeleteCouponPayload {
	/** 公司ID */
	firmId?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除顧客自訂欄位 */
export interface CustomerDeleteCustomerCustomFieldsPayload {
	/** 顧客ID */
	orderUserId?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯顧客自訂欄位 */
export interface CustomerUpdateCustomerCustomFieldsPayload {
	/** 顧客ID */
	orderUserId?: string;
	/** 自訂欄位名稱 */
	customFieldsName?: string;
	/** 自訂欄位內容 */
	customFieldsValue?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除顧客標籤 */
export interface CustomerDeleteCustomerTagPayload {
	/** 公司ID */
	firmId?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface CustomerUpdateTagPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 標籤名稱 */
	tagName?: string;
	/**
	 * 標籤類型
	 * @format int32
	 */
	tagType?: number;
	/** 快速選擇 */
	hotKey?: boolean;
	/** 編輯者 */
	updateUser?: string;
}

export interface CustomerBatchPasteCustomerTagPayload {
	/** 公司ID */
	firmId?: string;
	/** 標籤ID */
	tagId?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 新增聯絡紀錄 */
export interface CustomerCreateContactLogPayload {
	/**
	 * 聯絡紀錄類型
	 * @format int32
	 */
	contactLogType?: number;
	/** 聯絡紀錄標題 */
	contactLogTitle?: string;
	/** 聯絡紀錄內容 */
	contactLogText?: string;
	/**
	 * 聯絡紀錄評級
	 * @format double
	 */
	rating?: number;
	/** 編輯者 */
	updateUser?: string;
	/** 紀錄者 */
	contactLogUser?: string;
	/** 聯絡時間 */
	contactLogTime?: string;
}

export interface CustomerDeleteContactLogPayload {
	/** 編輯者 */
	updateUser?: string;
}

export interface CustomerUpdateContactLogPayload {
	/** 聯絡紀錄標題 */
	contactLogTitle?: string;
	/** 聯絡紀錄內容 */
	contactLogText?: string;
	/**
	 * 聯絡紀錄評級
	 * @format double
	 */
	rating?: number;
	/** 編輯者 */
	updateUser?: string;
	/** 紀錄者 */
	contactLogUser?: string;
	/** 聯絡時間 */
	contactLogTime?: string;
}

export interface FirmInfoCreateAffiliateAccountPayload {
	/** 帳號名稱 */
	accountName?: string;
	/** 帳號 */
	account?: string;
	/** 密碼 */
	password?: string;
	/** bombmy平台權限 */
	bombmy?: boolean;
	/**
	 * bombmy_直播相關(分析、影片、商品、直播、留言組、虛擬使用者、黑名單)
	 * @format int32
	 */
	bombmyLive?: number;
	/**
	 * bombmy_報名相關(報名、信件模板、寄送信件)
	 * @format int32
	 */
	bombmySignup?: number;
	/**
	 * bombmy_設定相關(廠商設定)
	 * @format int32
	 */
	bombmySetting?: number;
	/**
	 * 訂單權限
	 * @format int32
	 */
	order?: number;
	/**
	 * 顧客權限
	 * @format int32
	 */
	customer?: number;
	/**
	 * 廠商資訊
	 * @format int32
	 */
	firmInfo?: number;
	/**
	 * 商品權限
	 * @format int32
	 */
	product?: number;
	/** 編輯者 */
	updateUser?: string;
	/** 電話 */
	phone?: string;
	/** 信箱 */
	email?: string;
	/**
	 * bombmy聯盟行銷權限
	 * @format int32
	 */
	bombmyAssociation?: number;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

/** 刪除附屬帳號 */
export interface FirmInfoDeleteAffiliateAccountPayload {
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯附屬帳號 */
export interface FirmInfoUpdateAffiliateAccountPayload {
	/** 帳號名稱 */
	accountName?: string;
	/** 帳號 */
	account?: string;
	/** bombmy平台權限 */
	bombmy?: boolean;
	/**
	 * bombmy_直播相關(分析、影片、商品、直播、留言組、虛擬使用者、黑名單)
	 * @format int32
	 */
	bombmyLive?: number;
	/**
	 * bombmy_報名相關(報名、信件模板、寄送信件)
	 * @format int32
	 */
	bombmySignup?: number;
	/**
	 * bombmy_設定相關(廠商設定)
	 * @format int32
	 */
	bombmySetting?: number;
	/**
	 * 訂單權限
	 * @format int32
	 */
	order?: number;
	/**
	 * 顧客權限
	 * @format int32
	 */
	customer?: number;
	/**
	 * 廠商資訊
	 * @format int32
	 */
	firmInfo?: number;
	/**
	 * 商品權限
	 * @format int32
	 */
	product?: number;
	/** 編輯者 */
	updateUser?: string;
	/** 電話 */
	phone?: string;
	/** 信箱 */
	email?: string;
	/**
	 * bombmy聯盟行銷權限
	 * @format int32
	 */
	bombmyAssociation?: number;
	/** 密碼 */
	password?: string;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

export interface FirmInfoFirmBasicInfoSettingPayload {
	/** 商店名稱 */
	shopName?: string;
	/** 縣市 */
	county?: string;
	/** 區域 */
	district?: string;
	/** 地址 */
	address?: string;
	/** 網站或粉絲專業網址 */
	website?: string;
	/** 聯絡人 */
	userName?: string;
	/** Email */
	userEmail?: string;
	/** 連絡電話 */
	userPhone?: string;
	/** 發票類型 (0:個人 1:公司) */
	invoiceType?: string;
	/** 抬頭 (公司用) */
	invoiceTitle?: string;
	/** 統一編號 (公司用) */
	identifier?: string;
	/** 載具類型 (空字串:無載具 1:以Email發送發票 2:自然人憑證 3:手機條碼) */
	carrierType?: string;
	/** 載具編號 */
	carrierNo?: string;
	/** 廠商圖片 */
	imgUrl?: string;
	/**
	 * 商品圖片 []byte
	 * @format byte
	 */
	img?: string;
	/** 商品副檔名 */
	extension?: string;
}

export interface FirmInfoFirmEcPaySettingPayload {
	/** 是否啟用綠界 */
	ecpayStatus?: boolean;
	/** 金流商店代碼 */
	ecpayStoreCode?: string;
	/** 綠界hashKey */
	ecpayHashKey?: string;
	/** 綠界hashIV */
	ecpayHashIv?: string;
	/** 綠界發票商店代碼 */
	ecpayInvoiceStoreCode?: string;
	/** 綠界發票hashKey */
	ecpayInvoiceHashKey?: string;
	/** 綠界發票hashIV */
	ecpayInvoiceHashIv?: string;
	/** 綠界商家檢查碼 */
	ecpayCreditCheckCode?: string;
}

/** 廠商設定EZPay發票第三方服務 */
export interface FirmInfoFirmEzPaySettingPayload {
	/** 是否啟用EZPay */
	ezpayStatus?: boolean;
	/** EZPay發票商店代碼 */
	ezpayInvoiceStoreCode?: string;
	/** EZPay發票hashKey */
	ezpayInvoiceHashKey?: string;
	/** EZPay發票hashIV */
	ezpayInvoiceHashIv?: string;
}

export interface FirmInfoFirmFunPointSettingPayload {
	/** 是否啟用FunPoint */
	funpointStatus?: boolean;
	/** FunPoint商店代號 */
	funpointStoreCode?: string;
	/** FunPoint Hash Key */
	funpointHashKey?: string;
	/** FunPoint Hash IV */
	funpointHashIv?: string;
	/** FunPoint 商家檢查碼 */
	funpointCreditCheckCode?: string;
	/** 是否啟用 FunPoint SDK */
	funpointSdkStatus?: boolean;
}

export interface FirmInfoFirmInvoiceSettingPayload {
	/** 是否啟用傳統紙本發票 */
	isInvoicePaper?: boolean;
	/** 是否啟用電子發票 */
	isInvoiceThirdParty?: boolean;
	/**
	 * 發票串接廠商
	 * @format int32
	 */
	channel?: number;
	/** 捐贈 */
	typeDonation?: boolean;
	/** 會員載具 */
	typeMember?: boolean;
	/** 自然人憑證 */
	typeCertificate?: boolean;
	/** 手機號碼 */
	typeBarcode?: boolean;
	/** 公司 */
	typeCompany?: boolean;
	/** 預設郵件 */
	defaultEmail?: string;
	/** 預設地址 */
	defaultAddr?: string;
	/**
	 * 台灣課稅類別 1:應稅 3:免稅
	 * @format int32
	 */
	taxType?: number;
	/**
	 * 海外課稅類別 0:經海關 1:未經海關
	 * @format int32
	 */
	customsType?: number;
	/**
	 * 開立發票顯示品項  0:顯示原先品項 1:彙整成一項名稱
	 * @format int32
	 */
	detailType?: number;
	/** 品項名稱 */
	detailName?: string;
	/** 是否自動開立發票 */
	isInvoiceAuto?: boolean;
	/**
	 * 自動開立發票時間點
	 * 0:付款後開立
	 * 1:出貨後開立
	 * 2:出貨後7天開立
	 * 3:出貨後14天開立
	 * 4:取貨時開立
	 * @format int32
	 */
	invoiceAutoAction?: number;
	/**
	 * 如果訂單內沒有電子發票資料 0:開立個人發票(會員載具)1:不開立
	 * @format int32
	 */
	invoiceDefaultAction?: number;
	/** 編輯者 */
	updateUser?: string;
	/** 不開立發票(1:不開立 0:開立) */
	invoiceOff?: boolean;
}

export interface FirmInfoFirmLinePaySettingPayload {
	/** 是否啟用LinePay */
	linePayStatus?: boolean;
	/** LinePay ChannelID */
	linePayChannelId?: string;
	/** LinePay Channel密鑰 */
	linePayChannelSecret?: string;
}

export interface FirmInfoFirmNewebSettingPayload {
	/** 是否啟用藍新 */
	newebStatus?: boolean;
	/** 金流商店代碼 */
	newebStoreCode?: string;
	/** 藍新hashKey */
	newebHashKey?: string;
	/** 藍新hashIV */
	newebHashIv?: string;
}

export interface FirmInfoFirmOpaySettingPayload {
	/** 是否啟用歐付寶 */
	opayStatus?: boolean;
	/** 金流商店代碼 */
	opayStoreCode?: string;
	/** 歐付寶hashKey */
	opayHashKey?: string;
	/** 歐付寶hashIV */
	opayHashIv?: string;
	/** 歐付寶商家檢查碼 */
	opayCreditCheckCode?: string;
}

export interface FirmInfoFirmPaynowSettingPayload {
	/** 是否啟用Paynow */
	paynowStatus?: boolean;
	/** 商店代號 */
	paynowAccount?: string;
	/** 交易密碼 */
	paynowPassword?: string;
	/** 定期定額 訂閱期數 */
	subscriptionInstallment?: string;
	/** 定期定額 付款日期 */
	subscriptionPayday?: string;
	/** 私鑰 */
	paynowPrivateKey?: string;
	/** 公鑰 */
	paynowPublicKey?: string;
	/** 是否啟用 Paynow Component SDK */
	paynowSdkStatus?: boolean;
}

export interface FirmInfoFirmPaypalSettingPayload {
	/** 是否啟用Paypal */
	paypalStatus?: boolean;
	/** paypal client_id */
	paypalClientId?: string;
	/** paypal client_secret */
	paypalClientSecret?: string;
}

/** 廠商設定統一第三方服務 */
export interface FirmInfoFirmPayUniSettingPayload {
	/** 是否啟用統一 */
	payuniStatus?: boolean;
	/** 統一商店代碼 */
	payuniStoreCode?: string;
	/** 統一hashKey */
	payuniHashKey?: string;
	/** 統一hashIV */
	payuniHashIv?: string;
}

export interface FirmInfoFirmPcHomeSettingPayload {
	/** 是否啟用支付連 */
	pchomeStatus?: boolean;
	/** pchome app id */
	pchomeAppId?: string;
	/** pchome secret */
	pchomeSecret?: string;
}

export interface FirmInfoFirmPifcSettingPayload {
	/** 是否啟用第一融資 */
	pifcStatus?: boolean;
}

export interface FirmInfoFirmStripeSettingPayload {
	/** 是否啟用Stripe */
	stripeStatus?: boolean;
	/** Stripe私鑰 */
	stripePrivateKey?: string;
}

export interface FirmInfoFirmSunPaySettingPayload {
	/** 是否啟用紅陽 */
	sunpayStatus?: boolean;
	/** 紅陽 交易密碼 */
	sunpayTransPassword?: string;
	/** 商店代號(虛擬帳戶) */
	sunpayMerchantIdVAtm?: string;
	/** 商店代號(信用卡) */
	sunpayMerchantIdCard?: string;
}

export interface FirmInfoFirmZingalaSettingPayload {
	/** 是否啟用銀角零卡 */
	zingalaStatus?: boolean;
	/** 統一編號/身分證字號 */
	zingalaIdentifier?: string;
}

/** 編輯廠商點數安全庫存量 */
export interface FirmInfoUpdatePointSafetyStockPayload {
	/**
	 * 點數安全庫存量
	 * @format int32
	 */
	pointSafetyStock?: number;
}

/** 刪除物流商品 */
export interface ProductDeleteLogisticsProductPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯物流商品 */
export interface ProductUpdateLogisticsProductPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 物流商品名稱 */
	logisticsProductName?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除訂單 */
export interface OrderDeleteOrderPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 批次刪除訂單 */
export interface OrderBatchDeleteOrderPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 批次編輯訂單 */
export interface OrderBatchUpdateOrderPayload {
	/** 公司ID */
	firmId?: string;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderCreateOrderLogPayload {
	/** 公司ID */
	firmId?: string;
	/**
	 * 訂單記錄類型 (
	 * 0:訂單成立
	 * 1:Email發送
	 * 2:新建發票
	 * 3:更改訂單付款方式
	 * 4:更新訂單狀態
	 * 5:付款成功
	 * 6:給顧客的備註
	 * 7:顧客留言
	 * 8:付款失敗
	 * 9:取號成功
	 * 10:匯款回覆
	 * 11:訂單退款成功
	 * 12:新增費用
	 * 13:新增產品
	 * 14:更改訂單物流方式
	 * 15:修改發票
	 * 16:開立電子發票
	 * 17:作廢電子發票
	 * 18:新增物流追蹤
	 * 19:更新物流追蹤)
	 */
	orderLogType?: string;
	/** 訂單成立紀錄資料 */
	createOrderLogData?: CrmCreateOrderLogData;
	/** Email發送紀錄資料 */
	sendEmailLogData?: CrmSendEmailLogData;
	/** 新建發票 */
	createInvoiceLogData?: CrmCreateInvoiceLogData;
	/** 更改訂單付款方式 */
	updatePaymentMethodLogData?: CrmUpdatePaymentMethodLogData;
	/** 更新訂單狀態 */
	updateOrderStatusLogData?: CrmUpdateOrderStatusLogData;
	/** 付款成功 */
	successPaymentLogData?: CrmSuccessPaymentLogData;
	/** 給顧客的備註 */
	orderMemoData?: CrmOrderMemoData;
	/** 顧客留言 */
	customerMessageData?: CrmCustomerMessageData;
	/** 付款失敗 */
	failPaymentLogData?: CrmFailPaymentLogData;
	/** 編輯者 */
	updateUser?: string;
	/** 取號成功 */
	successTakeNumberData?: CrmSuccessTakeNumberData;
	/** 匯款回覆 */
	remitReplyData?: CrmRemitReplyData;
	/** 訂單退款成功 */
	successRefundData?: CrmSuccessRefundData;
	/** 新增費用 */
	createCostData?: CrmCreateCostData;
	/** 新增產品 */
	createOrderProductLogData?: CrmCreateOrderProductLogData;
	/** 更改訂單物流方式 */
	updateDeliveryTypeLogData?: CrmUpdateDeliveryTypeLogData;
	/** 修改發票 */
	updateInvoiceLogData?: CrmUpdateInvoiceLogData;
	/** 開立電子發票 */
	issueInvoiceLogData?: CrmIssueInvoiceLogData;
	/** 作廢電子發票 */
	validInvoiceLogData?: CrmValidInvoiceLogData;
	/** 新增物流追蹤 */
	createDeliveryLogisticsLogData?: CrmCreateDeliveryLogisticsLogData;
	/** 更新物流追蹤 */
	updateDeliveryLogisticsLogData?: CrmUpdateDeliveryLogisticsLogData;
	/** 開立紙本發票 */
	issuePaperInvoiceLogData?: CrmIssuePaperInvoiceLogData;
	/** 編輯產品 */
	updateOrderProductLogData?: CrmUpdateOrderProductLogData;
	/** 刪除產品 */
	deleteOrderProductLogData?: CrmDeleteOrderProductLogData;
}

export interface OrderCreateDeliveryLogisticsPayload {
	/** 公司ID */
	firmId?: string;
	/** 物流商品ID */
	logisticsProductId?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
	/** 是否更新物流狀態 */
	isUpdateDeliveryStatus?: boolean;
	/** 是否更新訂單狀態 */
	isUpdateOrderStatus?: boolean;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除物流追蹤 */
export interface OrderDeleteDeliveryLogisticsPayload {
	/** 公司ID */
	firmId?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯物流追蹤 */
export interface OrderUpdateDeliveryLogisticsPayload {
	/** 公司ID */
	firmId?: string;
	/** 物流商品ID */
	logisticsProductId?: string;
	/**
	 * 物流商品類別
	 * @format int32
	 */
	logisticsProductType?: number;
	/** 序號 */
	serialNo?: string;
	/** 提供時間 */
	provideTime?: string;
	/** 開通時間 */
	activateTime?: string;
	/** S/N */
	SN?: string;
	/** 物流商名稱 */
	logisticsName?: string;
	/** 物流商查詢網址 */
	logisticsUrl?: string;
	/** 追蹤號碼 */
	trackNo?: string;
	/** 出貨日期 */
	shipDate?: string;
	/** 抵達日期 */
	deliveryDate?: string;
	/** 是否更新物流狀態 */
	isUpdateDeliveryStatus?: boolean;
	/** 是否更新訂單狀態 */
	isUpdateOrderStatus?: boolean;
	/** 編輯者 */
	updateUser?: string;
}

/** OrderBindAssociationRequest 訂單綁定聯盟行銷 */
export interface OrderOrderBindAssociationPayload {
	/** 公司ID */
	firmId?: string;
	/** 聯盟ID */
	associationId?: string;
}

/** OrderCancelAssociationRequest 訂單解除聯盟行銷 */
export interface OrderOrderCancelAssociationPayload {
	/** 公司ID */
	firmId?: string;
}

export interface OrderCreateOrderCostPayload {
	/** 公司ID */
	firmId?: string;
	/** 費用名稱 */
	costName?: string;
	/**
	 * 費用金額
	 * @format int32
	 */
	costPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除訂單費用 */
export interface OrderDeleteOrderCostPayload {
	/** 公司ID */
	firmId?: string;
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯訂單費用 */
export interface OrderUpdateOrderCostPayload {
	/** 公司ID */
	firmId?: string;
	/** 費用名稱 */
	costName?: string;
	/**
	 * 費用金額
	 * @format int32
	 */
	costPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

/** 新增訂單產品內容 */
export interface OrderCreateOrderProductPayload {
	/** 公司ID */
	firmId?: string;
	/** 商品ID */
	productId?: string;
	/**
	 * 產品數量
	 * @format int32
	 */
	productQuantity?: number;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdateOrderProductPayload {
	/** 公司ID */
	firmId?: string;
	/**
	 * 產品數量
	 * @format int32
	 */
	productQuantity?: number;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

/** 更改物流方式 */
export interface OrderUpdateDeliveryTypePayload {
	/** 公司ID */
	firmId?: string;
	/**
	 * 物流方式類型(
	 *        0:宅配(台灣本島)
	 *        1:宅配(離島)
	 *        2:宅配(海外)
	 *        3:其他(海外)
	 *        4:郵寄
	 *        5:門市自取
	 *        6:7-11超商取貨
	 *        7:全家超商取貨
	 *        8:萊爾富超商取貨
	 *        9:不需運送(虛擬產品)
	 */
	deliveryType?: string;
	/** 編輯者 */
	updateUser?: string;
	/**
	 * 物流手續費
	 * @format int32
	 */
	deliveryFee?: number;
}

export interface OrderUpdateOrderDeliveryDataPayload {
	/** 公司ID */
	firmId?: string;
	/** 顧客ID */
	orderUserId?: string;
	/** 物流收件者名稱 */
	deliveryName?: string;
	/** 物流信箱 */
	deliveryEmail?: string;
	/** 物流電話 */
	deliveryPhone?: string;
	/** 物流方式 */
	deliveryType?: string;
	/** 物流性別 */
	deliveryGender?: string;
	/** 是否同步更新顧客資料 */
	isUpdateUserData?: boolean;
	/** 編輯者 */
	updateUser?: string;
	/** 物流地址 */
	deliveryAddress?: string;
	/** 方便收件時段 */
	deliveryPreferPeriod?: string;
	/** 物流縣市 */
	deliveryCounty?: string;
	/** 物流行政區 */
	deliveryDistrict?: string;
	/** 國碼 */
	countryCode?: string;
}

/** 更改訂單發票鎖定狀態 */
export interface OrderUpdateOrderLockInvoiceStatusPayload {
	/** 公司ID */
	firmId?: string;
	/** 發票鎖定狀態 */
	lockInvoiceStatus?: boolean;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdateOrderMemoPayload {
	/** 公司ID */
	firmId?: string;
	/** 顧客訂單備註 */
	orderMemoCustomer?: string;
	/** 廠商訂單備註 */
	orderMemoFirm?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdateOrderMemoCustomerPayload {
	/** 公司ID */
	firmId?: string;
	/** 顧客訂單備註 */
	orderMemoCustomer?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdateOrderMemoFirmPayload {
	/** 公司ID */
	firmId?: string;
	/** 廠商訂單備註 */
	orderMemoFirm?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdateOrderStatusPayload {
	/** 公司ID */
	firmId?: string;
	/** 訂單狀態 */
	orderStatus?: string;
	/** 編輯者 */
	updateUser?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
}

export interface OrderUpdatePaymentDeliveryStatusPayload {
	/** 公司ID */
	firmId?: string;
	/** 物流狀態 */
	deliveryStatus?: string;
	/** 金流狀態 */
	paymentStatus?: string;
	/** 編輯者 */
	updateUser?: string;
}

export interface OrderUpdatePaymentMethodPayload {
	/** 公司ID */
	firmId?: string;
	/** 付款方式 */
	paymentMethod?: string;
	/** 編輯者 */
	updateUser?: string;
	/**
	 * 金流手續費
	 * @format int32
	 */
	paymentFee?: number;
}

export interface ProductUpdateProductPayload {
	/** 公司ID */
	firmId?: string;
	/** 平台類型 */
	platformType?: string;
	/** 商品名稱 */
	productName?: string;
	/**
	 * 商品圖片 []byte
	 * @format byte
	 */
	productImg?: string;
	/** 商品副檔名 */
	productExtension?: string;
	/**
	 * 商品價格
	 * @format int32
	 */
	productPrice?: number;
	/** 編輯者 */
	updateUser?: string;
}

/** 刪除公告 */
export interface RamateVerifyDeleteAnnouncementPayload {
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

/** 編輯公告 */
export interface RamateVerifyUpdateAnnouncementPayload {
	/** 公告名稱 */
	name?: string;
	/** 公告內文 */
	content?: string;
	/** 公告類型 */
	type?: string[];
	/** 公告截止日期 */
	expireDate?: string;
	/**
	 * 公告平台(0:all 1:bombmy 2:crm)
	 * @format int32
	 */
	service?: number;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

/** 刪除幫開課方案 */
export interface RamateVerifyDeleteBombcoursePlanPayload {
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

/** 根垣管理者編輯帳號資訊 */
export interface RamateVerifyRamateUpdateAccountInfoPayload {
	/** 根垣管理者暱稱 */
	ramateAccountName?: string;
	/** 根垣管理者電話 */
	ramateAccountPhone?: string;
	/** 根垣管理者信箱 */
	ramateAccountEmail?: string;
	/** 舊密碼 */
	oldPassword?: string;
	/** 新密碼 */
	newPassword?: string;
}

/** 編輯廠商權限 */
export interface RamateVerifyUpdateFirmAuthorityPayload {
	/** bombmy平台權限 */
	bombmy?: boolean;
	/** 編輯者 */
	updateUser?: string;
	/** bombmy聯盟行銷權限 */
	bombmyAssociation?: boolean;
	/** bombcourse平台 */
	bombcourse?: boolean;
}

/** 寄送簡訊 */
export interface RamateVerifySendSmsPayload {
	/** 簡訊內容 */
	smsContent?: string;
	/** 收件者ID列表 */
	receiveUserList?: string[];
	/** 編輯者 */
	updateUser?: string;
}

/** 編輯幫開課到期日期 */
export interface RamateVerifyUpdateBombcourseExpireDatePayload {
	/** 幫開課到期日期 */
	bombcourseExpireDate?: string;
}

/** 編輯廠商幫開課使用方案 */
export interface RamateVerifyUpdateFirmBombcoursePlanPayload {
	/** 幫開課方案ID */
	bombcoursePlanId?: string;
}

/** 編輯廠商幫賣直播時數 */
export interface RamateVerifyUpdateFirmLiveHoursPayload {
	/** 直播時數 */
	liveHours?: string;
	/** 異動記錄內容 */
	logText?: string;
	/** 紀錄者(ramate_account_name) */
	logUser?: string;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

/** 編輯廠商簡訊點數 */
export interface RamateVerifyUpdateFirmSmsPointPayload {
	/**
	 * 點數
	 * @format double
	 */
	smsPoint?: number;
	/** 異動記錄內容 */
	logText?: string;
	/** 紀錄者(ramate_account_name) */
	logUser?: string;
	/** 編輯者(ramate_account_id) */
	updateUser?: string;
}

/** 編輯最後儲值日期 */
export interface RamateVerifyUpdateLastLiveHoursDatePayload {
	/** 最後儲值日期 */
	lastLiveHoursDate?: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = 'application/json',
	FormData = 'multipart/form-data',
	UrlEncoded = 'application/x-www-form-urlencoded',
	Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = '';
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: 'same-origin',
		headers: {},
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
		return keys.map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key))).join('&');
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : '';
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) => (input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input),
		[ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob ? property : typeof property === 'object' && property !== null ? JSON.stringify(property) : `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) && this.securityWorker && (await this.securityWorker(this.securityData))) || {};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
			},
			signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
			body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
		}).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title crm.proto
 * @version version not set
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	coupon = {
		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductCouponList
		 * @summary 獲取優惠券列表
		 * @request GET:/coupon
		 */
		productCouponList: (
			query?: {
				/** 公司ID */
				firmId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmCouponListResponse, RpcStatus>({
				path: `/coupon`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags product
 * @name ProductCreateCoupon
 * @summary 優惠券
新建優惠券
 * @request POST:/coupon
 */
		productCreateCoupon: (body: CrmCreateCouponRequest, params: RequestParams = {}) =>
			this.request<CrmCreateCouponResponse, RpcStatus>({
				path: `/coupon`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductLoadOneCoupon
		 * @summary 載入單一優惠券內容
		 * @request GET:/coupon/{couponId}
		 */
		productLoadOneCoupon: (
			couponId: string,
			query?: {
				/** 公司ID */
				firmId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadOneCouponResponse, RpcStatus>({
				path: `/coupon/${couponId}`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductDeleteCoupon
		 * @summary 刪除優惠券
		 * @request DELETE:/coupon/{couponId}
		 */
		productDeleteCoupon: (couponId: string, body: ProductDeleteCouponPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteCouponResponse, RpcStatus>({
				path: `/coupon/${couponId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductUpdateCoupon
		 * @summary 編輯優惠券
		 * @request PUT:/coupon/{couponId}
		 */
		productUpdateCoupon: (
			couponId: string,
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 優惠券名稱 */
				couponName?: string;
				/** 優惠券代碼 */
				couponCode?: string;
				/** 起迄時間(1:有時間限制 0:無時間限制) */
				couponExpireType?: boolean;
				/** 優惠券開始時間 */
				couponExpireBegin?: string;
				/** 優惠券結束時間 */
				couponExpireEnd?: string;
				/** 狀態(1:公開 0:關閉) */
				couponStatus?: boolean;
				/** 可與其他優惠券並用(1:可 0:不可) */
				couponCombination?: boolean;
				/** 優惠類型(固定/百分比) */
				couponType?: string;
				/**
				 * 折扣(百分比0.0001~1 固定:整數)
				 * @format double
				 */
				couponDiscount?: number;
				/**
				 * 最低訂單金額
				 * @format int32
				 */
				couponLimitPrice?: number;
				/**
				 * 優惠券數量上限
				 * @format int32
				 */
				couponSaleLimit?: number;
				/** 使用範圍 */
				couponRange?: string[];
				/** 編輯者 */
				updateUser?: string;
				/**
				 * Bombcourse優惠券數量上限
				 * @format int32
				 */
				couponSaleLimitBombcourse?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmUpdateCouponResponse, RpcStatus>({
				path: `/coupon/${couponId}`,
				method: 'PUT',
				query: query,
				format: 'json',
				...params,
			}),
	};
	customer = {
		/**
 * No description
 *
 * @tags customer
 * @name CustomerCustomerList
 * @summary 顧客管理
載入顧客列表
 * @request GET:/customer
 */
		customerCustomerList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 標籤ID */
				tagId?: string;
				/** 搜尋 */
				likeSearch?: string;
				/** 性別 */
				customerGender?: string;
				/** 縣市 */
				customerCounty?: string;
				/** 平均花費(開始) */
				averageCostBegin?: string;
				/** 平均花費(結束) */
				averageCostEnd?: string;
				/** 總花費(開始) */
				totalCostBegin?: string;
				/** 總花費(結束) */
				totalCostEnd?: string;
				/** 總訂單數(開始) */
				orderCountBegin?: string;
				/** 總訂單數(結束) */
				orderCountEnd?: string;
				/** 平均購物天數(開始) */
				averageOrderDayBegin?: string;
				/** 平均購物天數(結束) */
				averageOrderDayEnd?: string;
				/** 距離上次購買天數(開始) */
				sinceLastOrderDayBegin?: string;
				/** 距離上次購買天數(結束) */
				sinceLastOrderDayEnd?: string;
				/** 排序 (0:建立(新->舊) 1: 建立(舊->新) 2:最後更新(新->舊) 3:最後更新(舊->新) 4:總花費金額(多->少) 5:總花費金額(少->多) 6:總訂單數(多->少) 7:總訂單數(少->多)) */
				sort?: string;
				/**
				 * 每頁筆數(25、50、100)
				 * @format int32
				 */
				countPerPage?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmCustomerListResponse, RpcStatus>({
				path: `/customer`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerBatchCustomerSendSms
		 * @summary 批次顧客寄發簡訊
		 * @request POST:/customer/batch_customer_send_sms
		 */
		customerBatchCustomerSendSms: (body: CrmBatchCustomerSendSMSRequest, params: RequestParams = {}) =>
			this.request<CrmBatchCustomerSendSMSResponse, RpcStatus>({
				path: `/customer/batch_customer_send_sms`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerCreateCustomerCustomFields
		 * @summary 新增顧客自訂欄位
		 * @request POST:/customer/custom_fields
		 */
		customerCreateCustomerCustomFields: (body: CrmCreateCustomerCustomFieldsRequest, params: RequestParams = {}) =>
			this.request<CrmCreateCustomerCustomFieldsResponse, RpcStatus>({
				path: `/customer/custom_fields`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerDeleteCustomerCustomFields
		 * @summary 刪除顧客自訂欄位
		 * @request DELETE:/customer/custom_fields/{customFieldsId}
		 */
		customerDeleteCustomerCustomFields: (customFieldsId: string, body: CustomerDeleteCustomerCustomFieldsPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteCustomerCustomFieldsResponse, RpcStatus>({
				path: `/customer/custom_fields/${customFieldsId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerUpdateCustomerCustomFields
		 * @summary 編輯顧客自訂欄位
		 * @request PUT:/customer/custom_fields/{customFieldsId}
		 */
		customerUpdateCustomerCustomFields: (customFieldsId: string, body: CustomerUpdateCustomerCustomFieldsPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateCustomerCustomFieldsResponse, RpcStatus>({
				path: `/customer/custom_fields/${customFieldsId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerCustomerDetailList
		 * @summary 載入顧客詳細資料列表
		 * @request GET:/customer/customer_detail_list
		 */
		customerCustomerDetailList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmCustomerDetailListResponse, RpcStatus>({
				path: `/customer/customer_detail_list`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerExportCustomer
		 * @summary 匯出顧客
		 * @request GET:/customer/export_customer
		 */
		customerExportCustomer: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 標籤ID */
				tagId?: string;
				/** 搜尋 */
				likeSearch?: string;
				/** 性別 */
				customerGender?: string;
				/** 縣市 */
				customerCounty?: string;
				/** 平均花費(開始) */
				averageCostBegin?: string;
				/** 平均花費(結束) */
				averageCostEnd?: string;
				/** 總花費(開始) */
				totalCostBegin?: string;
				/** 總花費(結束) */
				totalCostEnd?: string;
				/** 總訂單數(開始) */
				orderCountBegin?: string;
				/** 總訂單數(結束) */
				orderCountEnd?: string;
				/** 平均購物天數(開始) */
				averageOrderDayBegin?: string;
				/** 平均購物天數(結束) */
				averageOrderDayEnd?: string;
				/** 距離上次購買天數(開始) */
				sinceLastOrderDayBegin?: string;
				/** 距離上次購買天數(結束) */
				sinceLastOrderDayEnd?: string;
				/** 排序 (0:建立(新->舊) 1: 建立(舊->新) 2:最後更新(新->舊) 3:最後更新(舊->新) 4:總花費金額(多->少) 5:總花費金額(少->多) 6:總訂單數(多->少) 7:總訂單數(少->多)) */
				sort?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmExportCustomerResponse, RpcStatus>({
				path: `/customer/export_customer`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerTagList
		 * @summary 標籤列表
		 * @request GET:/customer/tag
		 */
		customerTagList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmTagListResponse, RpcStatus>({
				path: `/customer/tag`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerCreateTag
		 * @summary 建立標籤
		 * @request POST:/customer/tag
		 */
		customerCreateTag: (body: CrmCreateTagRequest, params: RequestParams = {}) =>
			this.request<CrmCreateTagResponse, RpcStatus>({
				path: `/customer/tag`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerDeleteCustomerTag
		 * @summary 刪除顧客標籤
		 * @request DELETE:/customer/tag/{orderUserId}/{tagId}
		 */
		customerDeleteCustomerTag: (orderUserId: string, tagId: string, body: CustomerDeleteCustomerTagPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteCustomerTagResponse, RpcStatus>({
				path: `/customer/tag/${orderUserId}/${tagId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerPasteCustomerTag
		 * @summary 貼上顧客標籤
		 * @request PUT:/customer/tag/{orderUserId}/{tagId}
		 */
		customerPasteCustomerTag: (
			orderUserId: string,
			tagId: string,
			body: {
				/** 公司ID */
				firmId?: string;
				/** 編輯者 */
				updateUser?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmPasteCustomerTagResponse, RpcStatus>({
				path: `/customer/tag/${orderUserId}/${tagId}`,
				method: 'PUT',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerDeleteTag
		 * @summary 刪除標籤
		 * @request DELETE:/customer/tag/{tagId}
		 */
		customerDeleteTag: (
			tagId: string,
			body: {
				/** 公司ID */
				firmId?: string;
				/** 編輯者 */
				updateUser?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmDeleteTagResponse, RpcStatus>({
				path: `/customer/tag/${tagId}`,
				method: 'DELETE',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerUpdateTag
		 * @summary 編輯標籤
		 * @request PUT:/customer/tag/{tagId}
		 */
		customerUpdateTag: (tagId: string, body: CustomerUpdateTagPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateTagResponse, RpcStatus>({
				path: `/customer/tag/${tagId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerLoadOneCustomer
		 * @summary 載入單一顧客內容
		 * @request GET:/customer/{orderUserId}
		 */
		customerLoadOneCustomer: (
			orderUserId: string,
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadOneCustomerResponse, RpcStatus>({
				path: `/customer/${orderUserId}`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerDeleteCustomerData
		 * @summary 刪除顧客資訊
		 * @request DELETE:/customer/{orderUserId}
		 */
		customerDeleteCustomerData: (
			orderUserId: string,
			body: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 編輯者 */
				updateUser?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmDeleteCustomerDataResponse, RpcStatus>({
				path: `/customer/${orderUserId}`,
				method: 'DELETE',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerUpdateCustomerData
		 * @summary 編輯顧客資訊
		 * @request PUT:/customer/{orderUserId}
		 */
		customerUpdateCustomerData: (
			orderUserId: string,
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 顧客名稱 */
				customerName?: string;
				/** 顧客性別 */
				customerGender?: string;
				/** 顧客電話 */
				customerPhone?: string;
				/** 顧客地址 */
				customerAddress?: string;
				/** 顧客信箱 */
				customerEmail?: string;
				/** 公司名稱 */
				companyName?: string;
				/** 公司統一編號 */
				companyIdentifier?: string;
				/** 負責人 */
				companyChargePerson?: string;
				/** 備註 */
				memo?: string;
				/** 顧客縣市 */
				customerCounty?: string;
				/** 顧客行政區 */
				customerDistrict?: string;
				/** 編輯者 */
				updateUser?: string;
				/** 國碼 */
				countryCode?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmUpdateCustomerDataResponse, RpcStatus>({
				path: `/customer/${orderUserId}`,
				method: 'PUT',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerBatchPasteCustomerTag
		 * @summary 批次貼上顧客標籤
		 * @request PUT:/customer/{orderUserId}/batch_paste_customer_tag
		 */
		customerBatchPasteCustomerTag: (orderUserId: string[], body: CustomerBatchPasteCustomerTagPayload, params: RequestParams = {}) =>
			this.request<CrmBatchPasteCustomerTagResponse, RpcStatus>({
				path: `/customer/${orderUserId}/batch_paste_customer_tag`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerCreateContactLog
		 * @summary 新增聯絡紀錄
		 * @request POST:/customer/{orderUserId}/contact_log
		 */
		customerCreateContactLog: (orderUserId: string, body: CustomerCreateContactLogPayload, params: RequestParams = {}) =>
			this.request<CrmCreateContactLogResponse, RpcStatus>({
				path: `/customer/${orderUserId}/contact_log`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerDeleteContactLog
		 * @summary 刪除聯絡紀錄
		 * @request DELETE:/customer/{orderUserId}/contact_log/{contactLogId}
		 */
		customerDeleteContactLog: (orderUserId: string, contactLogId: string, body: CustomerDeleteContactLogPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteContactLogResponse, RpcStatus>({
				path: `/customer/${orderUserId}/contact_log/${contactLogId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags customer
		 * @name CustomerUpdateContactLog
		 * @summary 編輯聯絡紀錄
		 * @request PUT:/customer/{orderUserId}/contact_log/{contactLogId}
		 */
		customerUpdateContactLog: (orderUserId: string, contactLogId: string, body: CustomerUpdateContactLogPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateContactLogResponse, RpcStatus>({
				path: `/customer/${orderUserId}/contact_log/${contactLogId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	firmAutoLogin = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmFirmAutoLogin
		 * @summary 自動登入
		 * @request POST:/firm_auto_login
		 */
		crmFirmAutoLogin: (body: CrmCRMFirmAutoLoginRequest, params: RequestParams = {}) =>
			this.request<CrmCRMFirmAutoLoginResponse, RpcStatus>({
				path: `/firm_auto_login`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	firmInfo = {
		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoAffiliateAccountList
		 * @summary 獲取附屬帳號列表
		 * @request GET:/firm_info/affiliate_account/{firmId}
		 */
		firmInfoAffiliateAccountList: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmAffiliateAccountListResponse, RpcStatus>({
				path: `/firm_info/affiliate_account/${firmId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoCreateAffiliateAccount
		 * @summary 新增附屬帳號
		 * @request POST:/firm_info/affiliate_account/{firmId}
		 */
		firmInfoCreateAffiliateAccount: (firmId: string, body: FirmInfoCreateAffiliateAccountPayload, params: RequestParams = {}) =>
			this.request<CrmCreateAffiliateAccountResponse, RpcStatus>({
				path: `/firm_info/affiliate_account/${firmId}`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadOneAffiliateAccount
		 * @summary 載入單一附屬帳號內容
		 * @request GET:/firm_info/affiliate_account/{firmId}/{affiliateAccountId}
		 */
		firmInfoLoadOneAffiliateAccount: (firmId: string, affiliateAccountId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneAffiliateAccountResponse, RpcStatus>({
				path: `/firm_info/affiliate_account/${firmId}/${affiliateAccountId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoDeleteAffiliateAccount
		 * @summary 刪除附屬帳號
		 * @request DELETE:/firm_info/affiliate_account/{firmId}/{affiliateAccountId}
		 */
		firmInfoDeleteAffiliateAccount: (firmId: string, affiliateAccountId: string, body: FirmInfoDeleteAffiliateAccountPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteAffiliateAccountResponse, RpcStatus>({
				path: `/firm_info/affiliate_account/${firmId}/${affiliateAccountId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoUpdateAffiliateAccount
		 * @summary 編輯附屬帳號
		 * @request PUT:/firm_info/affiliate_account/{firmId}/{affiliateAccountId}
		 */
		firmInfoUpdateAffiliateAccount: (firmId: string, affiliateAccountId: string, body: FirmInfoUpdateAffiliateAccountPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateAffiliateAccountResponse, RpcStatus>({
				path: `/firm_info/affiliate_account/${firmId}/${affiliateAccountId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadOneFirmInfo
		 * @summary 載入單一廠商資訊
		 * @request GET:/firm_info/{firmId}
		 */
		firmInfoLoadOneFirmInfo: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneFirmInfoResponse, RpcStatus>({
				path: `/firm_info/${firmId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmBasicInfoSetting
		 * @summary 廠商基本資料設定
		 * @request PUT:/firm_info/{firmId}
		 */
		firmInfoFirmBasicInfoSetting: (firmId: string, body: FirmInfoFirmBasicInfoSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmBasicInfoSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmEcPaySetting
		 * @summary 廠商載入綠界第三方服務
		 * @request GET:/firm_info/{firmId}/firm_ecpay_setting
		 */
		firmInfoLoadFirmEcPaySetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmECPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_ecpay_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmEcPaySetting
		 * @summary 廠商設定綠界第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_ecpay_setting
		 */
		firmInfoFirmEcPaySetting: (firmId: string, body: FirmInfoFirmEcPaySettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmECPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_ecpay_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmEzPaySetting
		 * @summary 廠商載入EZPay發票第三方服務
		 * @request GET:/firm_info/{firmId}/firm_ezpay_setting
		 */
		firmInfoLoadFirmEzPaySetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmEZPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_ezpay_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmEzPaySetting
		 * @summary 廠商設定EZPay發票第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_ezpay_setting
		 */
		firmInfoFirmEzPaySetting: (firmId: string, body: FirmInfoFirmEzPaySettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmEZPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_ezpay_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmFunPointSetting
		 * @summary 廠商載入FunPoint第三方服務
		 * @request GET:/firm_info/{firmId}/firm_funpoint_setting
		 */
		firmInfoLoadFirmFunPointSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmFunPointSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_funpoint_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmFunPointSetting
		 * @summary 廠商設定FunPoint第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_funpoint_setting
		 */
		firmInfoFirmFunPointSetting: (firmId: string, body: FirmInfoFirmFunPointSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmFunPointSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_funpoint_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags firm_info
 * @name FirmInfoFirmInvoiceSetting
 * @summary 廠商資訊
廠商發票設定
 * @request PUT:/firm_info/{firmId}/firm_invoice_setting
 */
		firmInfoFirmInvoiceSetting: (firmId: string, body: FirmInfoFirmInvoiceSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmInvoiceSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_invoice_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmLinePaySetting
		 * @summary 廠商載入LinePay第三方服務
		 * @request GET:/firm_info/{firmId}/firm_line_pay_setting
		 */
		firmInfoLoadFirmLinePaySetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmLinePaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_line_pay_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmLinePaySetting
		 * @summary 廠商設定LinePay第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_line_pay_setting
		 */
		firmInfoFirmLinePaySetting: (firmId: string, body: FirmInfoFirmLinePaySettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmLinePaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_line_pay_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmNewebSetting
		 * @summary 廠商載入藍新第三方服務
		 * @request GET:/firm_info/{firmId}/firm_neweb_setting
		 */
		firmInfoLoadFirmNewebSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmNewebSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_neweb_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmNewebSetting
		 * @summary 廠商設定藍新第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_neweb_setting
		 */
		firmInfoFirmNewebSetting: (firmId: string, body: FirmInfoFirmNewebSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmNewebSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_neweb_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmOpaySetting
		 * @summary 廠商載入歐付寶第三方服務
		 * @request GET:/firm_info/{firmId}/firm_opay_setting
		 */
		firmInfoLoadFirmOpaySetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmOpaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_opay_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmOpaySetting
		 * @summary 廠商設定歐付寶第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_opay_setting
		 */
		firmInfoFirmOpaySetting: (firmId: string, body: FirmInfoFirmOpaySettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmOpaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_opay_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmPaynowSetting
		 * @summary 廠商載入Paynow第三方服務
		 * @request GET:/firm_info/{firmId}/firm_paynow_setting
		 */
		firmInfoLoadFirmPaynowSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmPaynowSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_paynow_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmPaynowSetting
		 * @summary 廠商設定Paynow第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_paynow_setting
		 */
		firmInfoFirmPaynowSetting: (firmId: string, body: FirmInfoFirmPaynowSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmPaynowSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_paynow_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmPaypalSetting
		 * @summary 廠商載入Paypal第三方服務
		 * @request GET:/firm_info/{firmId}/firm_paypal_setting
		 */
		firmInfoLoadFirmPaypalSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmPaypalSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_paypal_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmPaypalSetting
		 * @summary 廠商設定Paypal第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_paypal_setting
		 */
		firmInfoFirmPaypalSetting: (firmId: string, body: FirmInfoFirmPaypalSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmPaypalSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_paypal_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmPayUniSetting
		 * @summary 廠商載入統一第三方服務
		 * @request GET:/firm_info/{firmId}/firm_payuni_setting
		 */
		firmInfoLoadFirmPayUniSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmPayUniSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_payuni_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmPayUniSetting
		 * @summary 廠商設定統一第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_payuni_setting
		 */
		firmInfoFirmPayUniSetting: (firmId: string, body: FirmInfoFirmPayUniSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmPayUniSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_payuni_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmPcHomeSetting
		 * @summary 廠商載入支付連第三方服務
		 * @request GET:/firm_info/{firmId}/firm_pchome_setting
		 */
		firmInfoLoadFirmPcHomeSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmPCHomeSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_pchome_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmPcHomeSetting
		 * @summary 廠商設定支付連第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_pchome_setting
		 */
		firmInfoFirmPcHomeSetting: (firmId: string, body: FirmInfoFirmPcHomeSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmPCHomeSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_pchome_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmPifcSetting
		 * @summary 廠商載入第一融資第三方服務
		 * @request GET:/firm_info/{firmId}/firm_pifc_setting
		 */
		firmInfoLoadFirmPifcSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmPIFCSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_pifc_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmPifcSetting
		 * @summary 廠商設定第一融資第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_pifc_setting
		 */
		firmInfoFirmPifcSetting: (firmId: string, body: FirmInfoFirmPifcSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmPIFCSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_pifc_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoanOneFirmSmsPointLog
		 * @summary 載入單一廠商簡訊點數紀錄
		 * @request GET:/firm_info/{firmId}/firm_sms_point_log
		 */
		firmInfoLoanOneFirmSmsPointLog: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoanOneFirmSMSPointLogResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_sms_point_log`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmStripeSetting
		 * @summary 廠商載入Stripe第三方服務
		 * @request GET:/firm_info/{firmId}/firm_stripe_setting
		 */
		firmInfoLoadFirmStripeSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmStripeSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_stripe_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmStripeSetting
		 * @summary 廠商設定Stripe第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_stripe_setting
		 */
		firmInfoFirmStripeSetting: (firmId: string, body: FirmInfoFirmStripeSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmStripeSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_stripe_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmSunPaySetting
		 * @summary 廠商載入紅陽第三方服務
		 * @request GET:/firm_info/{firmId}/firm_sunpay_setting
		 */
		firmInfoLoadFirmSunPaySetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmSunPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_sunpay_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmSunPaySetting
		 * @summary 廠商設定紅陽第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_sunpay_setting
		 */
		firmInfoFirmSunPaySetting: (firmId: string, body: FirmInfoFirmSunPaySettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmSunPaySettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_sunpay_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadFirmZingalaSetting
		 * @summary 廠商載入銀角零卡第三方服務
		 * @request GET:/firm_info/{firmId}/firm_zingala_setting
		 */
		firmInfoLoadFirmZingalaSetting: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadFirmZingalaSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_zingala_setting`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoFirmZingalaSetting
		 * @summary 廠商設定銀角零卡第三方服務
		 * @request PUT:/firm_info/{firmId}/firm_zingala_setting
		 */
		firmInfoFirmZingalaSetting: (firmId: string, body: FirmInfoFirmZingalaSettingPayload, params: RequestParams = {}) =>
			this.request<CrmFirmZingalaSettingResponse, RpcStatus>({
				path: `/firm_info/${firmId}/firm_zingala_setting`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoLoadOneFirmPointSafetyStock
		 * @summary 載入單一廠商點數安全庫存量
		 * @request GET:/firm_info/{firmId}/point_safety_stock
		 */
		firmInfoLoadOneFirmPointSafetyStock: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneFirmPointSafetyStockResponse, RpcStatus>({
				path: `/firm_info/${firmId}/point_safety_stock`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags firm_info
		 * @name FirmInfoUpdatePointSafetyStock
		 * @summary 編輯廠商點數安全庫存量
		 * @request PUT:/firm_info/{firmId}/point_safety_stock
		 */
		firmInfoUpdatePointSafetyStock: (firmId: string, body: FirmInfoUpdatePointSafetyStockPayload, params: RequestParams = {}) =>
			this.request<CrmUpdatePointSafetyStockResponse, RpcStatus>({
				path: `/firm_info/${firmId}/point_safety_stock`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	firmLogin = {
		/**
 * No description
 *
 * @tags CRM
 * @name CrmFirmLogin
 * @summary 登入相關
登入
 * @request POST:/firm_login
 */
		crmFirmLogin: (body: CrmCRMFirmLoginRequest, params: RequestParams = {}) =>
			this.request<CrmCRMFirmLoginResponse, RpcStatus>({
				path: `/firm_login`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	firmRegister = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmFirmRegister
		 * @summary 註冊
		 * @request POST:/firm_register
		 */
		crmFirmRegister: (body: CrmCRMFirmRegisterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMFirmRegisterResponse, RpcStatus>({
				path: `/firm_register`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	loadCouponUseRange = {
		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductLoadCouponUseRange
		 * @summary 獲取優惠券使用範圍
		 * @request GET:/load_coupon_use_range
		 */
		productLoadCouponUseRange: (
			query?: {
				/** 公司ID */
				firmId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadCouponUseRangeResponse, RpcStatus>({
				path: `/load_coupon_use_range`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),
	};
	logisticsProduct = {
		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductLogisticsProductList
		 * @summary 獲取物流商品列表
		 * @request GET:/logistics_product
		 */
		productLogisticsProductList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLogisticsProductListResponse, RpcStatus>({
				path: `/logistics_product`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags product
 * @name ProductCreateLogisticsProduct
 * @summary 物流商品管理
新增物流商品
 * @request POST:/logistics_product
 */
		productCreateLogisticsProduct: (body: CrmCreateLogisticsProductRequest, params: RequestParams = {}) =>
			this.request<CrmCreateLogisticsProductResponse, RpcStatus>({
				path: `/logistics_product`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductDeleteLogisticsProduct
		 * @summary 刪除物流商品
		 * @request DELETE:/logistics_product/{logisticsProductId}
		 */
		productDeleteLogisticsProduct: (logisticsProductId: string, body: ProductDeleteLogisticsProductPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteLogisticsProductResponse, RpcStatus>({
				path: `/logistics_product/${logisticsProductId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductUpdateLogisticsProduct
		 * @summary 編輯物流商品
		 * @request PUT:/logistics_product/{logisticsProductId}
		 */
		productUpdateLogisticsProduct: (logisticsProductId: string, body: ProductUpdateLogisticsProductPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateLogisticsProductResponse, RpcStatus>({
				path: `/logistics_product/${logisticsProductId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	order = {
		/**
 * No description
 *
 * @tags order
 * @name OrderOrderList
 * @summary 訂單管理
載入訂單列表
 * @request GET:/order
 */
		orderOrderList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 金流狀態 */
				paymentStatus?: string;
				/** 物流狀態 */
				deliveryStatus?: string;
				/** 訂購日期(開始) */
				orderDateBegin?: string;
				/** 訂購日期(結束) */
				orderDateEnd?: string;
				/** 出貨日期(開始) */
				shipDateBegin?: string;
				/** 出貨日期(結束) */
				shipDateEnd?: string;
				/** 發票日期(開始) */
				invoiceDateBegin?: string;
				/** 發票日期(結束) */
				invoiceDateEnd?: string;
				/** 物流追蹤(1:有 0:無 空:不篩選) */
				deliveryLogisticsStatus?: string;
				/** 搜尋 */
				likeSearch?: string;
				/** 直播搜尋 */
				liveSearch?: string;
				/** 電子發票狀態(0:未開立1:已開立2:註銷) */
				invoiceStatus?: string;
				/** 產品搜尋 */
				productSearch?: string;
				/** 訂單號碼 */
				orderNo?: string;
				/** 發票號碼 */
				invoiceNo?: string;
				/** 顧客備註或留言(1:有 0:無 空:不篩選) */
				customerMemoMessageStatus?: string;
				/** 託運單號 */
				trackNo?: string;
				/** 訂單狀態 (0:等待確認 1:處理中 2:已完成 3:已取消) */
				orderStatus?: string;
				/**
				 * 每頁筆數(25、50、100)
				 * @format int32
				 */
				countPerPage?: number;
				/** 提供時間(開始) */
				provideTimeBegin?: string;
				/** 提供時間(結束) */
				provideTimeEnd?: string;
				/** 付款日期(開始) */
				paymentDateBegin?: string;
				/** 付款日期(結束) */
				paymentDateEnd?: string;
				/** 優惠券代碼 */
				couponCode?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmOrderListResponse, RpcStatus>({
				path: `/order`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCreateOrder
		 * @summary 新增訂單
		 * @request POST:/order
		 */
		orderCreateOrder: (body: CrmCreateOrderRequest, params: RequestParams = {}) =>
			this.request<CrmCreateOrderResponse, RpcStatus>({
				path: `/order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderBatchOrderSendEmail
		 * @summary 批次訂單寄發郵件
		 * @request POST:/order/batch_order_send_email
		 */
		orderBatchOrderSendEmail: (body: CrmBatchOrderSendEmailRequest, params: RequestParams = {}) =>
			this.request<CrmBatchOrderSendEmailResponse, RpcStatus>({
				path: `/order/batch_order_send_email`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderBatchOrderSendSms
		 * @summary 批次訂單寄發簡訊
		 * @request POST:/order/batch_order_send_sms
		 */
		orderBatchOrderSendSms: (body: CrmBatchOrderSendSMSRequest, params: RequestParams = {}) =>
			this.request<CrmBatchOrderSendSMSResponse, RpcStatus>({
				path: `/order/batch_order_send_sms`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCrmPermissionBombcourse
		 * @summary CRM開通幫開課權限
		 * @request POST:/order/crm_permission_bombcourse
		 */
		orderCrmPermissionBombcourse: (body: CrmCRMPermissionBombcourseRequest, params: RequestParams = {}) =>
			this.request<CrmCRMPermissionBombcourseResponse, RpcStatus>({
				path: `/order/crm_permission_bombcourse`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderExportOrder
		 * @summary 匯出訂單
		 * @request GET:/order/export_order
		 */
		orderExportOrder: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 金流狀態 */
				paymentStatus?: string;
				/** 物流狀態 */
				deliveryStatus?: string;
				/** 訂購日期(開始) */
				orderDateBegin?: string;
				/** 訂購日期(結束) */
				orderDateEnd?: string;
				/** 出貨日期(開始) */
				shipDateBegin?: string;
				/** 出貨日期(結束) */
				shipDateEnd?: string;
				/** 發票日期(開始) */
				invoiceDateBegin?: string;
				/** 發票日期(結束) */
				invoiceDateEnd?: string;
				/** 物流追蹤(1:有 0:無 空:不篩選) */
				deliveryLogisticsStatus?: string;
				/** 搜尋 */
				likeSearch?: string;
				/** 直播搜尋 */
				liveSearch?: string;
				/** 電子發票狀態(0:未開立1:已開立2:註銷) */
				invoiceStatus?: string;
				/** 產品搜尋 */
				productSearch?: string;
				/** 訂單號碼 */
				orderNo?: string;
				/** 發票號碼 */
				invoiceNo?: string;
				/** 顧客備註或留言(1:有 0:無 空:不篩選) */
				customerMemoMessageStatus?: string;
				/** 託運單號 */
				trackNo?: string;
				/** 訂單狀態 (0:等待確認 1:處理中 2:已完成 3:已取消) */
				orderStatus?: string;
				/** 提供時間(開始) */
				provideTimeBegin?: string;
				/** 提供時間(結束) */
				provideTimeEnd?: string;
				/** 付款日期(開始) */
				paymentDateBegin?: string;
				/** 付款日期(結束) */
				paymentDateEnd?: string;
				/** 優惠券代碼 */
				couponCode?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmExportOrderResponse, RpcStatus>({
				path: `/order/export_order`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderEzPayResendInvoice
		 * @summary EZPay補寄發票
		 * @request POST:/order/ezpay_resend_invoice
		 */
		orderEzPayResendInvoice: (body: CrmEZPayResendInvoiceRequest, params: RequestParams = {}) =>
			this.request<CrmEZPayResendInvoiceResponse, RpcStatus>({
				path: `/order/ezpay_resend_invoice`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderImportOrder
		 * @summary 匯入訂單
		 * @request POST:/order/import_order
		 */
		orderImportOrder: (body: CrmImportOrderRequest, params: RequestParams = {}) =>
			this.request<CrmImportOrderResponse, RpcStatus>({
				path: `/order/import_order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadLiveNameList
		 * @summary 直播名稱列表
		 * @request GET:/order/load_live_name_list
		 */
		orderLoadLiveNameList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadLiveNameListResponse, RpcStatus>({
				path: `/order/load_live_name_list`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadLivePaymentDeliveryMethod
		 * @summary 獲取直播付款、物流方式
		 * @request GET:/order/load_live_payment_delivery_method
		 */
		orderLoadLivePaymentDeliveryMethod: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 直播ID */
				liveId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadLivePaymentDeliveryMethodResponse, RpcStatus>({
				path: `/order/load_live_payment_delivery_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadLivePaymentMethod
		 * @summary 獲取直播付款方式
		 * @request GET:/order/load_live_payment_method
		 */
		orderLoadLivePaymentMethod: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 直播ID */
				liveId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadLivePaymentMethodResponse, RpcStatus>({
				path: `/order/load_live_payment_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadSignupNameList
		 * @summary 報名名稱列表
		 * @request GET:/order/load_signup_name_list
		 */
		orderLoadSignupNameList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadSignupNameListResponse, RpcStatus>({
				path: `/order/load_signup_name_list`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadSignupPaymentDeliveryMethod
		 * @summary 獲取報名付款、物流方式
		 * @request GET:/order/load_signup_payment_delivery_method
		 */
		orderLoadSignupPaymentDeliveryMethod: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 報名ID(一頁購物ID) */
				signupId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadSignupPaymentDeliveryMethodResponse, RpcStatus>({
				path: `/order/load_signup_payment_delivery_method`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderPartnerList
		 * @summary 聯盟夥伴名稱列表
		 * @request GET:/order/partner_list
		 */
		orderPartnerList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 訂單編號 */
				orderId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmPartnerListResponse, RpcStatus>({
				path: `/order/partner_list`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderRamatePermissionBombmy
		 * @summary 根垣開通幫賣帳號
		 * @request POST:/order/ramate_permission_bombmy
		 */
		orderRamatePermissionBombmy: (body: CrmRamatePermissionBombmyRequest, params: RequestParams = {}) =>
			this.request<CrmRamatePermissionBombmyResponse, RpcStatus>({
				path: `/order/ramate_permission_bombmy`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadOneOrder
		 * @summary 載入單一訂單內容
		 * @request GET:/order/{orderId}
		 */
		orderLoadOneOrder: (
			orderId: string,
			query?: {
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadOneOrderResponse, RpcStatus>({
				path: `/order/${orderId}`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderDeleteOrder
		 * @summary 刪除訂單
		 * @request DELETE:/order/{orderId}
		 */
		orderDeleteOrder: (orderId: string, body: OrderDeleteOrderPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteOrderResponse, RpcStatus>({
				path: `/order/${orderId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderBatchDeleteOrder
		 * @summary 批次刪除訂單
		 * @request DELETE:/order/{orderId}/batch_delete_order
		 */
		orderBatchDeleteOrder: (orderId: string[], body: OrderBatchDeleteOrderPayload, params: RequestParams = {}) =>
			this.request<CrmBatchDeleteOrderResponse, RpcStatus>({
				path: `/order/${orderId}/batch_delete_order`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderBatchUpdateOrder
		 * @summary 批次編輯訂單
		 * @request PUT:/order/{orderId}/batch_update_order
		 */
		orderBatchUpdateOrder: (orderId: string[], body: OrderBatchUpdateOrderPayload, params: RequestParams = {}) =>
			this.request<CrmBatchUpdateOrderResponse, RpcStatus>({
				path: `/order/${orderId}/batch_update_order`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCreateOrderLog
		 * @summary 新增訂單紀錄
		 * @request POST:/order/{orderId}/create_order_log
		 */
		orderCreateOrderLog: (orderId: string, body: OrderCreateOrderLogPayload, params: RequestParams = {}) =>
			this.request<CrmCreateOrderLogResponse, RpcStatus>({
				path: `/order/${orderId}/create_order_log`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCreateDeliveryLogistics
		 * @summary 新增物流追蹤
		 * @request POST:/order/{orderId}/delivery_logistics
		 */
		orderCreateDeliveryLogistics: (orderId: string, body: OrderCreateDeliveryLogisticsPayload, params: RequestParams = {}) =>
			this.request<CrmCreateDeliveryLogisticsResponse, RpcStatus>({
				path: `/order/${orderId}/delivery_logistics`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderDeleteDeliveryLogistics
		 * @summary 刪除物流追蹤
		 * @request DELETE:/order/{orderId}/delivery_logistics/{logisticsId}
		 */
		orderDeleteDeliveryLogistics: (orderId: string, logisticsId: string, body: OrderDeleteDeliveryLogisticsPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteDeliveryLogisticsResponse, RpcStatus>({
				path: `/order/${orderId}/delivery_logistics/${logisticsId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateDeliveryLogistics
		 * @summary 編輯物流追蹤
		 * @request PUT:/order/{orderId}/delivery_logistics/{logisticsId}
		 */
		orderUpdateDeliveryLogistics: (orderId: string, logisticsId: string, body: OrderUpdateDeliveryLogisticsPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateDeliveryLogisticsResponse, RpcStatus>({
				path: `/order/${orderId}/delivery_logistics/${logisticsId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadOrderCustomerComment
		 * @summary 載入訂單顧客留言
		 * @request GET:/order/{orderId}/load_order_customer_comment
		 */
		orderLoadOrderCustomerComment: (orderId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOrderCustomerCommentResponse, RpcStatus>({
				path: `/order/${orderId}/load_order_customer_comment`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderOrderBindAssociation
		 * @summary 訂單綁定聯盟行銷
		 * @request PUT:/order/{orderId}/order_bind_association
		 */
		orderOrderBindAssociation: (orderId: string, body: OrderOrderBindAssociationPayload, params: RequestParams = {}) =>
			this.request<CrmOrderBindAssociationResponse, RpcStatus>({
				path: `/order/${orderId}/order_bind_association`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderOrderCancelAssociation
		 * @summary 訂單解除聯盟行銷
		 * @request PUT:/order/{orderId}/order_cancel_association
		 */
		orderOrderCancelAssociation: (orderId: string, body: OrderOrderCancelAssociationPayload, params: RequestParams = {}) =>
			this.request<CrmOrderCancelAssociationResponse, RpcStatus>({
				path: `/order/${orderId}/order_cancel_association`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCreateOrderCost
		 * @summary 新增訂單費用
		 * @request POST:/order/{orderId}/order_cost
		 */
		orderCreateOrderCost: (orderId: string, body: OrderCreateOrderCostPayload, params: RequestParams = {}) =>
			this.request<CrmCreateOrderCostResponse, RpcStatus>({
				path: `/order/${orderId}/order_cost`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderDeleteOrderCost
		 * @summary 刪除訂單費用
		 * @request DELETE:/order/{orderId}/order_cost/{costId}
		 */
		orderDeleteOrderCost: (orderId: string, costId: string, body: OrderDeleteOrderCostPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteOrderCostResponse, RpcStatus>({
				path: `/order/${orderId}/order_cost/${costId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderCost
		 * @summary 編輯訂單費用
		 * @request PUT:/order/{orderId}/order_cost/{costId}
		 */
		orderUpdateOrderCost: (orderId: string, costId: string, body: OrderUpdateOrderCostPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderCostResponse, RpcStatus>({
				path: `/order/${orderId}/order_cost/${costId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderCreateOrderProduct
		 * @summary 新增訂單產品內容
		 * @request POST:/order/{orderId}/order_product
		 */
		orderCreateOrderProduct: (orderId: string, body: OrderCreateOrderProductPayload, params: RequestParams = {}) =>
			this.request<CrmCreateOrderProductResponse, RpcStatus>({
				path: `/order/${orderId}/order_product`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderDeleteOrderProduct
		 * @summary 刪除訂單產品內容
		 * @request DELETE:/order/{orderId}/order_product/{productId}
		 */
		orderDeleteOrderProduct: (
			orderId: string,
			productId: string,
			body: {
				/** 公司ID */
				firmId?: string;
				/** 編輯者 */
				updateUser?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmDeleteOrderProductResponse, RpcStatus>({
				path: `/order/${orderId}/order_product/${productId}`,
				method: 'DELETE',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderProduct
		 * @summary 編輯訂單產品內容
		 * @request PUT:/order/{orderId}/order_product/{productId}
		 */
		orderUpdateOrderProduct: (orderId: string, productId: string, body: OrderUpdateOrderProductPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderProductResponse, RpcStatus>({
				path: `/order/${orderId}/order_product/${productId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateDeliveryType
		 * @summary 更改物流方式
		 * @request PUT:/order/{orderId}/update_delivery_type
		 */
		orderUpdateDeliveryType: (orderId: string, body: OrderUpdateDeliveryTypePayload, params: RequestParams = {}) =>
			this.request<CrmUpdateDeliveryTypeResponse, RpcStatus>({
				path: `/order/${orderId}/update_delivery_type`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderDeliveryData
		 * @summary 編輯訂單運送資料
		 * @request PUT:/order/{orderId}/update_order_delivery_data
		 */
		orderUpdateOrderDeliveryData: (orderId: string, body: OrderUpdateOrderDeliveryDataPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderDeliveryDataResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_delivery_data`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderLockInvoiceStatus
		 * @summary 更改訂單發票鎖定狀態
		 * @request PUT:/order/{orderId}/update_order_lock_invoice_status
		 */
		orderUpdateOrderLockInvoiceStatus: (orderId: string, body: OrderUpdateOrderLockInvoiceStatusPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderLockInvoiceStatusResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_lock_invoice_status`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderMemo
		 * @summary 更改訂單備註
		 * @request PUT:/order/{orderId}/update_order_memo
		 */
		orderUpdateOrderMemo: (orderId: string, body: OrderUpdateOrderMemoPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderMemoResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_memo`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderMemoCustomer
		 * @summary 更改訂單顧客備註
		 * @request PUT:/order/{orderId}/update_order_memo_customer
		 */
		orderUpdateOrderMemoCustomer: (orderId: string, body: OrderUpdateOrderMemoCustomerPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderMemoCustomerResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_memo_customer`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderMemoFirm
		 * @summary 更改訂單廠商備註
		 * @request PUT:/order/{orderId}/update_order_memo_firm
		 */
		orderUpdateOrderMemoFirm: (orderId: string, body: OrderUpdateOrderMemoFirmPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderMemoFirmResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_memo_firm`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdateOrderStatus
		 * @summary 更改訂單狀態
		 * @request PUT:/order/{orderId}/update_order_status
		 */
		orderUpdateOrderStatus: (orderId: string, body: OrderUpdateOrderStatusPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateOrderStatusResponse, RpcStatus>({
				path: `/order/${orderId}/update_order_status`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdatePaymentDeliveryStatus
		 * @summary 更改金、物流狀態
		 * @request PUT:/order/{orderId}/update_payment_delivery_status
		 */
		orderUpdatePaymentDeliveryStatus: (orderId: string, body: OrderUpdatePaymentDeliveryStatusPayload, params: RequestParams = {}) =>
			this.request<CrmUpdatePaymentDeliveryStatusResponse, RpcStatus>({
				path: `/order/${orderId}/update_payment_delivery_status`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderUpdatePaymentMethod
		 * @summary 更改付款方式
		 * @request PUT:/order/{orderId}/update_payment_method
		 */
		orderUpdatePaymentMethod: (orderId: string, body: OrderUpdatePaymentMethodPayload, params: RequestParams = {}) =>
			this.request<CrmUpdatePaymentMethodResponse, RpcStatus>({
				path: `/order/${orderId}/update_payment_method`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	product = {
		/**
 * No description
 *
 * @tags product
 * @name ProductProductList
 * @summary 商品管理
載入商品列表
 * @request GET:/product
 */
		productProductList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmProductListResponse, RpcStatus>({
				path: `/product`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductCreateProduct
		 * @summary 新增商品
		 * @request POST:/product
		 */
		productCreateProduct: (body: CrmCreateProductRequest, params: RequestParams = {}) =>
			this.request<CrmCreateProductResponse, RpcStatus>({
				path: `/product`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductDeleteProduct
		 * @summary 刪除商品
		 * @request DELETE:/product/{productId}
		 */
		productDeleteProduct: (
			productId: string,
			body: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 編輯者 */
				updateUser?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmDeleteProductResponse, RpcStatus>({
				path: `/product/${productId}`,
				method: 'DELETE',
				body: body,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags product
		 * @name ProductUpdateProduct
		 * @summary 編輯商品
		 * @request PUT:/product/{productId}
		 */
		productUpdateProduct: (productId: string, body: ProductUpdateProductPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateProductResponse, RpcStatus>({
				path: `/product/${productId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	ramateVerify = {
		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyFirmList
		 * @summary 廠商列表
		 * @request GET:/ramate_verify
		 */
		ramateVerifyFirmList: (params: RequestParams = {}) =>
			this.request<CrmFirmListResponse, RpcStatus>({
				path: `/ramate_verify`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadAnnouncementList
		 * @summary 載入公告列表
		 * @request GET:/ramate_verify/announcement
		 */
		ramateVerifyLoadAnnouncementList: (params: RequestParams = {}) =>
			this.request<CrmLoadAnnouncementListResponse, RpcStatus>({
				path: `/ramate_verify/announcement`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCreateAnnouncement
		 * @summary 建立公告
		 * @request POST:/ramate_verify/announcement
		 */
		ramateVerifyCreateAnnouncement: (body: CrmCreateAnnouncementRequest, params: RequestParams = {}) =>
			this.request<CrmCreateAnnouncementResponse, RpcStatus>({
				path: `/ramate_verify/announcement`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadOneAnnouncement
		 * @summary 載入單一公告
		 * @request GET:/ramate_verify/announcement/{announcementId}
		 */
		ramateVerifyLoadOneAnnouncement: (announcementId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneAnnouncementResponse, RpcStatus>({
				path: `/ramate_verify/announcement/${announcementId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyDeleteAnnouncement
		 * @summary 刪除公告
		 * @request DELETE:/ramate_verify/announcement/{announcementId}
		 */
		ramateVerifyDeleteAnnouncement: (announcementId: string, body: RamateVerifyDeleteAnnouncementPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteAnnouncementResponse, RpcStatus>({
				path: `/ramate_verify/announcement/${announcementId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateAnnouncement
		 * @summary 編輯公告
		 * @request PUT:/ramate_verify/announcement/{announcementId}
		 */
		ramateVerifyUpdateAnnouncement: (announcementId: string, body: RamateVerifyUpdateAnnouncementPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateAnnouncementResponse, RpcStatus>({
				path: `/ramate_verify/announcement/${announcementId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadAnnouncementListPublic
		 * @summary 載入公告列表(公開/後端使用)
		 * @request GET:/ramate_verify/announcement_public
		 */
		ramateVerifyLoadAnnouncementListPublic: (
			query?: {
				/**
				 * 公告平台(0:all 1:bombmy 2:crm)
				 * @format int32
				 */
				service?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadAnnouncementListPublicResponse, RpcStatus>({
				path: `/ramate_verify/announcement_public`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadBombcoursePlanList
		 * @summary 獲取幫開課方案列表
		 * @request GET:/ramate_verify/bombcourse_plan
		 */
		ramateVerifyLoadBombcoursePlanList: (params: RequestParams = {}) =>
			this.request<CrmLoadBombcoursePlanListResponse, RpcStatus>({
				path: `/ramate_verify/bombcourse_plan`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCreateBombcoursePlan
		 * @summary 新增幫開課方案
		 * @request POST:/ramate_verify/bombcourse_plan
		 */
		ramateVerifyCreateBombcoursePlan: (body: CrmCreateBombcoursePlanRequest, params: RequestParams = {}) =>
			this.request<CrmCreateBombcoursePlanResponse, RpcStatus>({
				path: `/ramate_verify/bombcourse_plan`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateBombcoursePlan
		 * @summary 編輯幫開課方案
		 * @request PUT:/ramate_verify/bombcourse_plan
		 */
		ramateVerifyUpdateBombcoursePlan: (body: CrmUpdateBombcoursePlanRequest, params: RequestParams = {}) =>
			this.request<CrmUpdateBombcoursePlanResponse, RpcStatus>({
				path: `/ramate_verify/bombcourse_plan`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadOneBombcoursePlan
		 * @summary 載入單一幫開課方案
		 * @request GET:/ramate_verify/bombcourse_plan/{bombcoursePlanId}
		 */
		ramateVerifyLoadOneBombcoursePlan: (bombcoursePlanId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneBombcoursePlanResponse, RpcStatus>({
				path: `/ramate_verify/bombcourse_plan/${bombcoursePlanId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyDeleteBombcoursePlan
		 * @summary 刪除幫開課方案
		 * @request DELETE:/ramate_verify/bombcourse_plan/{bombcoursePlanId}
		 */
		ramateVerifyDeleteBombcoursePlan: (bombcoursePlanId: string, body: RamateVerifyDeleteBombcoursePlanPayload, params: RequestParams = {}) =>
			this.request<CrmDeleteBombcoursePlanResponse, RpcStatus>({
				path: `/ramate_verify/bombcourse_plan/${bombcoursePlanId}`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCheckAccountDouble
		 * @summary 查驗帳號是否重複(幫賣銷售使用)
		 * @request GET:/ramate_verify/check_account_double
		 */
		ramateVerifyCheckAccountDouble: (
			query?: {
				/** 帳號 */
				account?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmCheckAccountDoubleResponse, RpcStatus>({
				path: `/ramate_verify/check_account_double`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmBombmyPermissionNoticeLetter
		 * @summary 幫賣帳號開通通知信(幫賣銷售使用)
		 * @request POST:/ramate_verify/crm_bombmy_permission_notice_letter
		 */
		ramateVerifyCrmBombmyPermissionNoticeLetter: (body: CrmCRMBombmyPermissionNoticeLetterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMBombmyPermissionNoticeLetterResponse, RpcStatus>({
				path: `/ramate_verify/crm_bombmy_permission_notice_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmFirmRegisterForSaleBombmy
		 * @summary 註冊帳號(幫賣銷售使用)
		 * @request POST:/ramate_verify/crm_firm_register_for_sale_bombmy
		 */
		ramateVerifyCrmFirmRegisterForSaleBombmy: (body: CrmCRMFirmRegisterForSaleBombmyRequest, params: RequestParams = {}) =>
			this.request<CrmCRMFirmRegisterForSaleBombmyResponse, RpcStatus>({
				path: `/ramate_verify/crm_firm_register_for_sale_bombmy`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmOrderBuildNoticeLetter
		 * @summary 訂單成立通知信(商家、顧客)
		 * @request POST:/ramate_verify/crm_order_build_notice_letter
		 */
		ramateVerifyCrmOrderBuildNoticeLetter: (body: CrmCRMOrderBuildNoticeLetterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMOrderBuildNoticeLetterResponse, RpcStatus>({
				path: `/ramate_verify/crm_order_build_notice_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmOrderCustomerMessageNoticeLetter
		 * @summary 顧客留言通知信(給商家)
		 * @request POST:/ramate_verify/crm_order_customer_message_notice_letter
		 */
		ramateVerifyCrmOrderCustomerMessageNoticeLetter: (body: CrmCRMOrderCustomerMessageNoticeLetterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMOrderCustomerMessageNoticeLetterResponse, RpcStatus>({
				path: `/ramate_verify/crm_order_customer_message_notice_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmOrderFirmMessageNoticeLetter
		 * @summary 商家留言通知信(給顧客)
		 * @request POST:/ramate_verify/crm_order_firm_message_notice_letter
		 */
		ramateVerifyCrmOrderFirmMessageNoticeLetter: (body: CrmCRMOrderFirmMessageNoticeLetterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMOrderFirmMessageNoticeLetterResponse, RpcStatus>({
				path: `/ramate_verify/crm_order_firm_message_notice_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyCrmOrderPaymentCompleteNoticeLetter
		 * @summary 訂單付款成功通知信(商家、顧客)
		 * @request POST:/ramate_verify/crm_order_payment_complete_notice_letter
		 */
		ramateVerifyCrmOrderPaymentCompleteNoticeLetter: (body: CrmCRMOrderPaymentCompleteNoticeLetterRequest, params: RequestParams = {}) =>
			this.request<CrmCRMOrderPaymentCompleteNoticeLetterResponse, RpcStatus>({
				path: `/ramate_verify/crm_order_payment_complete_notice_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyDeleteFirmAccount
		 * @summary 刪除帳號
		 * @request DELETE:/ramate_verify/delete_firm_account
		 */
		ramateVerifyDeleteFirmAccount: (body: CrmDeleteFirmAccountRequest, params: RequestParams = {}) =>
			this.request<CrmDeleteFirmAccountResponse, RpcStatus>({
				path: `/ramate_verify/delete_firm_account`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyFirmSmsPointLog
		 * @summary 簡訊點數紀錄
		 * @request GET:/ramate_verify/firm_sms_point_log
		 */
		ramateVerifyFirmSmsPointLog: (params: RequestParams = {}) =>
			this.request<CrmFirmSMSPointLogResponse, RpcStatus>({
				path: `/ramate_verify/firm_sms_point_log`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoadLiveDetailInfo
		 * @summary 獲取直播詳細資訊
		 * @request GET:/ramate_verify/load_live_detail_info
		 */
		ramateVerifyLoadLiveDetailInfo: (
			query?: {
				/** 直播ID */
				liveId?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmLoadLiveDetailInfoResponse, RpcStatus>({
				path: `/ramate_verify/load_live_detail_info`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyRamateAccountAutoLogin
		 * @summary 根垣管理者自動登入
		 * @request POST:/ramate_verify/ramate_account_auto_login
		 */
		ramateVerifyRamateAccountAutoLogin: (body: CrmRamateAccountAutoLoginRequest, params: RequestParams = {}) =>
			this.request<CrmRamateAccountAutoLoginResponse, RpcStatus>({
				path: `/ramate_verify/ramate_account_auto_login`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyRamateLoadAccountInfo
		 * @summary 根垣管理者獲取帳號資訊
		 * @request GET:/ramate_verify/ramate_account_info/{ramateAccountId}
		 */
		ramateVerifyRamateLoadAccountInfo: (ramateAccountId: string, params: RequestParams = {}) =>
			this.request<CrmRamateLoadAccountInfoResponse, RpcStatus>({
				path: `/ramate_verify/ramate_account_info/${ramateAccountId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyRamateUpdateAccountInfo
		 * @summary 根垣管理者編輯帳號資訊
		 * @request PUT:/ramate_verify/ramate_account_info/{ramateAccountId}
		 */
		ramateVerifyRamateUpdateAccountInfo: (ramateAccountId: string, body: RamateVerifyRamateUpdateAccountInfoPayload, params: RequestParams = {}) =>
			this.request<CrmRamateUpdateAccountInfoResponse, RpcStatus>({
				path: `/ramate_verify/ramate_account_info/${ramateAccountId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyRamateAccountLogin
		 * @summary 根垣管理者登入
		 * @request POST:/ramate_verify/ramate_account_login
		 */
		ramateVerifyRamateAccountLogin: (body: CrmRamateAccountLoginRequest, params: RequestParams = {}) =>
			this.request<CrmRamateAccountLoginResponse, RpcStatus>({
				path: `/ramate_verify/ramate_account_login`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyRamateAccountRegister
		 * @summary 根垣管理者註冊
		 * @request POST:/ramate_verify/ramate_account_register
		 */
		ramateVerifyRamateAccountRegister: (body: CrmRamateAccountRegisterRequest, params: RequestParams = {}) =>
			this.request<CrmRamateAccountRegisterResponse, RpcStatus>({
				path: `/ramate_verify/ramate_account_register`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifySearchBombmyLivePeriod
		 * @summary 查閱幫賣直播時段
		 * @request GET:/ramate_verify/search_bombmy_live_period
		 */
		ramateVerifySearchBombmyLivePeriod: (
			query?: {
				/** 查詢日期 */
				searchDate?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CrmSearchBombmyLivePeriodResponse, RpcStatus>({
				path: `/ramate_verify/search_bombmy_live_period`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateFirmAuthority
		 * @summary 編輯廠商權限
		 * @request PUT:/ramate_verify/{firmId}
		 */
		ramateVerifyUpdateFirmAuthority: (firmId: string, body: RamateVerifyUpdateFirmAuthorityPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateFirmAuthorityResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoanOneFirmLiveHoursLog
		 * @summary 載入單一廠商幫賣直播時數紀錄
		 * @request GET:/ramate_verify/{firmId}/firm_live_hours_log
		 */
		ramateVerifyLoanOneFirmLiveHoursLog: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoanOneFirmLiveHoursLogResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/firm_live_hours_log`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyLoanOneFirmSmsPointLog
		 * @summary 載入單一廠商簡訊點數紀錄
		 * @request GET:/ramate_verify/{firmId}/firm_sms_point_log
		 */
		ramateVerifyLoanOneFirmSmsPointLog: (firmId: string, params: RequestParams = {}) =>
			this.request<CrmLoanOneFirmSMSPointLogResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/firm_sms_point_log`,
				method: 'GET',
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifySendSms
		 * @summary 寄送簡訊
		 * @request POST:/ramate_verify/{firmId}/send_sms
		 */
		ramateVerifySendSms: (firmId: string, body: RamateVerifySendSmsPayload, params: RequestParams = {}) =>
			this.request<CrmSendSMSResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/send_sms`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateBombcourseExpireDate
		 * @summary 編輯幫開課到期日期
		 * @request PUT:/ramate_verify/{firmId}/update_bombcourse_expire_date
		 */
		ramateVerifyUpdateBombcourseExpireDate: (firmId: string, body: RamateVerifyUpdateBombcourseExpireDatePayload, params: RequestParams = {}) =>
			this.request<CrmUpdateBombcourseExpireDateResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/update_bombcourse_expire_date`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateFirmBombcoursePlan
		 * @summary 編輯廠商幫開課方案
		 * @request PUT:/ramate_verify/{firmId}/update_firm_bombcourse_plan
		 */
		ramateVerifyUpdateFirmBombcoursePlan: (firmId: string, body: RamateVerifyUpdateFirmBombcoursePlanPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateFirmBombcoursePlanResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/update_firm_bombcourse_plan`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateFirmLiveHours
		 * @summary 編輯廠商幫賣直播時數
		 * @request POST:/ramate_verify/{firmId}/update_firm_live_hours
		 */
		ramateVerifyUpdateFirmLiveHours: (firmId: string, body: RamateVerifyUpdateFirmLiveHoursPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateFirmLiveHoursResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/update_firm_live_hours`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateFirmSmsPoint
		 * @summary 編輯廠商簡訊點數
		 * @request POST:/ramate_verify/{firmId}/update_firm_sms_point
		 */
		ramateVerifyUpdateFirmSmsPoint: (firmId: string, body: RamateVerifyUpdateFirmSmsPointPayload, params: RequestParams = {}) =>
			this.request<CrmUpdateFirmSMSPointResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/update_firm_sms_point`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags ramate_verify
		 * @name RamateVerifyUpdateLastLiveHoursDate
		 * @summary 編輯最後儲值日期
		 * @request PUT:/ramate_verify/{firmId}/update_last_live_hours_date
		 */
		ramateVerifyUpdateLastLiveHoursDate: (firmId: string, body: RamateVerifyUpdateLastLiveHoursDatePayload, params: RequestParams = {}) =>
			this.request<CrmUpdateLastLiveHoursDateResponse, RpcStatus>({
				path: `/ramate_verify/${firmId}/update_last_live_hours_date`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	sendForgotPassLetter = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmSendForgotPassLetter
		 * @summary 寄送忘記密碼信件
		 * @request POST:/send_forgot_pass_letter
		 */
		crmSendForgotPassLetter: (body: CrmSendForgotPassLetterRequest, params: RequestParams = {}) =>
			this.request<CrmSendForgotPassLetterResponse, RpcStatus>({
				path: `/send_forgot_pass_letter`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	setTodayOrder = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmSetTodayOrder
		 * @summary 每日添加隨機訂單
		 * @request POST:/set_today_order
		 */
		crmSetTodayOrder: (body: CrmSetTodayOrderRequest, params: RequestParams = {}) =>
			this.request<CrmSetTodayOrderResponse, RpcStatus>({
				path: `/set_today_order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	signOut = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmSignOut
		 * @summary 登出
		 * @request DELETE:/sign_out
		 */
		crmSignOut: (body: CrmCRMSignOutRequest, params: RequestParams = {}) =>
			this.request<CrmCRMSignOutResponse, RpcStatus>({
				path: `/sign_out`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	signOutAllDevice = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmSignOutAllDevice
		 * @summary 登出所有裝置
		 * @request DELETE:/sign_out_all_device
		 */
		crmSignOutAllDevice: (body: CrmSignOutAllDeviceRequest, params: RequestParams = {}) =>
			this.request<CrmSignOutAllDeviceResponse, RpcStatus>({
				path: `/sign_out_all_device`,
				method: 'DELETE',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	signupOrder = {
		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderSignupOrderList
		 * @summary 載入報名訂單列表
		 * @request GET:/signup_order
		 */
		orderSignupOrderList: (
			query?: {
				/** 公司ID */
				firmId?: string;
				/** 平台類型 */
				platformType?: string;
				/** 金流狀態 */
				paymentStatus?: string;
				/** 訂購日期 */
				orderDate?: string[];
				/** 發票日期 */
				invoiceDate?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<CrmSignupOrderListResponse, RpcStatus>({
				path: `/signup_order`,
				method: 'GET',
				query: query,
				format: 'json',
				...params,
			}),

		/**
 * No description
 *
 * @tags order
 * @name OrderCreateSignupOrder
 * @summary 報名訂單管理
新增報名訂單
 * @request POST:/signup_order
 */
		orderCreateSignupOrder: (body: CrmCreateSignupOrderRequest, params: RequestParams = {}) =>
			this.request<CrmCreateSignupOrderResponse, RpcStatus>({
				path: `/signup_order`,
				method: 'POST',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags order
		 * @name OrderLoadOneSignupOrder
		 * @summary 載入單一報名訂單內容
		 * @request GET:/signup_order/{signupOrderId}
		 */
		orderLoadOneSignupOrder: (signupOrderId: string, params: RequestParams = {}) =>
			this.request<CrmLoadOneSignupOrderResponse, RpcStatus>({
				path: `/signup_order/${signupOrderId}`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	testHeader = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmTestHeader
		 * @request GET:/test_header
		 */
		crmTestHeader: (params: RequestParams = {}) =>
			this.request<CrmTestHeaderResponse, RpcStatus>({
				path: `/test_header`,
				method: 'GET',
				format: 'json',
				...params,
			}),
	};
	updateAccountInfo = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmUpdateAccountInfo
		 * @summary 編輯帳號資訊
		 * @request PUT:/update_account_info
		 */
		crmUpdateAccountInfo: (body: CrmUpdateAccountInfoRequest, params: RequestParams = {}) =>
			this.request<CrmUpdateAccountInfoResponse, RpcStatus>({
				path: `/update_account_info`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
	updateForgotPass = {
		/**
		 * No description
		 *
		 * @tags CRM
		 * @name CrmUpdateForgotPass
		 * @summary 忘記密碼_編輯新密碼
		 * @request PUT:/update_forgot_pass
		 */
		crmUpdateForgotPass: (body: CrmUpdateForgotPassRequest, params: RequestParams = {}) =>
			this.request<CrmUpdateForgotPassResponse, RpcStatus>({
				path: `/update_forgot_pass`,
				method: 'PUT',
				body: body,
				type: ContentType.Json,
				format: 'json',
				...params,
			}),
	};
}
